<template>
    <div class="section reserves-from-app">
        <h2 class="section__title">Резервы из приложения</h2>

        <data-table ref="table" :options="tableOptions"></data-table>
    </div>
</template>

<script>
import DataTable from "@/components/global/DataTable/index";
import table_name from '@/components/global/DataTable/templates/tables';
import event_name from '@/components/global/DataTable/templates/event';
import client_name from '@/components/global/DataTable/templates/client';

export default {
    name: 'ReservesFromApp',
    components: {
        DataTable,
    },
    data() {
        return {
            tableOptions: {
                type: 'reserveFromApp',
                getDataParams: {orderBy: 'created_at', sortedBy: 'desc', from_mobile: true},
                columns: ['id', 'event_name', 'table_name', 'client_name', 'persons_count', 'date_start', 'created_at', 'options', 'edit'],
                filterable: ['event_name', 'table_name', 'client_name', 'persons_count', 'date_start'],
                searchParams: ['event_name', 'client_name', 'table_name'],
                headings: {
                    id: '#',
                    'event_name': 'Название события',
                    'client_name': 'Имя клиента',
                    'table_name': 'Стол(ы)',
                    persons_count: 'Количество человек',
                    date_start: 'Дата начала',
                    date_finish: 'Дата окончания',
                    options: 'Статус',
                    status: 'Статус Заказа',
                    edit: 'Действия',
                    prepayment: 'Предоплата',
                    comment: 'Комментарий',
                    tables: 'Столы',
                    restaurant_space_id: 'Зал Ресторана',
                    event_id: 'Событие',
                    products: 'Продукты',
                    total_price: 'Сумма Бронирования',
                    
                    avg_check: 'Средний чек',
                    name: 'Имя клиента',
                    last_name: 'Фамилия клиента',
                    email: 'email',
                    phone: 'Телефон',
                    created_at: 'Создан',
                    updated_at: 'Обновлен',
                    image_url: 'Изображение',

                    title: 'Название',
                    date_time: 'Дата',
                    description: 'Описание',
                    ticket_price: 'Цена билета',
                    min_deposit: 'Минимальный депозит',
                },
                templates: {event_name, client_name, table_name},
                prefetchDataList: [],
                actions: ['view', 'edit', 'delete'],
            },
        }
    },
    methods: {

    }
}
</script>