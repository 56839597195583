<template>
    <div class="login">
        <div class="login__content">
            <h2 class="login__title">Авторизоваться</h2>
            <h5 class="login__subtitle">Пожалуйста, заполните следующие поля для входа:</h5>
            <form class="login-form" @submit.prevent="login">
                <div class="site-input">
                    <label for="email">Email</label>
                    <div class="input-item">
                        <input id="email" type="email" v-model="email" required ref="email">
                    </div>
                </div>
                <div class="site-input">
                    <label for="password">Password</label>
                    <div class="input-item">
                        <input id="password" type="password" v-model="password" required ref="password">
                    </div>
                </div>
                <div class="site-input site-input_check">
                    <label for="remember">Remember me</label>
                    <input id="remember" type="checkbox" v-model="rememberMe" required ref="rememberMe">
                </div>
                <div class="login__error" v-show="showError">
                    <span>Введен неверный логин или пароль</span>
                </div>
                <button type="submit" class="btn">Авторизоваться</button>
            </form>
            <div class="login-error" v-show="error">{{error}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data() {
        return {
            email: "",
            password: "",
            rememberMe: true,
            error: '',
            showError: false
        }
    },
    watch: {

    },
    methods: {
        login() {
            let email = this.email;
            let password = this.password;
            let rememberMe = this.rememberMe;
            this.$store.dispatch('login', {user: {email, password}, rememberMe: rememberMe})
                .then(() => {
                    this.$router.push('/booking');
                })
                .catch(() => {
                    this.showError = true;
                })
        },
        goTo(destination) {
            this.$router.push('/' + destination)
        },
    }
}
</script>
