<template>
    <div class="section table-presets">
        <table-preset-form :type="'create'" @itemSubmitSuccess="onItemSubmitSuccess"></table-preset-form>
    </div>
</template>

<script>
import TablePresetForm from "@/components/pages/Cabinet/TablePresets/blocks/TablePresetForm";

export default {
    name: 'TablePresetCreate',
    components: {
        TablePresetForm
    },
    data() {
        return {

        }
    },
    methods: {
        onItemSubmitSuccess() {
            this.$router.push('/table-presets');
        }
    }
}
</script>