<template>
    <div>
        <h2 class="section__title" v-if="type === 'create'">Создать событие</h2>
        <h2 class="modal__title" v-if="type === 'edit'  && !showGallery">Редактировать событие: {{eventObj.title}}</h2>
        <h2 class="modal__title modal__title_gallery" v-if="type === 'edit'  && showGallery">
            Галерея события {{eventObj.title}}
            <button class="btn btn_primary btn_mtb" @click="hideGallery">Назад</button>
        </h2>
        <div class="new-event" v-show="!showGallery">
            <form class="form" @submit.prevent="submitItem" ref="form">
                <div class="form__row">
                    <div class="site-input">
                        <label for="title">Название</label>
                        <div class="input-item">
                            <input id="title" type="text" v-model="eventObj.title" required>
                        </div>
                    </div>
                    <div class="site-input">
                        <label for="description">Описание</label>
                        <div class="input-item">
                            <input id="description" type="text" v-model="eventObj.description">
                        </div>
                    </div>
                    <div class="site-input">
                        <label for="restaurant_space_id">Зал</label>
                        <div class="input-item input-item_booking">
                            <multiselect
                                    id="restaurant_space_id"
                                    v-model="selectedSpace"
                                    :options="restaurantSpaces"
                                    :searchable="false"
                                    :close-on-select="true"
                                    placeholder="Выберите столы"
                                    :multiple="false"
                                    label="name"
                                    track-by="id"
                                    :selectLabel="''"
                                    :deselectLabel="''"
                                    :selectedLabel="'•'"
                            ></multiselect>
                        </div>
                    </div>
                </div>
                <div class="site-input site-input_multirow site-input_multirow_event">
                    <div class="date_time">
                        <label for="date_time">Дата</label>
                        <div class="input-item">
                            <datepicker id="date_time"
                                        v-model="eventObj.date_time"
                                        lang="ru"
                                        type="datetime"
                                        :appendToBody="false"
                                        format="YYYY-MM-DD HH:mm:ss"
                                        :time-picker-options="{ start: '00:00', step: '00:30', end: '23:30' }"
                                        :first-day-of-week="1"
                                        :width="'auto'"
                                        :editable="false"
                                        :clearable="false"
                                        :valueType="'format'"
                            ></datepicker>
                        </div>
                    </div>
                    <div class="ticket_price">
                        <label for="ticket_price">Цена билета</label>
                        <div class="input-item">
                            <input id="ticket_price" type="text" v-model="eventObj.ticket_price">
                        </div>
                    </div>
                    <div class="min_deposit">
                        <label for="min_deposit">Минимальный депозит</label>
                        <div class="input-item">
                            <input id="min_deposit" type="text" v-model="eventObj.min_deposit">
                        </div>
                    </div>
                    <div class="gallery-button" v-if="isPastEvent">
                        <button class="btn btn_theme" @click.prevent="toGallery">Галерея</button>
                    </div>
                </div>
                <div class="form__row">
                    <div class="dropzone">
                        <div class="site-input">
                            <label for="image">Изображение</label>
                            <p v-if="!show">Перетащите сюда или выберите изображение <i class="fa fa-cloud-upload"></i></p>
                            <input class="file-input" type="file" id="image" ref="file" @change="previewImage" accept="image/*">
                        </div>
                        <div class="image" v-if="show">
                            <img :src="imageData" alt="">
                            <span class="close" @click="deleteImage">&times;</span>
                        </div>
                    </div>
                </div>
                <div class="form__row form__row_m">
                </div>
                <div class="form__row form__row_submit">
                    <button class="btn btn_primary" type="submit" v-show="!submitting">Сохранить</button>
                    <div class="loader" v-show="submitting">Сохранение...</div>
                </div>
            </form>
        </div>

        <event-gallery v-if="showGallery" ref="gallery" :id="eventObj.id" :media="eventObj.medias" @submit="onGalleryItemsSubmit" @delete="onGalleryItemsDelete"></event-gallery>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Datepicker from 'vue2-datepicker';
import {apiUrls} from "@/config/constants";
import handleFormValidationErrors from "@/assets/js/mixins/handleFormValidationErrors";
import EventGallery from "@/components/pages/Cabinet/Events/blocks/EventGallery";
import moment from 'moment';

import {mapGetters} from  'vuex';

export default {
    name: 'EventForm',
    components: {
        Multiselect,
        Datepicker,
        EventGallery
    },
    mixins: [handleFormValidationErrors],
    props: {
        type: {
            type: String,
            default: 'create'
        },
        event: {
            type: Object,
            default: () => ({
                date_time: '',
                id: '',
                image: '',
                title: '',
                description: '',
                min_deposit: 0,
                ticket_price: 0,
                medias: [],
                schedule: null
            })
        }
    },
    computed: {
         ...mapGetters(['userRestaurant']),
        isPastEvent() {
            return this.type !== 'create' && new Date(moment(this.eventObj.date_time).format()) < new Date()
        }
    },
    data() {
        return {
            eventObj: {},
            errors: [],
            imageData: '',
            show: false,
            submitting: false,
            showGallery: false,
            selectedSpace: {},
            restaurantSpaces: []
        }
    },
    methods: {
        getRestaurantSpaces() {
            return this.$store.dispatch('getRestaurantSpaces')
                .then(response => {
                    this.restaurantSpaces = response;
                })
                .catch(error => console.log(error));
        },
        createEvent(formData) {
            return this.$http.post(apiUrls.event, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(data => data)
                .catch(error => {
                    this.handleFormValidationErrors(error, this.$refs.form)
                })
        },
        editEvent(formData) {
            return this.$http.post(apiUrls.event + '/' + this.eventObj.id, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(data => data)
                .catch(error => {
                    this.handleFormValidationErrors(error, this.$refs.form)
                })
        },
        async submitItem() {
            this.submitting = true;

            this.eventToSubmit = {
                date_time: this.eventObj.date_time,
                image: this.eventObj.image,
                description: this.eventObj.description || '',
                id: this.eventObj.id || '',
                title: this.eventObj.title,
                min_deposit: this.eventObj.min_deposit,
                ticket_price: this.eventObj.ticket_price,
                restaurant_space_id: this.selectedSpace.id,
                schedule: this.eventObj.schedule || null
            };

            let formData = new FormData();
            formData.append('date_time', this.eventToSubmit.date_time);
            formData.append('min_deposit', this.eventToSubmit.min_deposit);
            formData.append('ticket_price', this.eventToSubmit.ticket_price);
            formData.append('description', this.eventToSubmit.description);
            formData.append('image', this.eventToSubmit.image || '');
            formData.append('title', this.eventToSubmit.title);
            formData.append('restaurant_space_id', this.eventToSubmit.restaurant_space_id);
            formData.append('restaurant_id', this.userRestaurant);
            formData.append('schedule', this.eventToSubmit.schedule);

            let resp;

            if (this.type === 'edit') {
                resp = await this.editEvent(formData);
            } else {
                resp = await this.createEvent(formData);
            }

            if (resp && resp.data && resp.data.success === true) {
                this.$emit('itemSubmitSuccess', this.eventToSubmit);
            }

            this.submitting = false;
        },
        previewImage(ev) {
            let input = ev.target;
            if (input.files && input.files[0]) {
                let reader = new FileReader();
                reader.onload = e => {
                    this.imageData = e.target.result;
                    this.show = !!this.imageData;
                };
                reader.readAsDataURL(input.files[0]);
            }
            this.eventObj.image = this.$refs.file.files[0];
        },
        deleteImage() {
            this.eventObj.image = null;
            this.imageData = '';
            this.show = false;
            this.$refs.file.value = '';
        },
        toGallery() {
            this.showGallery = true;
        },
        hideGallery() {
            this.showGallery = false;
        },
        onGalleryItemsSubmit(results) {
            this.eventObj.medias = results.map(result => result && result.data && result.data.data ? result.data.data : {});
            this.showGallery = false;
        },
        onGalleryItemsDelete(responses) {
            responses.forEach(response => {
                if (response.data && response.data.data) {
                    this.eventObj.medias.splice(this.eventObj.medias.findIndex(media => +media.id === +response.data.data), 1)
                }
            })
        }
    },
    mounted() {
        this.eventObj = Object.assign({}, this.event);

        if (this.eventObj.image_url) {
            this.imageData = this.eventObj.image_url;
            this.show = true;
        }

        this.addWatcher('eventObj');

        this.getRestaurantSpaces()
            .then(() => {
                let id = this.eventObj.restaurant_space_id || 1;
                this.selectedSpace = Object.assign({}, this.restaurantSpaces.find(space => space.id === id));
            })
    }
}
</script>