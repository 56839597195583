<template>
    <div class="section reserves">
        <button class="btn btn_primary btn_mtb" @click="goBack">Назад</button>
        <button class="btn btn_pale btn_m" @click="toggleTableBusyNowStatus" v-if="todayIsTheDay && $store.state.bookingState && $store.state.bookingState.table">
            <span v-if="isCurrentTableBusy">Освободить стол</span>
            <span v-else>Занять стол</span>
        </button>

        <div v-if="loaded">
            <reserve-form v-if="this.reserve.from_mobile === false || type === 'create'"
                          :type="type"
                          :mode="mode"
                          :reserve="reserve.id ? reserve : null"
                          @itemSubmitSuccess="onItemSubmitSuccess"
                          @itemChanged="onItemChanged"
                          @itemDeleted="onItemDeleted">
            </reserve-form>
            <reserve-from-app-form v-if="this.reserve.from_mobile === true"
                          :type="type"
                          :mode="mode"
                          :reserve="reserve.id ? reserve : null"
                          @itemSubmitSuccess="onItemSubmitSuccess"
                          @itemChanged="onItemChanged"
                          @itemDeleted="onItemDeleted">
            </reserve-from-app-form>
        </div>
    </div>
</template>

<script>
import ReserveForm from "@/components/pages/Cabinet/Reserves/blocks/ReserveForm";
import ReserveFromAppForm from "@/components/pages/Cabinet/ReservesFromApp/blocks/ReserveFromAppForm";
import {apiUrls, misc} from "@/config/constants";
import {mapGetters} from 'vuex';

export default {
    name: 'Reserve',
    components: {
        ReserveForm,
        ReserveFromAppForm
    },
    data() {
        return {
            loaded: false,
            type: '',
            mode: '',
            reserve: {},
            todayIsTheDay: false,
            from: {path: '/booking'}
        }
    },
    computed: {
        ...mapGetters(['isCurrentTableBusy'])
    },
    methods: {
        onItemChanged() {

        },
        onItemSubmitSuccess(){
            this.goBack();
        },
        toggleTableBusyNowStatus() {
            try {
                if (!this.isCurrentTableBusy) {
                    this.$store.state.bookingState.table.busy_now = 'busy';
                    this.$store.state.bookingState.wasBusy = true;
                } else {
                    this.$store.state.bookingState.table.busy_now = '';
                }
                this.$http.post(apiUrls.table + '/' + this.$store.state.bookingState.table.id, this.$store.state.bookingState.table)
                    .then(resp => {
                        if (resp.data.data) {
                            this.$store.state.bookingState.table = resp.data.data;
                        }
                    })
            } catch (e) {
                console.log(e);
            }
        },
        dateSubtractHours(hours) {
            return new Date().setTime(new Date().getTime() - hours * 60 * 60 * 1000);
        },
        onItemDeleted() {
            this.goBack();
        },
        goBack() {
            this.$router.push(this.from.path);
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.from = from;
        });
    },
    mounted() {
        let today = new Date(this.dateSubtractHours(misc.WORKING_HOURS_OFFSET)),
            theDay = this.$store.state.bookingState ? new Date(this.$store.state.bookingState.date) : new Date();

        this.todayIsTheDay =
            theDay.getDate() === today.getDate() &&
            theDay.getMonth() === today.getMonth() &&
            theDay.getFullYear() === today.getFullYear();

        if (this.$route.params['reserveId']) {
            this.$http.get(apiUrls.reserve + '/' + this.$route.params['reserveId'])
                .then(resp => {
                    this.reserve = resp.data.data;
                    this.type = 'edit';
                    this.mode = 'booking';
                    this.loaded = true;
                })
                .catch(err => console.log(err));
        } else {
            this.type = 'create';
            this.mode = 'booking';
            this.loaded = true;
        }
    }
}
</script>