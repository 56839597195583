<template>
    <div>
        <h2 class="section__title" v-if="type === 'create'">
            Создать расстановку столов
        </h2>
        <h2 class="modal__title" v-if="type === 'edit'">
            Редактировать расстановку столов: {{ tablePresetObj.name }}
        </h2>
        <div>
            <form class="form" @submit.prevent="submitItem" ref="form">
                <div class="site-input">
                    <label for="name">Название</label>
                    <div class="input-item">
                        <input id="name" type="text" v-model="tablePresetObj.name"/>
                    </div>
                </div>
                <div class="site-input">
                    <label for="comment">Комментарий</label>
                    <div class="input-item">
            <textarea id="comment" type="text" v-model="tablePresetObj.comment">
            </textarea>
                    </div>
                </div>
                <div class="site-input">
                    <multiselect
                            v-model="tablePresetObj.restaurant_space"
                            :options="restaurantSpaces"
                            :searchable="true"
                            :close-on-select="true"
                            :clear-on-select="true"
                            placeholder="Выберите зал"
                            :multiple="false"
                            :trackBy="'id'"
                            :label="'name'"
                            :selectLabel="'Выбрать'"
                            :deselectLabel="''"
                            :selectedLabel="'Выбрано'"
                    >
                        <span slot="noResult">По вашему запросу нет результатов.</span>
                    </multiselect>
                </div>
                <div class="site-input">
                    <label for="prepayment">Масштаб столов</label>
                    <div class="input-item">
                        <input
                                id="prepayment"
                                type="range"
                                min="0.5"
                                max="5"
                                step="0.1"
                                v-model="tablePresetObj.scale"
                        />
                    </div>
                </div>
                <div class="site-input preset__checkbox">
                    <input
                            type="checkbox"
                            id="checkbox"
                            v-model="tablePresetObj.default"
                    />
                    <label for="checkbox"
                    >Использовать данную расстановку по умолчанию
                    </label
                    >
                </div>
                <div v-if="tablePresetObj['restaurant_space']">
                    <h4>Расстановка:</h4>
                    <h5>(Масштаб столов: {{ tablePresetObj.scale }})</h5>
                    <div class="moving-message" v-show="movingTable">
                        Кликните по нужному месту на схеме для перемещения туда стола или
                        введите точные координаты.
                        <button
                                class="btn btn_danger"
                                @click.stop.prevent="movingTable = false"
                        >
                            Выйти из режима перемещения стола
                        </button>
                    </div>
                    <div
                            class="preset"
                            ref="preset"
                            :class="{ moving: movingTable }"
                            :style="{
              background:
                'url(' +
                tablePresetObj['restaurant_space'].image_url +
                ') center center / 100% 100% no-repeat',
              width: tablePresetObj['restaurant_space']['size_x'] + 'px',
              height: tablePresetObj['restaurant_space']['size_y'] + 'px',
            }"
                            @click.self.prevent="openPresetMenu"
                    >
                        <div class="preset__click-zone" ref="clickZone"></div>
                        <div
                                v-for="(table, index) in tablePresetObj['tables']"
                                :key="index"
                                class="preset__table"
                                :class="{ selected: showTableMenu === table.id }"
                                :style="{
                top: table['pivot']['coord_y'] + 'px',
                left: table['pivot']['coord_x'] + 'px',
                background:
                  'url(' +
                  table.image_url +
                  ') center center / 100% 100% no-repeat',
                height: table.seats_count * tableScale + 'px',
                width: table.seats_count * tableScale + 'px',
                'margin-top': getMargin(table.seats_count),
                'margin-left': getMargin(table.seats_count),
              }"
                                @click.stop.prevent="openTableMenu($event, table)"
                        >
                            <span class="name">{{ table.name }}</span>
                            <div
                                    class="menu"
                                    v-show="showTableMenu === table.id"
                                    :style="{
                  top: tableMenuY + 'px',
                  left: tableMenuX + 'px',
                }"
                            >
                <span class="menu__option" @click="openMoveTable(table)"
                >{{ table.id === null ? 'Переместить' : 'Переместить или изменить цену'}}</span
                >
                                <span class="menu__option" @click="openDeleteTable(table)"
                                >{{ table.id === null ? 'Удалить кнопку' : 'Удалить стол'}}</span
                                >
                            </div>

                            <div
                                    class="coordinates"
                                    v-if="movingTable && tableToMove.id === table.id"
                            >
                                <div class="coordinates__row">
                                    <label for="move-x" style="width: 70px;">X:</label>
                                    <input
                                            id="move-x"
                                            type="number"
                                            v-model="tableToMove.pivot.coord_x"
                                    />
                                </div>
                                <div class="coordinates__row">
                                    <label for="move-y" style="width: 70px;">Y:</label>
                                    <input
                                            id="move-y"
                                            type="number"
                                            v-model="tableToMove.pivot.coord_y"
                                    />
                                </div>

                                <div class="coordinates__row">
                                    <label for="width" style="width: 70px;">Width:</label>
                                    <input
                                            id="size-x"
                                            type="number"
                                            v-model="tableToMove.pivot.width"
                                    />
                                </div>
                                <div class="coordinates__row">
                                    <label for="height" style="width: 70px;">Height:</label>
                                    <input
                                            id="size-y"
                                            type="number"
                                            v-model="tableToMove.pivot.height"
                                    />
                                </div>
                                <div class="coordinates__row" v-if="showPrice">
                                    <label for="move-price" style="width: 70px;">Цена:</label>
                                    <input
                                            id="move-price"
                                            type="number"
                                            v-model="tableToMove.pivot.price"
                                    />
                                </div>
                                <div class="coordinates__row" v-if="showPrice">
                                    <label for="move-seat-price" style="width: 70px;">Цена за место:</label>
                                    <input
                                            id="move-seat-price"
                                            type="number"
                                            v-model="tableToMove.pivot.seat_price"
                                    />
                                </div>
                                <div class="coordinates__save" @click="confirmTableMove">
                                    <span>&#10004;</span>
                                </div>
                            </div>
                        </div>
                        <div
                                class="menu menu_btlr"
                                v-show="showPresetMenu"
                                :style="{
                top: presetMenuY + 'px',
                left: presetMenuX + 'px',
              }"
                        >
              <span class="menu__option" @click="openAddTable"
              >Добавить стол</span
              >
                            <span class="menu__option" style="display: block;" @click="addButton"
                            >Добавить кнопку покупки билета</span
                            >
                        </div>
                        <div class="preset__delete-table" v-if="deletingTable">
                            <h4>Удалить {{ tableToDelete.id === null ? 'кнопку' : 'стол'}} {{ tableToDelete.name }}?</h4>
                            <span class="close" @click="deletingTable = false">&times;</span>
                            <button class="btn btn_danger" @click="confirmDeleteTable">
                                Удалить
                            </button>
                        </div>
                        <div class="preset__add-table" v-if="showTableList">
                            <h4>Добавить стол</h4>
                            <span class="close" @click="showTableList = false">&times;</span>
                            <div class="tables-dropdown">
                                <div class="site-input">
                                    <div class="input-item" id="status">
                                        <multiselect
                                                v-model="additionalTable"
                                                :options="tables"
                                                :searchable="true"
                                                :close-on-select="true"
                                                :clear-on-select="true"
                                                placeholder="Выберите стол"
                                                :multiple="false"
                                                :trackBy="'id'"
                                                :label="'name'"
                                                :selectLabel="'Выбрать'"
                                                :deselectLabel="'Убрать'"
                                                :selectedLabel="'Выбрано'"
                                        >
                                            <template slot="option" slot-scope="props">
                                                <img
                                                        v-show="props.option.image_url"
                                                        class="option__image"
                                                        :src="props.option.image_url"
                                                        :alt="props.option.name"
                                                />
                                                <div class="option__desc">
                          <span class="option__title"
                          >{{ props.option.name }} (мест:
                            {{ props.option.seats_count }})</span
                          >
                                                </div>
                                            </template>
                                            <span slot="noResult"
                                            >По вашему запросу нет результатов.</span
                                            >
                                        </multiselect>
                                    </div>
                                    <div class="input-item" id="add-price">
                                        <input
                                                id="add-price-input"
                                                type="number"
                                                placeholder="Цена"
                                                v-model="presetMenuPrice"
                                        />
                                    </div>
                                    <div class="input-item" id="add-price-seat">
                                        <input
                                                id="add-price-seat-input"
                                                type="number"
                                                placeholder="Цена за место"
                                                v-model="presetMenuSeatPrice"
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                    class="btn btn_success"
                                    @click="addTable"
                                    :disabled="!additionalTable"
                            >
                                Добавить
                            </button>
                        </div>
                    </div>
                </div>

                <div class="form__row form__row_submit">
                    <button class="btn btn_primary" type="submit" v-show="!submitting">
                        Сохранить
                    </button>
                    <div class="loader" v-show="submitting">Сохранение...</div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import Multiselect from "vue-multiselect";
    import {apiUrls} from "@/config/constants";
    import handleFormValidationErrors from "@/assets/js/mixins/handleFormValidationErrors";
    import {mapGetters} from "vuex";

    export default {
        name: "TablePresetForm",
        components: {
            Multiselect,
        },
        mixins: [handleFormValidationErrors],
        props: {
            type: {
                type: String,
                default: "create",
            },
            tablePreset: {
                type: Object,
                default: () => ({
                    comment: "",
                    id: "",
                    name: "",
                    restaurant_space_id: "",
                    scale: 1,
                    tables: [],
                }),
            },
        },
        data() {
            return {
                tablePresetObj: {},
                itemToSubmit: {},
                imageData: "",
                show: false,
                showPresetMenu: false,
                presetMenuX: 0,
                presetMenuY: 0,
                presetMenuPrice: null,
                presetMenuSeatPrice: null,
                showTableMenu: null,
                tableMenuX: 0,
                tableMenuY: 0,
                showTableList: false,
                additionalTable: null,
                tables: [],
                movingTable: false,
                tableToMove: {pivot: {}},
                prevX: 0,
                prevY: 0,
                deletingTable: false,
                tableToDelete: {},
                restaurantSpaces: [],
                submitting: false,
                tableButton: null,
                showPrice: true
            };
        },
        computed: {
            ...mapGetters(["userRestaurant"]),
            tableScale() {
                return parseFloat(this.tablePresetObj.scale) * 5;
            },
        },
        methods: {
            getMargin(seats_count) {
                return (-seats_count * this.tableScale) / 2 > -30
                    ? -30 + "px"
                    : (-seats_count * this.tableScale) / 2 + "px";
            },
            openPresetMenu(evt) {
                this.showTableMenu = false;
                let rect = evt.target.getBoundingClientRect();
                this.presetMenuX = Math.floor(evt.clientX - rect.left);
                this.presetMenuY = Math.floor(evt.clientY - rect.top);
                this.showPresetMenu = true;
            },
            openAddTable() {
                this.showTableList = true;
                this.presetMenuPrice = null;
                this.presetMenuSeatPrice = null;
            },
            getTables() {
                this.$http.get("/tables", {params: {restaurant_id: this.userRestaurant}}).then((resp) => {
                    if (resp && resp.data && resp.data.data && resp.data.data.items) {
                        this.tables = resp.data.data.items.map((table) => {
                            if (
                                this.tablePresetObj.tables.find(
                                    (existingTable) => existingTable.id === table.id
                                )
                            ) {
                                table.$isDisabled = true;
                            }
                            return table;
                        });
                    }
                });
            },
            addButton() {
                this.showPresetMenu = false;

                if (this.tableButton === null) {
                    this.tableButton = this.tablePresetObj.tables.find(
                        (existingTable) => existingTable.id === null
                    );

                    if (typeof this.tableButton === 'undefined') {
                        this.tableButton = {
                            busy_now: null,
                            id: null,
                            seats_count: 0,
                            name: 'Купить билет',
                            pivot: {
                                coord_x: this.presetMenuX,
                                coord_y: this.presetMenuY,
                                price: null,
                                seat_price: null,
                                button: true
                            },
                            restaurant_id: this.userRestaurant,
                            image: null,
                            image_url: "http://hostes.queenkyiv.com/img/tables/table_button.png",
                        };

                        this.tablePresetObj.tables.push(this.tableButton);
                        return true;
                    }

                }

                this.tableButton.pivot = {
                    coord_x: this.presetMenuX,
                    coord_y: this.presetMenuY,
                    price: null,
                    seat_price: null,
                    button: true
                };
            },
            addTable() {
                this.additionalTable.pivot = {
                    coord_x: this.presetMenuX,
                    coord_y: this.presetMenuY,
                    price: this.presetMenuPrice,
                    seat_price: this.presetMenuSeatPrice,
                };
                this.tablePresetObj.tables.push(this.additionalTable);
                this.showTableList = false;
            },
            openDeleteTable(table) {
                this.deletingTable = true;
                this.tableToDelete = table;
            },
            confirmDeleteTable() {
                this.tablePresetObj.tables = this.tablePresetObj.tables.filter(
                    (table) => table.id !== this.tableToDelete.id
                );
                this.deletingTable = false;
            },
            openMoveTable(table) {
                this.showPrice = table.id !== null;
                this.showTableList = false;
                this.showTableMenu = false;
                this.tableToMove = table;
                this.prevX = table.pivot.coord_x;
                this.prevY = table.pivot.coord_y;
                this.movingTable = true;
                $(this.$refs.clickZone).on("click", (event) => {
                    if (event.target === this.$refs.clickZone) this.moveTable(event);
                });
            },
            moveTable(evt) {
                let rect = evt.target.getBoundingClientRect();
                this.tableToMove.pivot.coord_x = Math.floor(evt.clientX - rect.left);
                this.tableToMove.pivot.coord_y = Math.floor(evt.clientY - rect.top);
                this.tablePresetObj.tables[
                    this.tablePresetObj.tables.findIndex(
                        (table) => table.id === this.tableToMove.id
                    )
                    ] = this.tableToMove;
                this.$forceUpdate();
            },
            confirmTableMove() {
                $(this.$refs.clickZone).off("click");

                this.tablePresetObj.tables[
                    this.tablePresetObj.tables.findIndex(
                        (table) => table.id === this.tableToMove.id
                    )
                    ] = this.tableToMove;
                this.tableToMove = {pivot: {}};
                this.movingTable = false;
            },
            cancelTableMove() {
                $(this.$refs.clickZone).off("click");

                this.tablePresetObj.tables[
                    this.tablePresetObj.tables.findIndex(
                        (table) => table.id === this.tableToMove.id
                    )
                    ].pivot.coord_x = this.prevX;
                this.tablePresetObj.tables[
                    this.tablePresetObj.tables.findIndex(
                        (table) => table.id === this.tableToMove.id
                    )
                    ].pivot.coord_y = this.prevY;
                this.tableToMove = {pivot: {}};
                this.movingTable = false;
            },
            openTableMenu(evt, table) {
                this.showPresetMenu = false;
                let rect = evt.target.getBoundingClientRect();
                this.tableMenuX = Math.floor(evt.clientX - rect.left);
                this.tableMenuY = Math.floor(evt.clientY - rect.top);
                this.showTableMenu = table.id;
            },
            createItem() {
                this.itemToSubmit.restaurant_id = this.userRestaurant;
                return this.$http
                    .post(apiUrls.tablePreset, this.itemToSubmit)
                    .then((data) => data)
                    .catch((error) => {
                        this.handleFormValidationErrors(error, this.$refs.form);
                    });
            },
            editItem() {
                return this.$http
                    .post(
                        apiUrls.tablePreset + "/" + this.tablePresetObj.id,
                        this.itemToSubmit
                    )
                    .then((data) => data)
                    .catch((error) => {
                        this.handleFormValidationErrors(error, this.$refs.form);
                    });
            },
            async submitItem() {
                this.submitting = true;

                this.itemToSubmit = {
                    scale: +this.tablePresetObj.scale,
                    name: this.tablePresetObj.name,
                    comment: this.tablePresetObj.comment,
                    tables: this.tablePresetObj.tables.map((table) => ({
                        id: table.id,
                        coord_x: table.pivot.coord_x,
                        coord_y: table.pivot.coord_y,
                        width: table.pivot.width,
                        height: table.pivot.height,
                        price: table.pivot.price,
                        seat_price: table.pivot.seat_price,
                    })),
                    restaurant_space_id: this.tablePresetObj.restaurant_space.id,
                    restaurant_id: this.userRestaurant,
                    default: this.tablePresetObj.default,
                };

                let resp;

                if (this.type === "edit") {
                    resp = await this.editItem();
                } else {
                    resp = await this.createItem();
                }

                if (resp && resp.data && resp.data.success === true) {
                    this.$emit("itemSubmitSuccess", this.itemToSubmit);
                }

                this.submitting = false;
            },
            getRestaurantSpaces() {
                return this.$store.dispatch("getRestaurantSpaces").then((response) => {
                    this.restaurantSpaces = response;
                });
                // this.$http.get(apiUrls.restaurantSpace).then(resp => {
                //     if (resp && resp.data && resp.data.data && resp.data.data.items) {
                //         this.restaurantSpaces = resp.data.data.items;
                //     }
                // });
            },
        },
        mounted() {
            this.tablePresetObj = Object.assign({}, this.tablePreset);
            console.log(this.tablePresetObj);
            this.getTables();

            $(window).on("click", (evt) => {
                let $target = $(evt.target),
                    $parent = $(evt.target.parentElement);

                if (!$target.hasClass("preset") && !$target.parents(".preset").length) {
                    this.showPresetMenu = false;
                    this.showTableMenu = false;
                    return;
                }

                if (
                    $target.hasClass("preset__table") ||
                    $parent.hasClass("preset__table")
                ) {
                    this.showPresetMenu = false;
                } else {
                    this.showTableMenu = false;
                }
            });

            if (this.type === "create") {
                this.getRestaurantSpaces();
            }

            this.addWatcher("tablePresetObj");
        },
        destroyed() {
            $(window).off("click");
        },
    };
</script>
<style>
    .option__image {
        display: inline-block;
        width: 50px;
        object-fit: cover;
    }

    .multiselect__option {
        display: flex !important;
        align-items: center;
    }

    .multiselect__option--highlight {
        background: #f3f7fa;
        color: #000;
    }

    .multiselect__option--highlight::after {
        display: none;
    }

    .option__title {
        margin-left: 15px;
    }
</style>
