<template>
  <div class="section booking">
    <div class="booking__wrap">
      <div class="booking__left">
        <div class="site-input">
          <label for="tables">Зал</label>
          <div class="input-item input-item_booking">
            <multiselect
                id="tables"
                v-model="selectedSpace"
                :options="restaurantSpaces"
                :searchable="false"
                :close-on-select="true"
                placeholder="Выберите столы"
                :multiple="false"
                label="name"
                track-by="id"
                :selectLabel="''"
                :deselectLabel="''"
                :selectedLabel="'•'"
            ></multiselect>
          </div>
        </div>

        <div class="site-input">
          <label for="date_time">Дата</label>
          <div class="input-item">
            <datepicker
                id="date_time"
                v-model="date"
                lang="ru"
                appendToBody=""
                format="YYYY-MM-DD"
                :first-day-of-week="1"
                :width="'auto'"
                :valueType="'format'"
                :editable="false"
                :clearable="false"
            ></datepicker>
          </div>
        </div>
        <div class="booking__comment" v-if="loaded">
          <div v-if="!editComment" class="booking__comment-text">
            <span v-if="booking.tablesForDate.day_description">{{
                booking.tablesForDate.day_description
              }}</span>
            <span v-if="!booking.tablesForDate.day_description"
            >[описание дня будет здесь]</span
            >
            <span @click="tryEditComment"
            ><i class="fas fa-pencil-alt"></i
            ></span>
          </div>
          <div v-if="editComment">
            <textarea
                v-model="booking.tablesForDate.day_description"
            ></textarea>
            <p
                class="btn btn_success"
                @click="editTablePresetForDate({ goToForm: false })"
            >
              Сохранить
            </p>
          </div>
        </div>
      </div>

      <div class="booking__center">
        <h4 class="booking__event-subtitle">
          События в этом зале в этот день:
        </h4>
        <div v-if="booking.events && booking.events.length">
          <div
              class="booking__event"
              v-for="(event, index) in booking.events"
              :key="index"
          >
            <div
                class="booking__event-content"
                v-if="event && event.restaurant_space_id === selectedSpace.id"
            >
              <div class="booking__event-img" v-if="event.image_url">
                <img :src="event.image_url" alt/>
              </div>
              <div class="booking__event-text-content">
                <div class="booking__event-title">
                  <h3>{{ event.title }}</h3>
                </div>
                <div class="booking__event-params">
                  <h4>
                    Цена билета:
                    <span>{{ event.ticket_price }}</span>
                  </h4>
                  <h4>
                    Минимальный депозит:
                    <span>{{ event.min_deposit }}</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <vue-slider
        :width="800"
        v-model="timer"
        :data="timerItems"
    />
    <div class="book" v-if="loaded" :class="{ client: clientMode }">
      <div
          class="preset preset_nm"
          ref="preset"
          v-if="selectedSpace"
          :style="{
          background:
            'url(' +
            (selectedSpace ? selectedSpace.image_url : '') +
            ') center center / 100% 100% no-repeat',
          width: selectedSpace['size_x'] + 'px',
          height: selectedSpace['size_y'] + 'px',
        }"
      >
        <div class="book__controls">
          <div class="checkbox-control">
            <label for="switch">Режим гостя</label>
            <input type="checkbox" id="switch" v-model="clientMode"/>
          </div>
          <div class="fullscreen-mode" @click="toggleFullscreen">
            <span v-if="isFullscreenModeOn($route)">
              В стандартный режим
              <i class="fas fa-compress"></i>
            </span>
            <span v-else>
              На весь экран
              <i class="fas fa-expand"></i>
            </span>
          </div>
          <div
              class="checkbox-control"
              @click.stop.prevent="toggleNoReservesFromMobile"
          >
            <label for="no_reserves_from_mobile"
            >Запрет брони из приложения
            </label
            >
            <input
                type="checkbox"
                id="no_reserves_from_mobile"
                v-model="no_reserves_from_mobile"
            />
          </div>
        </div>

        <div v-if="tablesRender">

          <div
              v-for="(table, index) in tablePresetObj.tables"
              :key="index"
              class="preset__table"
              :data-background="table.image_url"
              :class="{
              selected: showTableMenu === table.id,
              additional: table.additional,
              busy: todayIsTheDay && table.busy_now === 'busy',
              draft: table.busy_now === 'draft',
              booked: getTableReserves(table).length > 0,
              pending:
                getTableReserves(table) &&
                getTableReserves(table)[0] &&
                +getTableReserves(table)[0].status === 1 &&
                getTableReserves(table)[0].from_mobile,
              expired: todayIsTheDay && isExpired(table),
              group: isInActiveGroup(table.group),
              hour_fill: getReservTiming(table, timer) === 'hour to reserv',
              now_fill: getReservTiming(table, timer) === 'busy now',
              have_fill: getReservTiming(table, timer) === 'have reservs',
              not_fill: getReservTiming(table, timer) === 'not reservs',
            }"
              :style="{
              top: table['pivot']['coord_y'] + 'px',
              left: table['pivot']['coord_x'] + 'px',
              height: table.seats_count * tableScale + 'px',
              'background-image': 'none',
              width: table.seats_count * tableScale + 'px',
              'margin-top': (-table.seats_count * tableScale) / 2 + 'px',
              'margin-left': (-table.seats_count * tableScale) / 2 + 'px',
              'box-shadow': '0 0 4px 4px ' + getGroupColor(table.group),
              background: getTableBackground(table, index),
            }"
              @click="
              getTableReserves(table).length
                ? openTableMenu(table)
                : addReserve(table)
            "
          >
            <div
                class="preset__table-reserve-options"
                v-if="table.reserves.length"
            >
              <span
                  v-for="(option, index) in getOptionsArr(
                  table.reserves[0].options
                )"
                  :key="index"
                  class="option"
                  :class="option.class"
              >{{ option.value }}</span
              >
            </div>
            <div class="table-content">
              <div class="text-wrapper">
                <div class="text">
                  <div class="name">{{ table.name }}</div>
                  <!-- {{ table.id }} -->
                  <div class="seats" v-if="table.id">(Мест: {{ table.seats_count }})</div>
                  <!-- {{ getTableReserves(table.id) }} -->
                  <div
                      class="preset__table-reserve__animation"

                  >
                    <div
                        class="preset__table-reserve"
                        v-for="(reserve, index) in getTableReserves(table)"
                        :key="index"
                        :style="{
                      height: 100 / table.reserves.length + '%',
                      width: '100%',
                    }"
                        v-show="getViewReserv(moment(reserve.date_finish).format('HH:mm'), timer)"
                    >
                      <div class="preset__table-reserve-text">
                      <span
                      >{{ reserve.client ? reserve.client.name : "" }}
                        {{ reserve.client ? reserve.client.last_name : "" }}
                      </span>
                        <span>({{ reserve.persons_count }} чел.) </span>
                        <span
                        >{{ moment(reserve.date_start).format("HH:mm") }} -
                        {{ moment(reserve.date_finish).format("HH:mm") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          /* eslint-enable */
        </div>
        <div
            class="booking-menu-wrapper"
            v-show="showTableMenu === tableToShow.id"
            @click.stop.self="showTableMenu = null"
        >
          <div class="booking-menu" @click.stop.self="showTableMenu = null">
            <div class="booking-menu__body">
              <h4>Стол: {{ tableToShow.name }}</h4>
              <h6>(на {{ tableToShow.seats_count }} человек)</h6>
              <button
                  class="btn btn_pale"
                  @click="takeTable(tableToShow)"
                  v-if="!tableToShow.busy_now && todayIsTheDay"
              >
                Занять стол
              </button>
              <button
                  class="btn btn_pale"
                  @click="freeTable(tableToShow)"
                  v-if="tableToShow.busy_now === 'busy' && todayIsTheDay"
              >
                Освободить стол
              </button>
              <button
                  class="btn btn_pale"
                  :disabled="true"
                  v-if="tableToShow.busy_now === 'draft'"
              >
                Стол в процессе букинга
              </button>
              <p class="menu-section">
                <b>Резервы на сегодня:</b>
              </p>
              <div
                  class="reserve"
                  v-for="(reserve, index) in getTableReserves(tableToShow)"
                  :key="index"
              >
                <p>
                  {{ reserve.client ? reserve.client.name : "" }}
                  {{ reserve.client ? reserve.client.last_name : "" || "" }}
                </p>
                <p>{{ reserve.client ? reserve.client.phone : "" }}</p>
                <p>{{ reserve.persons_count }} чел.</p>
                <p>Статус: {{ reserve.status }}</p>
                <p v-if="reserve.prepayment">
                  Предоплата: {{ reserve.prepayment }}
                </p>
                <p>
                  С:
                  {{
                    reserve.date_start.slice(10, reserve.date_start.length - 3)
                  }}
                </p>
                <p>
                  До:
                  {{
                    reserve.date_finish.slice(
                        10,
                        reserve.date_finish.length - 3
                    )
                  }}
                </p>
                <p>Комментарий: {{ reserve.comment }}</p>
                <button
                    class="btn btn_primary"
                    :disabled="!!tableToShow.busy_now && todayIsTheDay"
                    @click="editReserve(reserve)"
                >
                  Редактировать резерв
                </button>
                <button
                    class="btn btn_danger"
                    :disabled="!!tableToShow.busy_now && todayIsTheDay"
                    @click="deleteReserve(reserve)"
                >
                  Удалить резерв
                </button>
              </div>
              <p v-if="getTableReserves(tableToShow).length === 0">
                [нет резервов]
              </p>
              <button
                  class="btn btn_success"
                  :disabled="!!tableToShow.busy_now && todayIsTheDay"
                  @click="addReserve(tableToShow)"
              >
                Добавить резерв
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="booking__bottom" v-if="loaded">
      <div class="booking__bottom-buttons">
        <button class="btn btn_primary" @click="bookAllTables">
          Занять весь зал
        </button>
        <button
            class="btn btn_success"
            @click="editTablePresetForDate({ goToForm: true })"
        >
          Изменить расстановку
        </button>
      </div>
      <div class="booking__overbookReserves">
        <div class="overbooking-container">
          <div
              v-for="(reserve, index) in overbookReserves"
              :key="index"
              class="preset__table booked"
              :class="{
              selected: showTableMenu === reserve.id,
              additional: reserve.additional,
              expired: todayIsTheDay && isExpired(reserve),
              group: isInActiveGroup(reserve.group),
              pending: +reserve.status === 1 && reserve.from_mobile,
            }"
              :style="{
              'background-image': 'none',
              'box-shadow': '0 0 4px 4px ' + getGroupColor(reserve.group),
            }"
              @click="editReserve(reserve)"
          >
            <div class="preset__table-reserve-options" v-if="reserve.length">
              <span
                  v-for="(option, index) in getOptionsArr(reserve[0].options)"
                  :key="index"
                  class="option"
                  :class="option.class"
              >{{ option.value }}</span
              >
            </div>
            <div class="table-content">
              <div class="text-wrapper">
                <div class="text">
                  <div
                      class="preset__table-reserve"
                      :key="index"
                      :style="{
                      height: 100 / reserve.length + '%',
                      width: '80%',
                    }"
                  >
                    <div class="preset__table-reserve-text">
                      <span
                      >{{ reserve.client ? reserve.client.name : "" }}
                        {{ reserve.client ? reserve.client.last_name : "" }}
                      </span>
                      <span>({{ reserve.persons_count }} чел.) </span>
                      <span
                      >{{ moment(reserve.date_start).format("HH:mm") }} -
                        {{ moment(reserve.date_finish).format("HH:mm") }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- {{ tablePresetObj["tables"] }} -->

    <div
        v-if="reserveToDelete"
        class="modal"
        :class="{
        fade: !showReserveDeleteConfirmation,
        open: showReserveDeleteConfirmation,
      }"
        @click.self.stop="showReserveDeleteConfirmation = false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-body">
            <div
                class="popup__close"
                @click="showReserveDeleteConfirmation = false"
            >
              &times;
            </div>
            <h4>Удалить резерв {{ reserveToDelete.id }}?</h4>
            <div class="popup__buttons popup__buttons_spread popup__buttons_mt">
              <button
                  class="btn btn_primary"
                  @click.prevent="showReserveDeleteConfirmation = false"
              >
                Отмена
              </button>
              <button
                  class="btn btn_danger"
                  @click.prevent="deleteConfirmed(reserveToDelete)"
              >
                Удалить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section reserves" v-if="booking.events && booking.events.length">
      <h3 class="section__title" style="font-size: 3rem;">Билеты</h3>

      <data-table ref="table" :options="tableOptions"></data-table>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Datepicker from "vue2-datepicker";
import {apiUrls, keys, misc} from "@/config/constants";
import handleFormValidationErrors from "@/assets/js/mixins/handleFormValidationErrors";
import moment from "moment";
import {mapGetters} from "vuex";
import DataTable from "@/components/global/DataTable/index";
import table_name from '@/components/global/DataTable/templates/tables';
import event_name from '@/components/global/DataTable/templates/event';
import client_name from '@/components/global/DataTable/templates/client';

const groupColors = [
  "#FF6633",
  "#FF33FF",
  "#00B3E6",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
];

export default {
  name: "Booking",
  components: {
    Multiselect,
    Datepicker,
    DataTable,
  },
  mixins: [handleFormValidationErrors],
  data() {
    return {
      timer: '06:00',
      tableOptions: {
        type: 'ticket',
        getDataParams: {
          orderBy: 'created_at',
          sortedBy: 'desc',
          date: ((this.$store.state.bookingState === null && JSON.parse(sessionStorage[keys.bookingState.local] || "null")) ? JSON.parse(sessionStorage[keys.bookingState.local] || "null").date
              : ((this.$store.state.bookingState) ? JSON.parse(sessionStorage[keys.bookingState.local] || "null").date : moment(
                  new Date(this.dateSubtractHours(misc.WORKING_HOURS_OFFSET))
              ).format("YYYY-MM-DD")))
        },
        columns: ['id', 'event_name', 'client_name', 'persons_count', 'date_start', 'created_at', 'options', 'status', 'edit'],
        filterable: ['event_name', 'client_name', 'persons_count', 'date_start'],
        searchParams: ['event_name', 'client_name', 'table_name'],
        headings: {
          id: '#',
          'event_name': 'Название события',
          'client_name': 'Имя клиента',
          'table_name': 'Стол(ы)',
          persons_count: 'Количество человек',
          date_start: 'Дата начала',
          date_finish: 'Дата окончания',
          options: 'Статус',
          status: 'Статус Заказа',
          edit: 'Действия',
          prepayment: 'Предоплата',
          prepayment_hostes: 'Предоплата (хостес)',
          comment: 'Комментарий',
          tables: 'Столы',
          restaurant_space_id: 'Зал Ресторана',
          event_id: 'Событие',
          products: 'Продукты',
          total_price: 'Сумма Бронирования',
          hostes_client_name: 'Забронировал',

          avg_check: 'Средний чек',
          name: 'Имя клиента',
          last_name: 'Фамилия клиента',
          email: 'email',
          phone: 'Телефон',
          created_at: 'Создан',
          updated_at: 'Обновлен',
          image_url: 'Изображение',

          title: 'Название',
          date_time: 'Дата',
          description: 'Описание',
          ticket_price: 'Цена билета',
          min_deposit: 'Минимальный депозит',
        },
        templates: {event_name, client_name, table_name},
        prefetchDataList: [],
        actions: ['view', 'edit', 'delete', 'copy'],
      },
      moment: moment,
      booking: {tablesForDate: {}, tablePreset: {}, events: [], reserves: []},
      loaded: false,
      loading: false,
      date: "",
      tablesRender: false,
      selectedSpace: {},
      restaurantSpaces: [],
      comment: "",
      editComment: false,
      tablePresetObj: {tablesForDate: {}, tablePreset: {}},
      showTableList: false,
      additionalTable: null,
      showTableMenu: null,
      tableToShow: {reserves: []},
      reserveToEdit: null,
      reserveToDelete: null,
      showReserveDeleteConfirmation: false,
      reserveToAdd: null,
      clientMode: false,
      overbookReserves: [],
      changePreset: true,
      todayIsTheDay: true,
      no_reserves_from_mobile: false,
      bookingInterval: null,
    };
  },
  computed: {
    getReservTiming() {
      return (table, timerTime) => {
        const reservs = table.reserves
        const timer = parseInt(timerTime.replace(':', ''))
        let result = ''
        if (reservs) {
          for (const reserv of reservs) {
            const start = parseInt(moment(reserv.date_start).format("HH:mm").replace(':', ''))
            const finish = parseInt(moment(reserv.date_finish).format("HH:mm").replace(':', ''))
            if (this.getViewReserv(moment(reserv.date_finish).format("HH:mm"), timerTime)) {
              result = 'have reservs'
              if (start - timer <= 100 && start - timer > 0 ) {
                result = 'hour to reserv'
                return result
              } else if (timer >= start && timer <= finish ) {
                result = 'busy now'
                return result
              }
              return result
            }
          }
        }
        result = 'not reservs'
        return result
      }
    },
    getViewReserv() {
      return (finishTime, timerTime) => {
          const finish = parseInt(finishTime.replace(':', ''))
          const timer = parseInt(timerTime.replace(':', ''))
          return finish >= timer
      }
    },
    tableScale() {
      return this.tablePresetObj && this.tablePresetObj.scale
          ? this.tablePresetObj.scale * 5
          : 5;
    },
    timerItems() {
      const items = [6, 6.3, 7, 7.3, 8, 8.3, 9, 9.3, 10, 10.3, 11, 11.3, 12, 12.3, 13, 13.3, 14, 14.3, 15, 15.3, 16, 16.3, 17, 17.3, 18, 18.3, 19, 19.3, 20, 20.3, 21, 21.3, 22, 22.3, 23, 23.3, 0, 0.3, 1, 1.3, 2, 2.3, 3, 3.3, 4, 4.3, 5, 5.3]
      const copy = []
      items.forEach(item => {
        item < 10
            ? copy.push(`0${!Number.isInteger(item) ? Math.floor(item) : item}:${!Number.isInteger(item) ? 3 : 0}0`)
            : copy.push(`${!Number.isInteger(item) ? Math.floor(item) : item}:${!Number.isInteger(item) ? 3 : 0}0`)
      })
      return copy
    },
    ...mapGetters(["isFullscreenModeOn", "userRestaurant"]),

  },
  watch: {
    selectedSpace(val) {
      let bs = this.$store.state.bookingState;
      if (bs) {
        bs.selectedSpace = val;
      } else {
        bs = {selectedSpace: val};
      }
      this.$store.dispatch("updateBookingState", bs).then(() => {
        this.getBooking();
      });
    },
    date(val) {
      let bs = this.$store.state.bookingState;
      if (bs) {
        bs.date = val;
      } else {
        bs = {date: val};
      }
      this.$store.dispatch("updateBookingState", bs).then(() => {
        this.getBooking();
        if (this.booking.events && this.booking.events.length) {
          this.$refs.table.getData(val);
        }
      });
    },
  },
  methods: {
    getTableReserves(table) {
      //   console.log("getTableReserves ID: ", table.id, " Name: ", table.name);
      //   console.log("this.tablePresetObj", this.tablePresetObj);
      if (table.id === null) {
        return [];
      }
      for (let i = 0; i < this.tablePresetObj.tables.length; i++) {
        if (this.tablePresetObj.tables[i].id == table.id) {
          return this.tablePresetObj.tables[i].reserves;
        }
      }
      return [];
    },
    getRestaurantSpaces() {
      return this.$store
          .dispatch("getRestaurantSpaces")
          .then((response) => {
            this.restaurantSpaces = response;
          })
          .catch((error) => console.log(error));
    },
    getBooking() {
      try {
        if (this.loading || !this.date) return;

        this.loading = true;

        if (!this.selectedSpace || !this.selectedSpace.id) {
          this.selectedSpace = this.restaurantSpaces.length
              ? this.restaurantSpaces[0]
              : {id: 1};
        }

        return this.$http
            .get(apiUrls.booking + "/" + this.selectedSpace.id + "/" + this.date)
            .then(async (response) => {
              // console.log("getBooking", response);
              if (!response || !response.data || !response.data.data) return;

              this.booking = response.data.data;
              var tempTablePresetObj = this.booking.tablePreset;
              // this.tablePresetObj = this.booking["tablePreset"];
              // console.log("tablePresetObj ASSIGNED !!! ", this.tablePresetObj);
              this.overbookReserves = this.booking.overbookReserves.filter(
                  (item) =>
                      parseInt(item.restaurant_space_id) == this.selectedSpace.id
              );
              if (!this.booking.tablesForDate) {
                this.booking.tablesForDate = {additional_tables: []};
                this.no_reserves_from_mobile = false;
              } else {
                this.no_reserves_from_mobile = !!this.booking.tablesForDate
                    .no_reserves_from_mobile;
              }

              // console.log(
              //   "this.booking.tablesForDate.additional_tables",
              //   this.booking.tablesForDate.additional_tables
              // );
              // console.log(
              //   "this.tablePresetObj.tables",
              //   this.tablePresetObj.tables
              // );

              // console.log("tempTablePresetObj", tempTablePresetObj);
              // return;

              // TODO скрыть основные столы, если есть дополнительные
              if (this.booking.tablesForDate.additional_tables.length) {
                tempTablePresetObj.tables = this.booking.tablesForDate.additional_tables;
              }

              /*tempTablePresetObj.tables = tempTablePresetObj.tables.concat(
                  this.booking.tablesForDate.additional_tables
              );*/

              for (let i = 0; i < tempTablePresetObj.tables.length; i++) {
                tempTablePresetObj.tables[i].reserves = [];
              }
              //   .map((table) => Object.assign(table, { reserves: [] }));

              // console.log("tablePresetObj", tempTablePresetObj);

              // console.log("this.booking.reserves", this.booking.reserves);

              await this.booking.reserves.forEach(async (reserve) => {
                //   console.log("this.booking.reserves", reserveIdx);
                await reserve.tables.forEach((reserveTable, index) => {
                  // console.log("reserve.tables", index);
                  let tableIdx = -1;
                  for (let i = 0; i < tempTablePresetObj.tables.length; i++) {
                    if (tempTablePresetObj.tables[i].id == reserveTable.id) {
                      // console.log(
                      //   "tableMatch",
                      //   tempTablePresetObj.tables[i].id,
                      //   reserveTable.id,
                      //   "index: ",
                      //   i
                      // );
                      tableIdx = i;
                      break;
                    }
                  }
                  let tableToUpdate = tempTablePresetObj.tables[tableIdx];
                  //  this.tablePresetObj.tables.find(
                  //   (table) => table.id === reserveTable.id
                  // );

                  if (!tableToUpdate) {
                    //   console.log("!!!!!!!!!! !tableToUpdate");
                    Object.assign(tableToUpdate, reserveTable);
                  }

                  tableToUpdate.reserves = tableToUpdate.reserves
                      ? tableToUpdate.reserves
                      : [];
                  tableToUpdate.reserves.push(reserve);
                  if (reserve.tables.length > 1) {
                    tableToUpdate.group = {
                      index: index,
                      start: reserve.date_start,
                      finish: reserve.date_finish,
                    };
                  }

                  // console.log(
                  //   "reserveTable",
                  //   reserveTable.name,
                  //   reserveTable,
                  //   tableToUpdate
                  // );
                  // tempTablePresetObj.tables[tableIdx].busy_now = true;
                  // Object.assign(
                  //   tempTablePresetObj.tables[tableIdx].reserves,
                  //   tableToUpdate.reserves
                  // );
                  // this.tablePresetObj.tables[tableIdx] = tableToUpdate;
                  // console.log(
                  //   "this.tablePresetObj.tables",
                  //   tempTablePresetObj.tables
                  // );
                });
              });

              this.tablePresetObj = tempTablePresetObj;
              // console.log(
              //   "!!!this.tablePresetObj.tables",
              //   this.tablePresetObj.tables
              // );

              this.tablesRender = false;
              this.$nextTick(() => {
                this.tablesRender = true;
              });

              this.loaded = true;
              this.loading = false;

              let today = new Date(
                      this.dateSubtractHours(misc.WORKING_HOURS_OFFSET)
                  ),
                  theDay = new Date(this.date);
              this.todayIsTheDay =
                  theDay.getDate() === today.getDate() &&
                  theDay.getMonth() === today.getMonth() &&
                  theDay.getFullYear() === today.getFullYear();
            })
            .catch((error) => {
              console.log(error);
              this.handleFormValidationErrors(error, this.$refs.form);
            });
      } catch (e) {
        console.log(e);
      }
    },
    tryEditComment() {
      this.editComment = true;
    },
    openTableMenu(table) {
      this.tableToShow = table;
      this.showTableMenu = table.id;
    },
    editReserve(reserve) {
      reserve.table_id = this.showTableMenu;
      this.startDraft(this.tableToShow);
      this.showTableMenu = null;
      this.$store
          .dispatch("updateBookingState", {
            selectedSpace: this.selectedSpace,
            date: this.date,
            table: reserve.tables,
            qr_codes: reserve.qr_codes,
            wasBusy:
                reserve.tables && reserve.tables.length
                    ? reserve.tables[0].busy_now === "busy"
                    : reserve.tables
                        ? reserve.tables.busy_now === "busy"
                        : false,
          })
          .then(() => this.$router.push("/reserves/" + reserve.id + "/edit"));
    },
    addReserve(table) {
      this.startDraft(table);
      this.$store
          .dispatch("updateBookingState", {
            selectedSpace: this.selectedSpace,
            date: this.date,
            table: table,
          })
          .then(() => this.$router.push("/reserves/create"));
    },
    deleteReserve(reserve) {
      this.reserveToDelete = reserve;
      this.showReserveDeleteConfirmation = true;
    },
    deleteConfirmed(reserve) {
      this.$http
          .delete(apiUrls.reserve + "/" + reserve.id)
          .then((resp) => {
            this.showReserveDeleteConfirmation = false;

            if (resp.data && resp.data.success === true) {
              this.getBooking().then(() => {
                this.tableToShow = this.tablePresetObj.tables.find(
                    (table) => table.id === this.tableToShow.id
                );
              });
            }
          })
          .catch((err) => console.log(err));
    },
    takeTable(tableEntity, update = false) {
      if (!tableEntity) return;

      if ("number" === typeof tableEntity.id) {
        tableEntity.busy_now = "busy";
        this.$http.post("tables/" + tableEntity.id, tableEntity).then(() => {
          if (update) {
            this.getBooking().then(() => {
              this.tableToShow = this.tablePresetObj.tables.find(
                  (table) => table.id === this.tableToShow.id
              );
            });
          }
        });
      } else if (tableEntity.length) {
        tableEntity[0].busy_now = "busy";
        this.$http
            .post("tables/" + tableEntity[0].id, tableEntity[0])
            .then(() => {
              if (update) {
                this.getBooking().then(() => {
                  this.tableToShow = this.tablePresetObj.tables.find(
                      (table) => table.id === this.tableToShow.id
                  );
                });
              }
            });
      }
    },
    startDraft(tableEntity, update = false) {
      if (!tableEntity) return;

      if ("number" === typeof tableEntity.id) {
        if (tableEntity.busy_now === "busy") return;
        tableEntity.busy_now = "draft";
        this.$http.post("tables/" + tableEntity.id, tableEntity).then(() => {
          if (update) {
            this.getBooking().then(() => {
              this.tableToShow = this.tablePresetObj.tables.find(
                  (table) => table.id === this.tableToShow.id
              );
            });
          }
        });
      } else if (tableEntity.length) {
        if (tableEntity[0].busy_now === "busy") return;
        tableEntity[0].busy_now = "draft";
        this.$http
            .post("tables/" + tableEntity[0].id, tableEntity[0])
            .then(() => {
              if (update) {
                this.getBooking().then(() => {
                  this.tableToShow = this.tablePresetObj.tables.find(
                      (table) => table.id === this.tableToShow.id
                  );
                });
              }
            });
      }
    },
    freeTable(tableEntity, update = false) {
      return new Promise((resolve) => {
        if (!tableEntity) resolve();

        if ("number" === typeof tableEntity.id) {
          tableEntity.busy_now = "";
          this.$http.post("tables/" + tableEntity.id, tableEntity).then(() => {
            if (update) {
              this.getBooking().then(() => {
                this.tableToShow = this.tablePresetObj.tables.find(
                    (table) => table.id === this.tableToShow.id
                );
                resolve();
              });
            } else {
              resolve();
            }
          });
        } else if (tableEntity.length) {
          tableEntity[0].busy_now = "";
          this.$http
              .post("tables/" + tableEntity[0].id, tableEntity[0])
              .then(() => {
                if (update) {
                  this.getBooking().then(() => {
                    this.tableToShow = this.tablePresetObj.tables.find(
                        (table) => table.id === this.tableToShow.id
                    );
                    resolve();
                  });
                } else {
                  resolve();
                }
              });
        }
      });
    },
    getOptionsArr(reserveOptions) {
      if (reserveOptions && reserveOptions.length) {
        return reserveOptions.split(", ").map((option) => {
          let formattedOption = {};
          switch (option) {
            case "ПО":
              formattedOption.class = "po";
              break;
            case "ПС":
              formattedOption.class = "ps";
              break;
            case "ПЗ":
              formattedOption.class = "pz";
              break;
            default:
              break;
          }

          formattedOption.value = option;

          return formattedOption;
        });
      } else {
        return [];
      }
    },
    isExpired(table) {
      return (
          table.reserves &&
          table.reserves.length &&
          new Date().getTime() > new Date(table.reserves[0].date_start).getTime()
      );
    },
    isInActiveGroup(group) {
      return group
          ? moment(new Date()).isBetween(
              moment(group.start),
              moment(group.finish)
          )
          : false;
    },
    getGroupColor(group) {
      if (!group || !group.hasOwnProperty("index")) return "transparent";
      return "number" === typeof group.index
          ? groupColors[group.index]
          : "transparent";
    },
    editTablePresetForDate({goToForm = false}) {
      this.editComment = false;
      if (this.booking.tablesForDate.id) {
        this.$http
            .put(apiUrls.tablesForDate + "/" + this.booking.tablesForDate.id, {
              day_description: this.booking.tablesForDate.day_description,
              date: this.booking.tablesForDate.date,
              table_preset_id: this.booking.tablesForDate.table_preset_id,
              restaurant_space_id: this.booking.tablesForDate.restaurant_space_id,
              no_reserves_from_mobile: +this.no_reserves_from_mobile,
              additional_tables: this.booking.tablesForDate.additional_tables.map(
                  (table) => ({
                    coord_x: table.pivot.coord_x,
                    coord_y: table.pivot.coord_y,
                    width: table.pivot.width,
                    height: table.pivot.height,
                    price: table.pivot.price,
                    id: table.id,
                  })
              ),
            })
            .then(() => {
              this.getBooking();
              if (goToForm) {
                this.$store
                    .dispatch("setItemToEdit", {
                      id: this.booking.tablesForDate.id,
                      type: "tablesForDate",
                    })
                    .then(() => {
                      this.$router.push("/table-presets/on-date");
                    });
              }
            });
      } else {
        this.$http
            .post(apiUrls.tablesForDate, {
              day_description: this.booking.tablesForDate.day_description,
              table_preset_id: this.booking.tablePreset.id,
              date: this.date,
              no_reserves_from_mobile: +this.no_reserves_from_mobile,
              restaurant_space_id: this.booking.tablePreset.restaurant_space_id,
            })
            .then(() => {
              this.getBooking();
              if (goToForm) {
                this.$store
                    .dispatch("setItemToEdit", {
                      id: "last",
                      type: "tablesForDate",
                    })
                    .then(() => {
                      this.$router.push("/table-presets/on-date");
                    });
              }
            });
      }
    },
    toggleFullscreen() {
      this.$store.dispatch("toggleBookingFullscreen");
    },
    bookAllTables() {
      this.$store
          .dispatch("updateBookingState", {
            selectedSpace: this.selectedSpace,
            date: this.date,
            table: this.tablePresetObj.tables,
          })
          .then(() => this.$router.push("/reserves/create"));
    },
    dateSubtractHours(hours) {
      return new Date().setTime(new Date().getTime() - hours * 60 * 60 * 1000);
    },
    toggleNoReservesFromMobile() {
      this.no_reserves_from_mobile = !this.no_reserves_from_mobile;
      this.editTablePresetForDate({goToForm: false});
    },
    getTableBackground(table) {
      if (this.clientMode) {
        return (
            "url(" + table.image_url + ") center center / 100% 100% no-repeat"
        );
      }
    },
  },
  mounted() {
    let sessionBookingState = JSON.parse(
            sessionStorage[keys.bookingState.local] || "null"
        ),
        draftedTable =
            this.$store.state.bookingState &&
            this.$store.state.bookingState.table &&
            !this.$store.state.bookingState.wasBusy &&
            this.$store.state.bookingState.table.busy_now !== "busy"
                ? this.$store.state.bookingState.table
                : null;

    this.getRestaurantSpaces().then(() => {
      if (this.$store.state.bookingState === null && sessionBookingState) {
        sessionBookingState.table = null;
        sessionBookingState.wasBusy = null;
        this.date = sessionBookingState.date;
        this.selectedSpace = Object.assign(
            {},
            this.restaurantSpaces.find(
                (space) => space.id === sessionBookingState.selectedSpace.id
            )
        );
      } else if (this.$store.state.bookingState) {
        sessionBookingState = {
          table: null,
          wasBusy: null,
          date: this.$store.state.bookingState.date,
          selectedSpace: this.$store.state.bookingState.selectedSpace,
        };

        this.date = sessionBookingState.date;
        this.selectedSpace = Object.assign(
            {},
            this.restaurantSpaces.find(
                (space) => space.id === sessionBookingState.selectedSpace.id
            )
        );
      } else if (!this.$store.state.bookingState && !sessionBookingState) {
        this.date = moment(
            new Date(this.dateSubtractHours(misc.WORKING_HOURS_OFFSET))
        ).format("YYYY-MM-DD");
        this.selectedSpace = Object.assign({}, this.restaurantSpaces[0]);

        sessionBookingState = {
          table: null,
          wasBusy: null,
          date: this.date,
          selectedSpace: this.selectedSpace,
        };
      }

      this.$store
          .dispatch("updateBookingState", sessionBookingState)
          .then(() => {
            if (draftedTable) {
              this.freeTable(draftedTable).then(() => {
                this.getBooking();
              });
            } else {
              this.getBooking();
            }
          });
    });

    this.bookingInterval = setInterval(this.getBooking, 60000);
  },
  beforeDestroy() {
    clearInterval(this.bookingInterval);
  },
};
</script>
