<template>
    <div class="sidebar-wrapper">
        <div class="sidebar" :class="{'mini': sidebarMinimized}">
            <div class="sidebar__toggle" @click="toggleSidebar">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14"><g><g><g><path fill="none" stroke-miterlimit="50" stroke-width="2" d="M0 1h16" class="stroke"></path></g><g><path fill="none" stroke-miterlimit="50" stroke-width="2" d="M0 7h16" class="stroke"></path></g><g><path fill="none" stroke-miterlimit="50" stroke-width="2" d="M0 13h16" class="stroke"></path></g></g></g>
                </svg>
            </div>
            <div class="sidebar__logo" @click="toMain">
                <span class="sidebar__item-text">Bulldozer Group</span>
            </div>
            <nav class="sidebar__nav">
                <div class="sidebar__list">
<!--                    <router-link to="/users" class="sidebar__item">-->
<!--                        <span class="sidebar__item-content">-->
<!--                            <i class="fas fa-users"></i>-->
<!--                            <span class="sidebar__item-text">Пользователи</span>-->
<!--                        </span>-->
<!--                    </router-link>-->
                    <router-link to="/booking" class="sidebar__item">
                        <span class="sidebar__item-content">
                            <i class="fas fa-pen-fancy"></i>
                            <span class="sidebar__item-text">Букинг</span>
                        </span>
                    </router-link>
                    <router-link to="/tables" class="sidebar__item">
                        <span class="sidebar__item-content">
                            <i class="fas fa-layer-group"></i>
                            <span class="sidebar__item-text">Столы</span>
                        </span>
                    </router-link>
                    <router-link to="/events" class="sidebar__item">
                        <span class="sidebar__item-content">
                            <i class="fas fa-calendar-alt"></i>
                            <span class="sidebar__item-text">События</span>
                        </span>
                    </router-link>
                    <router-link to="/reserves" class="sidebar__item">
                        <span class="sidebar__item-content">
                            <i class="fas fa-clipboard-list"></i>
                            <span class="sidebar__item-text">Резервы</span>
                        </span>
                    </router-link>
                    <router-link to="/reserves-from-app" class="sidebar__item">
                        <span class="sidebar__item-content">
                            <i class="fa fa-mobile">
                                <span class="sidebar__item-counter" v-if="parseFloat(reservesFromAppCounter) > 0">{{reservesFromAppCounter}}</span>
                            </i>
                            <span class="sidebar__item-text sidebar__item-text_two-lines">Резервы из приложения</span>
                        </span>
                    </router-link>
                    <router-link to="/meet" class="sidebar__item">
                        <span class="sidebar__item-content">
                            <i class="fas fa-user-plus"></i>
                            <span class="sidebar__item-text">Встретить гостя</span>
                        </span>
                    </router-link>
                    <router-link to="/table-presets" class="sidebar__item">
                        <span class="sidebar__item-content">
                            <i class="fas fa-chess-board"></i>
                            <span class="sidebar__item-text">Расстановки</span>
                        </span>
                        <router-link to="/table-presets/saved" class="sidebar__item sidebar__item_nested">
                                <span class="sidebar__item-content sidebar__item-content_nested">
                                    <i class="far fa-save"></i>
                                    <span class="sidebar__item-text">Сохраненные</span>
                                </span>
                            </router-link>
                            <router-link to="/table-presets/on-date" class="sidebar__item sidebar__item_nested">
                                <span class="sidebar__item-content sidebar__item-content_nested">
                                    <i class="fas fa-list-ol"></i>
                                    <span class="sidebar__item-text">По датам</span>
                                </span>
                            </router-link>
                    </router-link>
<!--                    <router-link to="/admin-log" class="sidebar__item">-->
<!--                        <span class="sidebar__item-content">-->
<!--                            <i class="fas fa-scroll"></i>-->
<!--                            <span class="sidebar__item-text">Логи резервов</span>-->
<!--                        </span>-->
<!--                    </router-link>-->
                    <a class="sidebar__item sidebar__item_special" @click.prevent.stop="logout">
                        <span class="sidebar__item-content">
                            <i class="fas fa-sign-out-alt"></i>
                            <span class="sidebar__item-text">Выйти</span>
                        </span>
                    </a>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from  'vuex';

export default {
    name: "Sidebar",
    computed: {
        ...mapState(['sidebarMinimized']),
        ...mapGetters(['reservesFromAppCounter'])
    },
    methods: {
        toMain() {
            this.$router.push('/');
        },
        toggleSidebar() {
            this.$store.dispatch('toggleSidebar');
        },
        logout() {
            this.$store.dispatch('logout').then(() => {
                this.$router.push('/login');
            });
        },
        updateReservesFromApp() {
            this.$store.dispatch('getReservesFromApp');
        }
    },
    mounted() {
        this.updateReservesFromApp();

        setInterval(this.updateReservesFromApp, 60000);
    }
}
</script>