<template>
  <div class="section table-presets-for-date">
    <h2 class="section__title">Расстановки столов: по датам</h2>
    <div class="get-data-param-select">
      <div class="label">Отфильтровать для конкретного зала:</div>
      <div class="site-input">
        <multiselect
          v-model="selectedSpace"
          :options="restaurantSpaces"
          :searchable="true"
          :close-on-select="true"
          :clear-on-select="true"
          placeholder="Выберите зал"
          :multiple="false"
          :trackBy="'id'"
          :label="'name'"
          :selectLabel="'Выбрать'"
          :deselectLabel="''"
          :selectedLabel="'Выбрано'"
        >
          <template slot="option" slot-scope="props">
            <img
              v-show="props.option.image_url"
              class="option__image"
              :src="props.option.image_url"
              :alt="props.option.name"
            />
            <div class="option__desc">
              <span class="option__title">{{ props.option.name }}</span>
            </div>
          </template>
          <span slot="noResult">По вашему запросу нет результатов.</span>
        </multiselect>
      </div>
    </div>

    <data-table ref="table" :options="tableOptions"></data-table>
  </div>
</template>

<script>
import DataTable from "@/components/global/DataTable/index";
// import { apiUrls } from "@/config/constants";
import Multiselect from "vue-multiselect";

export default {
  name: "TablesForDate",
  components: {
    DataTable,
    Multiselect,
  },
  data() {
    return {
      restaurantSpacesLoaded: false,
      selectedSpace: {},
      restaurantSpaces: [],
      tableOptions: {
        type: "tablesForDate",
        getDataParams: { restaurant_space_id: "" },
        columns: ["id", "date", "table_preset.name", "edit"],
        filterable: ["date"],
        sortable: [], //todo ?
        headings: {
          id: "#",
          date: "Дата",
          "table_preset.name": "На базе расстановки",
          edit: "Действия",

          table_preset: "На базе расстановки",
          day_description: "Описание дня",
          created_at: "Создан",
          updated_at: "Обновлен",
        },
        prefetchDataList: [],
        actions: ["view", "edit", "delete", "copy"],
      },
    };
  },
  methods: {
    getRestaurantSpaces() {
      return this.$store.dispatch("getRestaurantSpaces").then((response) => {
        this.restaurantSpaces = response;
      });

      // return this.$http.get(apiUrls.restaurantSpace)
      //     .then(response => {
      //         this.restaurantSpaces = response.data.data.items;
      //     })
      //     .catch(error => {
      //         this.handleFormValidationErrors(error, this.$refs.form);
      //     });
    },
    getDataByRestSpaceId(id) {
      this.tableOptions.getDataParams = { restaurant_space_id: id };
      this.$refs.table.getData();
    },
  },
  watch: {
    selectedSpace: {
      handler(newVal) {
        this.getDataByRestSpaceId(newVal.id);
      },
      deep: true,
    },
  },
  mounted() {
    this.getRestaurantSpaces();
  },
};
</script>
