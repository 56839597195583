<template>
  <div>
    <div v-if="showeditClient">
      <div class="popup__close" @click="closeEditClient">&times;</div>
      <div v-if="clientObj" class="client_info-edit">
        <h3 class="section__title" v-if="typeClient === 'create'">Создать нового клиента</h3>
        <h3 class="section__title" v-if="typeClient === 'edit'">Редактировать данные:</h3>
        <div class="site-input">
          <label for="name">Имя</label>
          <div class="input-item">
            <input id="name" type="text" v-model="clientObj.name"/>
          </div>
        </div>
        <div class="site-input">
          <label for="last_name">Фамилия</label>
          <div class="input-item">
            <input id="last_name" type="text" v-model="clientObj.last_name"/>
          </div>
        </div>
        <div class="site-input">
          <label for="email">Email</label>
          <div class="input-item">
            <input id="email" type="text" v-model="clientObj.email"/>
          </div>
        </div>
        <div class="site-input">
          <label for="birthday">День рождения</label>
          <div class="input-item">
            <datepicker
                id="birthday"
                v-model="clientObj.birthday"
                lang="ru"
                :appendToBody="false"
                :first-day-of-week="1"
                :width="'auto'"
                :valueType="'format'"
            ></datepicker>
          </div>
        </div>
        <div class="site-input">
          <label for="phone">Телефон</label>
          <div class="input-item">
            <input id="phone" type="text" v-model="clientObj.phone"/>
          </div>
        </div>
        <div class="site-input">
          <label for="client_comment">Комментарий</label>
          <div class="input-item">
            <textarea id="client_comment" type="text" v-model="clientObj.comment"></textarea>
          </div>
        </div>
        <div class="form__row">
          <a
              href
              v-if="typeClient === 'edit'"
              class="btn btn_primary"
              @click.prevent="editClient"
          >Редактировать</a>
          <a href v-else class="btn btn_primary" @click.prevent="creatNewClient">Сохранить</a>
        </div>
      </div>
    </div>

    <div v-else class="reserve-form">
      <h2 class="section__title" v-if="type === 'create'">Создать резерв</h2>
      <h2 class="modal__title" v-if="type === 'edit'">Редактировать резерв: {{ reserveObj.id }}</h2>
      <form class="form" @submit.prevent="submitItem" ref="form">
        <div class="form__row">
          <div class="site-input">
            <label for="client_id">Клиент</label>
            <div class="input-item client_multiselect" id="client_id">
              <multiselect
                  v-model="clientObj"
                  :options="clients"
                  :searchable="true"
                  :internal-search="false"
                  :loading="loadingUsers"
                  :close-on-select="true"
                  :clear-on-select="true"
                  placeholder="Начните вводить имя/телефон/email клиента"
                  :multiple="false"
                  :trackBy="'id'"
                  :custom-label="userWithEmailAndPhone"
                  :selectLabel="'Выбрать'"
                  :deselectLabel="'Убрать'"
                  :selectedLabel="'Выбрано'"
                  @search-change="searchUser"
              >
                <span slot="noResult">
                  По вашему запросу нет результатов. Создать нового клиента:
                  <i
                      @click="showCreateClient"
                      class="fas fa-plus-square"
                  ></i>
                </span>
                <span slot="noOptions">
                  <i>[результаты поиска будут здесь]</i>
                </span>
              </multiselect>

                            <div v-if="!clientObj" @click="showCreateClient" class="client_btn-create">
                                <i class="fas fa-plus-square"></i>
                            </div>
                        </div>
                        <div v-if="!clientObj" class="booking__reserve booking__reserve2">
                            <div class="site-input site-input_w35">
                                <label for="VuePhoneNumberInput">Телефон</label>
                                <VuePhoneNumberInput
                                        :translations="phoneInputTrans"
                                        :show-code-on-list="phoneInputCodeList"
                                        default-country-code="UA"
                                        :no-example="phoneInputNoExample"
                                        v-model="newClient.phone"
                                        @update="onUpdateInputPhone"/>
                            </div>
                            <div class="site-input site-input_w35">
                                <label for="client-name">Имя</label>
                                <div class="input-item">
                                    <input id="client-name" type="text" v-model="newClient.name"/>
                                </div>
                            </div>
                            <div class="site-input site-input_w35">
                                <label for="client-lastname">Фамилия</label>
                                <div class="input-item">
                                    <input id="client-lastname" type="text" v-model="newClient.last_name"/>
                                </div>
                            </div>
                            <div class="site-input site-input_w35">
                                <label for="client-comment">Комментарий</label>
                                <div class="input-item">
                                    <input id="client-comment" type="text" v-model="newClient.comment"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="client_info" v-if="clientObj">
                        <p>
                            <span>{{ clientObj.name }} {{ clientObj.last_name }}</span>
                            ,
                            {{ clientObj.phone }},
                            {{ clientObj.email }}
                        </p>
                        <p>{{ clientObj.comment }}</p>
                        <p>
                            <span>Cредний чек:</span>
                            {{ clientObj.avg_check }} грн,
                            <span>День рождения:</span>
                            {{ new Date(clientObj.birthday).toLocaleDateString() }}
                        </p>
                        <p v-show="clientObj.bio">
                            <span>Гость сказал о себе:</span>
                            {{ clientObj.bio }}
                        </p>
                        <span
                                class="client_info-btn"
                                v-show="!showeditClient"
                                @click="showeditClient = !showeditClient"
                        >
              <i class="far fa-edit"></i>
            </span>
          </div>
        </div>
        <div class="form__row">
          <div class="booking__reserve">
            <div class="form__col">
              <div class="booking__reserve-tables">
                <div class="site-input site-input_mb" v-if="mode !== 'booking' || addingTables">
                  <label for="tables">Стол или группа столов</label>
                  <div class="input-item" id="tables">
                    <multiselect
                        v-model="reserveObj.tables"
                        :options="allTables"
                        :searchable="true"
                        :close-on-select="!addingTables"
                        :clear-on-select="true"
                        :placeholder="
                        addingTables ? 'Выберите столы' : 'Выберите стол'
                      "
                        :multiple="true"
                        :trackBy="'id'"
                        :label="'name'"
                        :selectLabel="'Выбрать'"
                        :deselectLabel="'Убрать'"
                        :selectedLabel="'Выбрано'"
                    >
                      <template slot="option" slot-scope="props">
                        <img
                            v-show="props.option.image_url"
                            class="option__image"
                            :src="props.option.image_url"
                            :alt="props.option.name"
                        />
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div class="site-input site-input_mb" v-else>
                  <label for="tables">Стол или группа столов</label>
                  <div class="input-item" id="tables">
                    <multiselect
                        v-model="reserveObj.tables"
                        :disabled="true"
                        :options="allTables"
                        :searchable="true"
                        :close-on-select="true"
                        placeholder="Выберите стол"
                        :multiple="true"
                        :trackBy="'id'"
                        :label="'name'"
                        :selectLabel="'Выбрать'"
                        :deselectLabel="'Убрать'"
                        :selectedLabel="'Выбрано'"
                    >
                      <template slot="option" slot-scope="props">
                        <img
                            v-show="props.option.image_url"
                            class="option__image"
                            :src="props.option.image_url"
                            :alt="props.option.name"
                        />
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>

                <div class="booking__reserve-tables-add" v-if="!addingTables">
                  <i @click="addTables" class="fas fa-plus-square"></i>
                </div>
                <div class="booking__reserve-tables-cancel" v-else>
                  <i @click="cancelAddTables" class="fas fa-times-circle"></i>
                </div>
              </div>
              <div class="site-input">
                <label for="restaurant_space_id">Зал Ресторана</label>
                <div class="input-item" id="restaurant_space_id">
                  <multiselect
                      v-model="restaurant_space"
                      :options="restaurantSpaces"
                      :searchable="true"
                      :close-on-select="true"
                      :clear-on-select="true"
                      placeholder="Выберите зал..."
                      :multiple="false"
                      :trackBy="'id'"
                      :label="'title'"
                      :selectLabel="'Выбрать'"
                      :deselectLabel="'Убрать'"
                      :selectedLabel="'Выбрано'"
                  >
                    <span slot="noResult">По вашему запросу нет результатов.</span>
                  </multiselect>
                </div>
              </div>
              <div class="site-input">
                <label for="event_id">Событие</label>
                <div class="input-item" id="event_id">
                  <div
                      v-if="!eventsTodayLoaded"
                  >[Чтобы увидеть событие в этот день, выберите дату начала]
                  </div>
                  <div v-else>
                    Событие в этот день ({{ this.day }}):
                    <div v-if="eventsToday.length === 0">[нет события]</div>
                    <div v-else>
                      <div class="checkbox-row">
                        <input
                            class="checkbox checkbox_normal"
                            type="checkbox"
                            id="eventToday"
                            v-model="eventChecked"
                        />
                        <label for="eventToday">
                          {{ eventsToday[0].title }} |
                          {{ eventsToday[0].description }} (Цена билета:
                          {{ eventsToday[0].ticket_price }}, Мин. депозит:
                          {{ eventsToday[0].min_deposit }})
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="site-input">
                                <label for="event_id">Статус Заказа</label>
                                <div class="input-item" id="status">
                                    <div>
                                         <span v-html="getStatus(reserveObj)"></span>
                                    </div>
                                </div>
              </div>-->
              <div class="site-input">
                <label for="order_status">Статус Заказа</label>
                <div class="input-item" id="order_status">
                  <multiselect
                      v-model="order_status"
                      :options="orderStatusOptions"
                      :searchable="true"
                      :close-on-select="true"
                      :clear-on-select="true"
                      placeholder="Выберите статус заказа..."
                      :multiple="false"
                      :trackBy="'id'"
                      :label="'title'"
                      :selectLabel="'Выбрать'"
                      :deselectLabel="'Убрать'"
                      :selectedLabel="'Выбрано'"
                  >
                    <span slot="noResult">По вашему запросу нет результатов.</span>
                  </multiselect>
                </div>
              </div>
              <div class="site-input">
                <label for="comment">Сумма Бронирования</label>
                <div class="input-item">{{ reserveObj.total_price }}</div>
              </div>
              <div class="site-input">
                <label for="comment">Продукты:</label>
                <ul>
                  <li v-for="(item, index) in reserveObj.products" :key="index">
                    {{ `${item.title} x${item.pivot.quantity}` }}
                    <span style="color: blue;">
                      {{
                        `(${getSubcategoryName(item.menu_subcategory_id)})`
                      }}
                    </span>
                                    </li>
                                </ul>
                            </div>
                            <div class="site-input">
                                <label for="comment">Предоплата (из приложения)</label>
                                <div class="input-item">
                                    {{
                                    reserveObj.status == 2 &&
                                    reserveObj.total_price > 0 &&
                                    reserveObj.prepayment == 0
                                    ? reserveObj.total_price
                                    : reserveObj.prepayment
                                    ? reserveObj.prepayment
                                    : "0.00"
                                    }}
                                </div>
                            </div>
                            <div class="site-input">
                                <label for="prepayment_hostes">Предоплата (хостес)</label>
                                <div class="input-item">
                                    <input
                                            id="prepayment_hostes"
                                            type="number"
                                            v-model="reserveObj.prepayment_hostes"
                                    />
                                </div>
                            </div>
                            <div class="site-input" v-for="(item, index) in reserveObj.qr_codes" :key="item.id" style="width: 50%; display: inline-block;">
                                <label for="qr_code">QR Код {{ index + 1 }}</label>
                                <div class="input-item">
                                    <input
                                            id="qr_code"
                                            type="text"
                                            :value="item.qr_code"
                                            readonly
                                            style="opacity: 0.5; font-size: 1.5rem;"
                                            v-if="item.used"
                                    />
                                    <input
                                            id="qr_code"
                                            type="text"
                                            :value="item.qr_code"
                                            readonly
                                            style="font-size: 1.5rem;"
                                            v-if="!item.used"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="form__col">
                            <div class="site-input site-input_multirow site-input_multirow-sb">
                                <div class="date_start">
                                    <label for="date_start">
                                        {{
                                        mode === "booking" ? "Время начала" : "Дата и время начала"
                                        }}
                                    </label>
                                    <div class="input-item">
                                        <datepicker
                                                id="date_start"
                                                v-model="reserveObj.date_start"
                                                lang="ru"
                                                :type="mode === 'booking' ? 'time' : 'datetime'"
                                                :appendToBody="false"
                                                :format="
                        mode === 'booking' ? 'HH:mm' : 'YYYY-MM-DD HH:mm'
                      "
                      :time-picker-options="{
                        start: '06:00',
                        step: '00:30',
                        end: '05:00',
                      }"
                        :time-select-options="{
                        hours: [
                          6,
                          7,
                          8,
                          9,
                          10,
                          11,
                          12,
                          13,
                          14,
                          15,
                          16,
                          17,
                          18,
                          19,
                          20,
                          21,
                          22,
                          23,
                          0,
                          1,
                          2,
                          3,
                          4,
                          5
                        ],
                        minutes: [
                          0,
                          5,
                          10,
                          15,
                          20,
                          25,
                          30,
                          35,
                          40,
                          45,
                          50,
                          55
                        ],
                        seconds: [],
                      }"
                        :first-day-of-week="1"
                        :width="'auto'"
                        :valueType="'format'"
                        :editable="false"
                        :clearable="false"
                        :placeholder="
                        mode === 'booking'
                          ? 'Выберите время'
                          : 'Выберите дату и время'
                      "
                    ></datepicker>
                  </div>
                </div>
                <div class="date_finish">
                  <label for="date_finish">
                    {{
                      mode === "booking"
                          ? "Время окончания"
                          : "Дата и время окончания"
                    }}
                  </label>
                  <div class="input-item">
                    <datepicker
                        id="date_finish"
                        v-model="reserveObj.date_finish"
                        lang="ru"
                        :type="mode === 'booking' ? 'time' : 'datetime'"
                        :appendToBody="false"
                        :format="
                        mode === 'booking' ? 'HH:mm' : 'YYYY-MM-DD HH:mm'
                      "
                        :time-picker-options="{
                        start: '06:00',
                        step: '00:30',
                        end: '05:00',
                      }"
                        :time-select-options="{
                        hours: [
                          6,
                          7,
                          8,
                          9,
                          10,
                          11,
                          12,
                          13,
                          14,
                          15,
                          16,
                          17,
                          18,
                          19,
                          20,
                          21,
                          22,
                          23,
                          0,
                          1,
                          2,
                          3,
                          4,
                          5
                        ],
                        minutes: [
                          0,
                          5,
                          10,
                          15,
                          20,
                          25,
                          30,
                          35,
                          40,
                          45,
                          50,
                          55
                        ],
                        seconds: [],
                      }"
                        :first-day-of-week="1"
                        :width="'auto'"
                        :valueType="'format'"
                        :editable="false"
                        :clearable="false"
                        :placeholder="
                        mode === 'booking'
                          ? 'Выберите время'
                          : 'Выберите дату и время'
                      "
                                        ></datepicker>
                                    </div>
                                </div>
                            </div>
                            <div class="site-input">
                                <label for="persons_count">Количество человек</label>
                                <div class="input-item">
                                    <input id="persons_count" type="number" v-model="reserveObj.persons_count"/>
                                </div>
                            </div>
                            <div class="site-input" v-if="!ticketOnly">
                                <label for="sms_send_time">SMS-напоминание</label>
                                <div class="input-item" id="sms_send_time">
                                    <div class="checkbox-row">
                                        <span class="checkbox-space" v-if="mode === 'booking'">
                                          <input
                                                  class="checkbox checkbox_normal"
                                                  type="checkbox"
                                                  id="immediately_sms"
                                                  v-model="reserveObj.immediately_sms"
                                          />
                                          <label for="immediately_sms">При бронировании</label>
                                        </span>
                                        <span class="checkbox-space">
                                          <input
                                                  class="checkbox checkbox_normal"
                                                  type="checkbox"
                                                  id="1hour"
                                                  v-model="smsObj['1hour']"
                                          />
                                          <label for="1hour">За 1 час</label>
                                        </span>
                                        <span class="checkbox-space">
                                          <input
                                                  class="checkbox checkbox_normal"
                                                  type="checkbox"
                                                  id="3hours"
                                                  v-model="smsObj['3hours']"
                                          />
                                          <label for="3hours">За 3 часа</label>
                                        </span>
                                        <span class="checkbox-space">
                                          <input
                                                  class="checkbox checkbox_normal"
                                                  type="checkbox"
                                                  id="thank_sms"
                                                  v-model="reserveObj.thank_sms"
                                          />
                                          <label for="thank_sms">Сообщение спасибо</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="site-input site-input_multirow">
                        <div class="status">
                            <label for="options">Статус</label>
                            <div class="input-item" id="options">
                                <div class="checkbox-row">
                  <span class="checkbox-space">
                    <input
                            class="checkbox checkbox_normal"
                            type="checkbox"
                            id="ps"
                            v-model="reserveObj.options.ps"
                    />
                    <label for="ps">ПС</label>
                  </span>
                                    <span class="checkbox-space">
                    <input
                            class="checkbox checkbox_normal"
                            type="checkbox"
                            id="pz"
                            v-model="reserveObj.options.pz"
                    />
                    <label for="pz">ПЗ</label>
                  </span>
                                    <span class="checkbox-space">
                    <input
                            class="checkbox checkbox_normal"
                            type="checkbox"
                            id="po"
                            v-model="reserveObj.options.po"
                    />
                    <label for="po">ПО</label>
                  </span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="prepayment">
                                        <label for="prepayment">Предоплата</label>
                        <div class="input-item">-->
                        <!-- <input id="prepayment" type="number" v-model="reserveObj.prepayment"> -->
                        <!-- {{reserveObj.status == 2 && reserveObj.total_price > 0 && reserveObj.prepayment == 0 ? reserveObj.total_price : reserveObj.prepayment   }}
                                        </div>
                        </div>-->
                        <div class="currency">
                            <label for="currency">Валюта</label>
                            <div class="input-item" id="currency">
                                <div class="radio-row">
                                    <div v-for="(currency, index) in currencies" :key="index" class="radio-row__item">
                                        <input
                                                class="radio"
                                                type="radio"
                                                v-model="selectedCurrency"
                                                :id="'currency' + index"
                                                :value="currency"
                                        />
                                        <label :for="'currency' + index">{{ currency }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="site-input">
                        <label for="comment">Комментарий</label>
                        <div class="input-item">
                            <textarea id="comment" type="text" v-model="reserveObj.comment"></textarea>
                        </div>
                    </div>
                    <div class="dropzone">
                        <div class="site-input">
                            <label for="image">Изображение</label>
                            <p v-if="!show">
                                Перетащите сюда или выберите изображение
                                <i class="fa fa-cloud-upload"></i>
                            </p>
                            <input
                                    class="file-input"
                                    type="file"
                                    id="image"
                                    ref="file"
                                    @change="previewImage"
                                    accept="image/*"
                            />
                        </div>
                        <div class="image" v-if="show">
                            <img :src="imageData" alt/>
                            <span class="close" @click="deleteImage">&times;</span>
                        </div>
                    </div>
                    <div class="form__row form__row_space-between form__row_submit">
                        <button class="btn btn_primary" type="submit" v-show="!submitting">Сохранить</button>
                        <div class="loader" v-show="submitting">Сохранение...</div>
                        <button
                                class="btn btn_danger"
                                @click.stop.prevent="deleteReserve"
                                v-if="reserveObj.id && !submitting"
                        >Удалить резерв
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div
                class="modal"
                :class="{ fade: !showItemDeletePopup, open: showItemDeletePopup }"
                @click.self.stop="showItemDeletePopup = false"
        >
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="popup__close" @click="showItemDeletePopup = false">&times;</div>
                        <h4>Удалить резерв {{ reserveObj.id }}?</h4>
                        <div class="site-input popup__reason">
                            <label for="reason_remove">Укажите причину удаления:</label>
                            <div class="input-item">
                                <input id="reason_remove" type="text" v-model="reasonForRemoval"/>
                            </div>
                        </div>
                        <div class="popup__buttons popup__buttons_spread popup__buttons_mt">
                            <button class="btn btn_primary" @click.prevent="showItemDeletePopup = false">Отмена</button>
                            <button
                                    class="btn btn_danger"
                                    :disabled="!reasonForRemoval"
                                    @click.prevent="deleteConfirmed"
                            >Удалить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="moving-message" v-show="showError">
            <div>
                <p v-for="(error,index) in errors" :key="index">
                    <b>{{error}}</b>
                </p>
            </div>
            <button class="btn btn_danger" @click.stop.prevent="showError = false">Закрыть</button>
        </div>
    </div>
</template>

<style>
    .vue-tel-input {
        padding: 0.4rem 0.8rem;
        margin: 1rem 0;
        font-size: 1.7rem;
        height: 3.6rem;
        line-height: 2.2rem;
        border-radius: 0.4rem;
        border: 1px solid #ccc;
    }

    .vti__dropdown {
        padding: 0px !important;
    }

    .vue-tel-input:focus-within {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        border: 1px solid #ccc !important;
    }

    .booking__reserve2 .site-input.site-input_w35 {
        padding-left: 15px;
    }

    .booking__reserve2 .site-input.site-input_w35:first-child {
        padding-left: 0px;
    }
</style>

<script>
    import Multiselect from "vue-multiselect";
    import Datepicker from "vue2-datepicker";
    import {apiUrls, misc} from "@/config/constants";
    import handleFormValidationErrors from "@/assets/js/mixins/handleFormValidationErrors";
    import moment from "moment";
    import {mapGetters} from 'vuex';
    import VuePhoneNumberInput from 'vue-phone-number-input';

    export default {
        name: "ReserveForm",
        components: {
            Multiselect,
            Datepicker,
            VuePhoneNumberInput
        },
        mixins: [handleFormValidationErrors],
        props: {
            type: {
                type: String,
                default: "create"
            },
            mode: {
                type: String,
                default: ""
            },
            reserve: {
                type: Object,
                default: () => ({
                    id: "",
                    client: {},
                    client_id: "",
                    table: {},
                    tables: [],
                    persons_count: "",
                    date_start: "",
                    date_finish: "",
                    options: "",
                    prepayment: "",
                    prepayment_hostes: "",
                    comment: "",
                    currency: "",
                    qr_codes: []
                })
            }
        },
        data() {
            return {
                showError: false,
                reserveObj: {
                    tables: [],
                    options: {ps: false, po: false, pz: false},
                    sms_send_time: [],
                    immediately_sms: this.mode === "booking",
                    thank_sms: false,
                    ticket: false,
                },
                itemToSubmit: {},
                currencies: ["UAH", "USD", "EUR", "RUR"],
                selectedCurrency: "UAH",
                clients: [{name: "", phone: ""}],
                loadingUsers: false,
                eventsToday: [],
                eventsTodayLoaded: false,
                eventChecked: true,
                availableOptions: {po: "ПО", ps: "ПС", pz: "ПЗ"},
                smsObj: {"1hour": false, "3hours": false},
                allTables: [{label: "", value: ""}],
                errors: [],
                searchQuery: "",
                selectedEvent: {},
                day: "",
                imageData: "",
                show: false,
                showeditClient: false,
                clientObj: null,
                typeClient: "edit",
                addingTables: false,
                tableBackup: {},
                newClient: {
                    name: "",
                    phone: "",
                    last_name: ""
                },
                userSearchTimeout: null,
                showItemDeletePopup: false,
                reasonForRemoval: "",
                restaurant_space: null,
                submitting: false,
                booking_mode_wasNight: false,
                restaurantSpaces: [],
                initialDay: "",
                orderStatusOptions: [
                    {id: 1, title: "Ожидание"},
                    {id: 2, title: "Принят"},
                    {id: 3, title: "Отклонен"},
                    {id: 4, title: "Ожидает оплаты"},
                    {id: 5, title: "Отменен клиентом"},
                    {id: 6, title: "Пришел"}
                ],

                order_status: null,
                subCategories: [],
                ticketOnly: false,
                phoneInputTrans: {
                    countrySelectorLabel: 'Код страны',
                    countrySelectorError: 'Выберите страну',
                    phoneNumberLabel: 'Номер телефона',
                    example: 'Пример:'
                },
                phoneInputCodeList: true,
                phoneInputNoExample: true,
                realPhoneNumber: null
            };
        },
        computed: {
            ...mapGetters(['userRestaurant']),
            computedOptions() {
                let options = "";
                for (let prop in this.reserveObj.options) {
                    if (
                        this.reserveObj.options.hasOwnProperty(prop) &&
                        this.reserveObj.options[prop]
                    ) {
                        options.length
                            ? (options += `, ${this.availableOptions[prop]}`)
                            : (options = this.availableOptions[prop] || "");
                    }
                }
                return options;
            }
        },
        watch: {
            "reserveObj.prepayment": {
                handler(val) {
                    this.reserveObj.options.po = !!val;
                }
            },
            "reserveObj.date_start": {
                handler(val, oldVal) {
                    //day doesn't change in booking mode
                    if (this.mode === "booking") return;

                    //if day changed - get events
                    if (val && val.slice(0, 10) !== (oldVal ? oldVal.slice(0, 10) : "")) {
                        this.getEventsOnDay(val.slice(0, 10));
                    }
                }
            }
        },
        methods: {
            onUpdateInputPhone(res) {
                console.log(res);
                if (res.isValid) {
                    this.realPhoneNumber = res.formattedNumber;
                } else {
                    this.realPhoneNumber = null;
                }
            },
            getSubcategoryName(id) {
                let subCategory = this.subCategories.find(item => item.id == id);
                return subCategory ? subCategory.name : "";
            },
            getStatus(data) {
                if (
                    !data ||
                    !data.status ||
                    data.status < 1 ||
                    data.status > this.orderStatusOptions.length
                ) {
                    return "";
                }
                var color = "black";
                if (data.status == 4 || data.status == 5) {
                    color = "red";
                }
                return `<span style="color: ${color}">${
                    this.orderStatusOptions[data.status - 1].title
                    }</span>`;
            },
            getRestaurantSpaces() {
                return this.$store
                    .dispatch("getRestaurantSpaces")
                    .then(response => {
                        this.restaurantSpaces = response.map(item => {
                            item.title = item.name;
                            return item;
                        });
                        var selectedSpace = this.restaurantSpaces.find(
                            item => item.id == this.reserveObj.restaurant_space_id
                        );

                        this.restaurant_space = selectedSpace;
                    })
                    .catch(error => console.log(error));
            },
            getClients() {
                if (!this.searchQuery) {
                    this.clients = [];
                    return;
                }
                this.loadingUsers = true;

                this.$http
                    .get("/clients", {params: {search: this.searchQuery}})
                    .then(resp => {
                        if (resp && resp.data && resp.data.data && resp.data.data.items) {
                            this.clients = resp.data.data.items;
                            this.loadingUsers = false;
                        }
                    });
            },
            getTables() {
                this.$http.get("/tables", {params: {restaurant_id: this.userRestaurant}}).then(resp => {
                    if (resp && resp.data && resp.data.data && resp.data.data.items) {
                        this.allTables = resp.data.data.items;
                    }
                });
            },
            createReserve(formData) {
                return this.$http
                    .post(apiUrls.reserve, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(data => data)
                    .catch(error => {
                        this.handleFormValidationErrors(error, this.$refs.form);
                    });
            },
            editReserve(formData) {
                return this.$http
                    .post(apiUrls.reserve + "/" + this.reserveObj.id, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(data => data)
                    .catch(error => {
                        this.handleFormValidationErrors(error, this.$refs.form);
                    });
            },
            async submitItem(params = {delete: false}) {
                this.errors = [];
                this.showError = false;
                if (!this.restaurant_space || !this.restaurant_space.id) {
                    this.errors.push("Укажите Зал Ресторана");
                }
                if (!this.reserveObj.date_start || this.reserveObj.date_start == "") {
                    this.errors.push("Укажите Время начала");
                }
                if (
                    !this.reserveObj.persons_count ||
                    this.reserveObj.persons_count == ""
                ) {
                    this.errors.push("Укажите Количество человек");
                }

                if (
                    !this.clientObj &&
                    (!this.newClient.name || this.newClient.name == "")
                ) {
                    this.errors.push("Укажите Имя");
                }
                if (
                    !this.clientObj &&
                    (!this.newClient.phone || this.newClient.phone == "")
                ) {
                    this.errors.push("Укажите Номер Телефона");
                }

                if (!this.clientObj && this.mode === "booking" && this.realPhoneNumber === null) {
                    this.errors.push("Неверный формат номера телефона");
                }

                if (this.errors.length > 0) {
                    this.showError = true;
                    return;
                }

                if (!this.clientObj) {
                    try {
                        var clientResp = await this.$http.post(apiUrls.client, {
                            name: this.newClient.name,
                            phone: this.realPhoneNumber,
                            last_name: this.newClient.last_name
                        });
                        if (clientResp && clientResp.data && clientResp.data.data) {
                            this.clientObj = Array.isArray(clientResp.data.data)
                                ? clientResp.data.data[0]
                                : clientResp.data.data;
                        } else {
                            if (clientResp == `Error: Request failed with status code 422`) {
                                this.errors.push(
                                    "Пользователь с указанным номером телефона уже существует"
                                );
                                this.showError = true;
                                return;
                            }
                        }
                    } catch (err) {
                        this.errors.push("Произошла ошибка при добавлении пользователя");
                        this.showError = true;
                        return;
                    }
                }

                this.submitting = true;

                if (
                    this.reserveObj.prepayment &&
                    "string" === typeof this.reserveObj.options &&
                    this.reserveObj.options.indexOf("ПО") === -1
                ) {
                    this.reserveObj.options.po = true;
                }

                // console.log(this.reserveObj.options);
                if (!this.reserveObj.options) {
                    this.reserveObj.options = {
                        ps: false,
                        po: false,
                        pz: false
                    };
                }
                if (this.order_status && this.order_status.id == 4) {
                    this.reserveObj.options.po = true;
                    this.reserveObj.options.pz = true;
                }

                this.itemToSubmit = {
                    id: this.reserveObj.id,
                    client_id: this.clientObj ? this.clientObj.id : "",
                    tables: this.reserveObj.tables,
                    persons_count: this.reserveObj.persons_count,
                    date_start: this.reserveObj.date_start,
                    date_finish: this.reserveObj.date_finish,
                    options: this.computedOptions,
                    currency: this.selectedCurrency,
                    prepayment: this.reserveObj.prepayment,
                    prepayment_hostes: this.reserveObj.prepayment_hostes,
                    status: this.order_status ? this.order_status.id : undefined,
                    sms_send_time: [],
                    event_id:
                        this.eventsToday.length && this.eventChecked
                            ? this.eventsToday[0].id
                            : undefined,
                    restaurant_space_id: this.restaurant_space
                        ? this.restaurant_space.id
                        : undefined,
                    comment: this.reserveObj.comment,
                    image: this.reserveObj.image,
                    immediately_sms: this.reserveObj.immediately_sms,
                    thank_sms: this.reserveObj.thank_sms
                };

                if (this.mode !== "booking") {
                    const normalDateLength = "YYYY-MM-DD HH:mm:ss".length;
                    if (normalDateLength - this.itemToSubmit.date_start.length === 3) {
                        this.itemToSubmit.date_start = this.itemToSubmit.date_start + ":00";
                    }
                    if (normalDateLength - this.itemToSubmit.date_finish.length === 3) {
                        this.itemToSubmit.date_finish = this.itemToSubmit.date_finish + ":00";
                    }
                } else {
                    let isNight = false;
                    this.day = this.initialDay;

                    if (this.itemToSubmit.date_start) {
                        isNight =
                            parseFloat(this.itemToSubmit.date_start.slice(0, 2)) <
                            misc.WORKING_HOURS_OFFSET;
                    }
                    if (!this.booking_mode_wasNight && isNight) {
                        this.day = moment(
                            new Date(moment(this.day).toDate()).setDate(
                                new Date(moment(this.day).toDate()).getDate() + 1
                            )
                        ).format("YYYY-MM-DD");
                    } else if (this.booking_mode_wasNight && !isNight) {
                        this.day = moment(
                            new Date(moment(this.day).toDate()).setDate(
                                new Date(moment(this.day).toDate()).getDate() - 1
                            )
                        ).format("YYYY-MM-DD");
                    }
                    // console.log(this.day);

                    this.itemToSubmit.date_start =
                        this.day + " " + this.itemToSubmit.date_start + ":00";
                    this.itemToSubmit.date_finish = this.itemToSubmit.date_finish
                        ? this.day + " " + this.itemToSubmit.date_finish + ":00"
                        : "";
                }

                if (
                    !this.itemToSubmit.date_finish ||
                    this.itemToSubmit.date_finish === ":00"
                ) {
                    let dateStartSafariFixed = new Date(
                        this.itemToSubmit.date_start.toString().replace(/-/g, "/")
                    );
                    this.itemToSubmit.date_finish = moment(
                        new Date().setTime(
                            dateStartSafariFixed.getTime() +
                            misc.RESERVE_DURATION_HOURS_DEFAULT * 60 * 60 * 1000
                        )
                    ).format("YYYY-MM-DD HH:mm:ss");
                }

                let smses = [];

                if (this.smsObj["1hour"]) {
                    smses.push(this.dateStartSubtractHours(1));
                }

                if (this.smsObj["3hours"]) {
                    smses.push(this.dateStartSubtractHours(3));
                }

                this.itemToSubmit.sms_send_time = smses;

                // console.log(this.itemToSubmit);

                // console.log("this.itemToSubmit ReserveForm");

                let formData = new FormData();
                formData.append("restaurant_id", this.userRestaurant);
                formData.append("client_id", this.itemToSubmit.client_id);
                formData.append("persons_count", this.itemToSubmit.persons_count || "");
                if (this.itemToSubmit.prepayment) {
                    formData.append(
                        "prepayment",
                        this.itemToSubmit.prepayment
                            ? this.itemToSubmit.prepayment + " " + this.itemToSubmit.currency
                            : ""
                    );
                }
                if (this.itemToSubmit.prepayment_hostes) {
                    formData.append(
                        "prepayment_hostes",
                        this.itemToSubmit.prepayment_hostes
                            ? this.itemToSubmit.prepayment_hostes
                            : 0
                    );
                }

                formData.append("ticket", (this.ticketOnly ? 1 : 0));
                formData.append("immediately_sms", (this.itemToSubmit.immediately_sms ? 1 : 0));
                formData.append("thank_sms", (this.itemToSubmit.thank_sms ? 1 : 0));
                formData.append("date_start", this.itemToSubmit.date_start);
                formData.append("date_finish", this.itemToSubmit.date_finish);
                formData.append("options", this.itemToSubmit.options);
                formData.append(
                    "comment",
                    params.delete
                        ? this.itemToSubmit.comment ||
                        "" + " ПРИЧИНА УДАЛЕНИЯ: " + this.reasonForRemoval
                        : this.itemToSubmit.comment || ""
                );

                if (this.itemToSubmit.restaurant_space_id) {
                    formData.append(
                        "restaurant_space_id",
                        this.itemToSubmit.restaurant_space_id || ""
                    );
                }
                if (this.itemToSubmit.event_id) {
                    formData.append("event_id", this.itemToSubmit.event_id);
                }
                // formData.append('event_id', this.itemToSubmit.event_id);
                formData.append("image", this.itemToSubmit.image || "");
                // formData.append('status', '2');
                // console.log(this.itemToSubmit.status);
                if (this.itemToSubmit.status)
                    formData.append("status", this.itemToSubmit.status);

                if (this.itemToSubmit.tables.length) {
                    for (let i = 0; i < this.itemToSubmit.tables.length; i++) {
                        formData.append("tables[" + i + "]", this.itemToSubmit.tables[i].id);
                    }
                } else if (this.itemToSubmit.tables.id) {
                    formData.append("tables[0]", this.itemToSubmit.tables.id);
                }

                if (this.itemToSubmit.sms_send_time.length) {
                    for (let i = 0; i < this.itemToSubmit.sms_send_time.length; i++) {
                        formData.append(
                            "sms_send_time[" + i + "]",
                            this.itemToSubmit.sms_send_time[i]
                        );
                    }
                }
                for (var pair of formData.entries()) {
                    console.log(pair[0] + ' ' + pair[1]);
                }

                let resp;

                if (params.delete) {
                    return this.editReserve(formData);
                }

                if (this.type === "edit") {
                    resp = await this.editReserve(formData);
                } else {
                    resp = await this.createReserve(formData);
                }

                if (resp && resp.data && resp.data.success === true) {
                    this.$emit("itemSubmitSuccess", this.itemToSubmit);
                }

                this.submitting = false;
            },
            userWithEmailAndPhone(user) {
                if (user.last_name) {
                    return user.email
                        ? user.name +
                        " " +
                        user.last_name +
                        " — " +
                        user.email +
                        " — " +
                        user.phone
                        : user.name + " " + user.last_name + " — " + user.phone;
                } else {
                    return user.email
                        ? user.name + " — " + user.email + " — " + user.phone
                        : user.name + " — " + user.phone;
                }
            },
            searchUser(searchQuery) {
                if (this.userSearchTimeout) {
                    clearTimeout(this.userSearchTimeout);
                }
                this.userSearchTimeout = setTimeout(() => {
                    this.searchQuery = searchQuery;
                    this.getClients();
                }, 600);
            },
            previewImage(ev) {
                let input = ev.target;
                if (input.files && input.files[0]) {
                    let reader = new FileReader();
                    reader.onload = e => {
                        this.imageData = e.target.result;
                        this.show = !!this.imageData;
                    };
                    reader.readAsDataURL(input.files[0]);
                }
                this.reserveObj.image = this.$refs.file.files[0];
            },
            deleteImage() {
                this.reserveObj.image = null;
                this.imageData = "";
                this.show = false;
                this.$refs.file.value = "";
            },
            editClient() {
                this.$http
                    .put(apiUrls.client + "/" + this.clientObj.id, {
                        name: this.clientObj.name,
                        last_name: this.clientObj.last_name,
                        email: this.clientObj.email,
                        birthday: this.clientObj.birthday,
                        phone: this.clientObj.phone,
                        comment: this.clientObj.comment
                        // image: this.clientObj.image,
                    })
                    .then(resp => {
                        if (resp && resp.data.data) {
                            this.showeditClient = !this.showeditClient;
                            this.typeClient = "edit";
                            this.getClients();
                        }
                    })
                    .catch(error => {
                        this.handleFormValidationErrors(error, this.$refs.form);
                    });
            },
            creatNewClient() {
                this.$http
                    .post(apiUrls.client, {
                        name: this.clientObj.name,
                        last_name: this.clientObj.last_name,
                        email: this.clientObj.email,
                        birthday: this.clientObj.birthday,
                        phone: this.clientObj.phone,
                        comment: this.clientObj.comment,
                        image: this.clientObj.image
                    })
                    .then(resp => {
                        if (resp && resp.data.data) {
                            this.clientObj.id = resp.data.data.id;
                            this.showeditClient = !this.showeditClient;
                            this.typeClient = "edit";
                            this.getClients();
                        }
                    })
                    .catch(error => {
                        this.handleFormValidationErrors(error, this.$refs.form);
                    });
            },
            showCreateClient() {
                this.typeClient = "create";
                this.clientObj = {
                    name: "",
                    last_name: "",
                    email: "",
                    birthday: "",
                    phone: "",
                    comment: "",
                    image: "",
                    avg_check: ""
                };
                this.showeditClient = !this.showeditClient;
            },
            closeEditClient() {
                if (!this.clientObj.id) {
                    this.clientObj = null;
                }
                this.showeditClient = !this.showeditClient;
            },
            findClientByPhone() {
                this.$http
                    .get("/clients", {
                        params: {search: "phone:" + this.newClient.phone}
                    })
                    .then(resp => {
                        if (
                            resp.data.data &&
                            resp.data.data.items &&
                            resp.data.data.items.length === 1
                        ) {
                            this.clientObj = resp.data.data.items[0];
                        }
                    });
            },
            addTables() {
                this.addingTables = true;
                this.tableBackup = [].concat(this.reserveObj.tables);
            },
            cancelAddTables() {
                this.reserveObj.tables = [].concat(this.tableBackup);
                this.addingTables = false;
            },
            dateStartSubtractHours(hours) {
                if (!hours) {
                    return 'now';
                }

                let dateStartSafariFixed = new Date(
                    this.itemToSubmit.date_start.toString().replace(/-/g, "/")
                );

                return moment(
                    new Date().setTime(
                        dateStartSafariFixed.getTime() - hours * 60 * 60 * 1000
                    )
                ).format("YYYY-MM-DD HH:mm:ss");
            },
            getEventsOnDay(day) {
                this.$http
                    .get(apiUrls.event, {
                        params: {search: "date_time:" + day || this.day, restaurant_id: this.userRestaurant}
                    })
                    .then(resp => {
                        this.eventsTodayLoaded = true;
                        if (resp.data.data && resp.data.data.items) {
                            if (resp.data.data.items.length) {
                                this.eventsToday = resp.data.data.items;
                                this.eventChecked =
                                    !!this.reserveObj.event_id || this.type === "create";
                                if (this.mode === "booking") {
                                    /*this.reserveObj.date_start = this.eventsToday[0].date_time.slice(
                                        11,
                                        this.eventsToday[0].date_time.length - 3
                                    );*/
                                }
                            } else {
                                this.$http
                                    .get(
                                        apiUrls.EVENTS_GET_DEFAULT +
                                        (this.$store.state.bookingState
                                            ? this.$store.state.bookingState.selectedSpace.id
                                            : 2)
                                    )
                                    .then(_resp => {
                                        if (_resp && _resp.data && _resp.data.success) {
                                            this.eventsToday = [_resp.data.data];
                                            this.eventChecked = false;
                                        }
                                    });
                            }
                        }
                    });
            },
            deleteReserve() {
                this.showItemDeletePopup = true;
            },
            deleteConfirmed() {
                this.submitItem({delete: true})
                    .then(resp => {
                        if (resp.data && resp.data.success === true) {
                            this.$http
                                .delete(apiUrls.reserve + "/" + this.reserveObj.id)
                                .then(resp => {
                                    this.showItemDeletePopup = false;

                                    if (resp.data && resp.data.success === true) {
                                        this.$emit("itemDeleted");
                                    }
                                })
                                .catch(err => console.log(err));
                        }
                    })
                    .catch(err => console.log(err));
            }
        },
        async mounted() {
            this.reserveObj = Object.assign({}, this.reserve || this.reserveObj);
            console.log("mounted", this.reserveObj);

            let subcategoriesResponse = await this.$http.get(apiUrls.menuSubcategories);
            this.subCategories =
                subcategoriesResponse && subcategoriesResponse.data
                    ? subcategoriesResponse.data.data
                    : [];

            // this.$http.get(apiUrls.menuCategories).then((response) => {
            //   console.log(response);
            // });

            this.order_status = this.reserveObj.status
                ? this.orderStatusOptions[this.reserveObj.status - 1]
                : null;

            this.itemToSubmit.date_start = this.reserveObj.date_start;

            if (this.itemToSubmit.date_start) {
                this.booking_mode_wasNight =
                    new Date(moment(this.itemToSubmit.date_start).toDate()).getHours() <
                    misc.WORKING_HOURS_OFFSET;
            }

            // console.log("this.reserveObj.smses", this.reserveObj.smses);
            if (this.reserveObj.smses && this.reserveObj.smses.length) {
                this.reserveObj.smses.forEach(sms => {
                    // console.log("!!sms");
                    // console.log(this.dateStartSubtractHours(1));
                    // console.log(moment(sms.send_time).format("YYYY-MM-DD HH:mm:ss"));
                    // console.log(moment(sms.send_time).format("YYYY-MM-DD HH:mm:ss"));
                    // console.log(this.dateStartSubtractHours(3));
                    // console.log("sms!!");
                    if (
                        this.dateStartSubtractHours(1) ===
                        moment(sms.send_time).format("YYYY-MM-DD HH:mm:ss")
                    ) {
                        this.smsObj["1hour"] = true;
                    }
                    if (
                        this.dateStartSubtractHours(3) ===
                        moment(sms.send_time).format("YYYY-MM-DD HH:mm:ss")
                    ) {
                        this.smsObj["3hours"] = true;
                    }
                    if (
                        this.dateStartSubtractHours(1) <
                        moment(sms.send_time).format("YYYY-MM-DD HH:mm:ss")
                    ) {
                        this.reserveObj.thank_sms = true;
                    }
                });
            }


            if (this.mode === "booking") {
                if (this.type === "create") {
                    this.smsObj["3hours"] = true;
                    this.day = this.$store.state.bookingState
                        ? this.$store.state.bookingState.date
                        : "";
                    this.reserveObj.tables = this.$store.state.bookingState
                        ? Array.isArray(this.$store.state.bookingState.table)
                            ? this.$store.state.bookingState.table
                            : [this.$store.state.bookingState.table]
                        : [];

                    if (this.$store.state.bookingState && !Array.isArray(this.$store.state.bookingState.table)
                        && this.$store.state.bookingState.table.id === null) {
                        this.ticketOnly = true;
                        this.reserveObj.persons_count = 1;
                    }
                } else {
                    this.day = this.reserveObj.date_start.slice(0, 10);
                    this.reserveObj.date_start = this.reserveObj.date_start.slice(
                        11,
                        this.reserveObj.date_start.length - 3
                    );
                    this.reserveObj.date_finish = this.reserveObj.date_finish.slice(
                        11,
                        this.reserveObj.date_finish.length - 3
                    );
                    this.reserveObj.qr_codes = this.$store.state.bookingState
                        ? Array.isArray(this.$store.state.bookingState.qr_codes)
                            ? this.$store.state.bookingState.qr_codes
                            : []
                        : [];
                    if (this.reserveObj.ticket) {
                        this.ticketOnly = true;
                    }
                }

                this.getEventsOnDay(this.day);
            } else {
                this.day = this.reserveObj.date_start.slice(0, 10);

                if (this.reserveObj.ticket) {
                    this.ticketOnly = true;
                }
            }

            this.initialDay = this.day;

            if (typeof this.reserveObj.options === "string") {
                let options = this.reserveObj.options.split(", ");
                this.reserveObj.options = {
                    ps: options.indexOf("ПС") !== -1,
                    po: options.indexOf("ПО") !== -1,
                    pz: options.indexOf("ПЗ") !== -1
                };
            } else {
                this.reserveObj.options = {ps: false, po: false, pz: false};
            }

            if (this.reserveObj.image_url) {
                this.imageData = this.reserveObj.image_url;
                this.show = true;
            }

            if (this.reserveObj.prepayment) {
                let array = this.reserveObj.prepayment.split(" ");
                this.reserveObj.prepayment = array[0];
                this.reserveObj.currency = array[1];
            }

            if (this.reserveObj.client) {
                this.clientObj = this.reserveObj.client;
            }

            if (this.ticketOnly) {
                this.smsObj["1hour"] = false;
                this.smsObj["3hours"] = false;
                this.reserveObj.immediately_sms = false;
                this.reserveObj.thank_sms = false;
            }

            Promise.all([
                this.getClients(),
                this.getTables(),
                this.getRestaurantSpaces()
            ]).then(() => {
                if (typeof this.$store.state.bookingState !== 'undefined'
                    && typeof this.$store.state.bookingState.selectedSpace !== 'undefined') {
                    this.restaurant_space = this.$store.state.bookingState.selectedSpace;
                }

                this.addWatcher("reserveObj")
            });

        }
    };
</script>
