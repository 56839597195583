<template>
  <div
    class="modal"
    :class="{
      fade: !showItemPopup,
      open: showItemPopup,
      fullscreen: viewType === 'tablePreset' || viewType === 'tablesForDate',
    }"
    @click.self.stop="close"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="popup__close" @click="close">&times;</div>
          <h2 class="popup__title">
            <span v-if="viewType === 'tablePreset'">{{ itemToShow.name }}</span
            ><span v-else>{{ itemToShow.id }}</span>
          </h2>
          <div class="popup__buttons">
            <button
              class="btn btn_primary btn_mr"
              @click.prevent="editItem(itemToShow)"
            >
              Обновить
            </button>
            <button
              class="btn btn_danger"
              @click.prevent="deleteItem(itemToShow)"
            >
              Удалить
            </button>
          </div>
          <table class="table table-bordered">
            <thead></thead>
            <tbody>
              <tr v-for="(row, index) in rows" :key="index">
                <th v-if="headings[row]">{{ headings[row] }}</th>
                <td v-if="headings[row]">
                  <tr v-if="row === 'restaurant_space'">
                    <th>Зал:&nbsp;</th>
                    <td>{{ itemToShow[row].name }}</td>
                  </tr>
                  <tr v-if="row === 'restaurant_space'">
                    <th>Описание зала:&nbsp;</th>
                    <td>{{ itemToShow[row].description || "" }}</td>
                  </tr>
                  <tr v-if="row === 'table_preset'">
                    <th>Название:&nbsp;</th>
                    <td>{{ itemToShow.table_preset.name }}</td>
                  </tr>
                  <tr v-if="row === 'table_preset'">
                    <th>Комментарий:&nbsp;</th>
                    <td>{{ itemToShow.table_preset.comment }}</td>
                  </tr>
                  <tr v-if="row === 'table_preset'">
                    <th>Зал:&nbsp;</th>
                    <td>
                      {{ itemToShow.table_preset["restaurant_space"].name }}
                    </td>
                  </tr>
                  <tr v-if="row === 'table_preset'">
                    <th>Описание зала:&nbsp;</th>
                    <td>
                      {{
                        itemToShow.table_preset["restaurant_space"].description
                      }}
                    </td>
                  </tr>
                  <tr v-else-if="row === 'image_url'">
                    <td>
                      <img class="item-image" :src="itemToShow[row]" alt="" />
                    </td>
                  </tr>
                  <tr v-else-if="row === 'options'">
                    <td>
                      {{ getRestaurantStatus(itemToShow[row]) }}
                    </td>
                  </tr>
                  <tr v-else-if="row === 'status'">
                    <td>
                      {{ getOrderStatus(itemToShow[row]) }}
                    </td>
                  </tr>
                  <tr v-else-if="row === 'tables'">
                    <td>
                      {{ itemToShow[row].map((item) => item.name).join(" ") }}
                    </td>
                  </tr>
                  <tr v-else-if="row === 'event_id'">
                    <td>
                      {{ getEventTitle(itemToShow[row]) }}
                    </td>
                  </tr>
                  <tr v-else-if="row === 'restaurant_space_id'">
                    <td>
                      {{ getRestaurantTitle(itemToShow[row]) }}
                    </td>
                  </tr>
                  <tr v-else-if="row === 'products'">
                    <td>
                      <p v-for="(item, index) in itemToShow[row]" :key="index">
                        {{
                          `${index + 1}. ${item.title} x${item.pivot.quantity}`
                        }}
                        <span style="color: blue;">{{
                          `(${getSubcategoryName(item.menu_subcategory_id)})`
                        }}</span>
                      </p>
                    </td>
                  </tr>
                  <tr v-else-if="row === 'total_price'">
                    <td>
                      {{ itemToShow[row] }}
                    </td>
                  </tr>

                  <tr v-else>
                    <td
                      v-if="
                        typeof itemToShow[row] === 'string' ||
                          typeof itemToShow[row] === 'number'
                      "
                    >
                      {{ itemToShow[row] }}
                    </td>
                  </tr>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="itemToShow['restaurant_space']">
            <h4>Расстановка:</h4>
            <h5 v-if="viewType === 'tablePreset'">
              (Масштаб столов: {{ itemToShow.scale }})
            </h5>
            <h5 v-if="viewType === 'tablesForDate'">
              (Масштаб столов: {{ itemToShow.table_preset.scale }})
            </h5>
            <div
              class="preset"
              :style="{
                background:
                  'url(' +
                  itemToShow['restaurant_space'].image_url +
                  ') center center / 100% 100% no-repeat',
                width: itemToShow['restaurant_space']['size_x'] + 'px',
                height: itemToShow['restaurant_space']['size_y'] + 'px',
              }"
            >
              <div
                v-for="(table, index) in itemToShow['tables']"
                :key="index"
                class="preset__table"
                :style="{
                  top: table['pivot']['coord_y'] + 'px',
                  left: table['pivot']['coord_x'] + 'px',
                  background:
                    'url(' +
                    table.image_url +
                    ') center center / 100% 100% no-repeat',
                  height: table.seats_count * tableScale + 'px',
                  'min-height': '30px',
                  width: table.seats_count * tableScale + 'px',
                  'min-width': '30px',
                  'margin-top': (-table.seats_count * tableScale) / 2 + 'px',
                  'margin-left': (-table.seats_count * tableScale) / 2 + 'px',
                }"
              >
                <span class="name">{{ table.name }}</span>
              </div>
            </div>
          </div>
          <div
            v-if="
              itemToShow.table_preset &&
                itemToShow.table_preset['restaurant_space']
            "
          >
            <h4>Расстановка:</h4>
            <h5>(Масштаб столов: {{ itemToShow.table_preset.scale }})</h5>
            <div
              class="preset"
              :style="{
                background:
                  'url(' +
                  itemToShow.table_preset['restaurant_space'].image_url +
                  ') center center / 100% 100% no-repeat',
                width:
                  itemToShow.table_preset['restaurant_space']['size_x'] + 'px',
                height:
                  itemToShow.table_preset['restaurant_space']['size_y'] + 'px',
              }"
            >
              <div
                v-for="(table, index) in itemToShow.table_preset['tables']"
                :key="index"
                class="preset__table"
                :style="{
                  top: table['pivot']['coord_y'] + 'px',
                  left: table['pivot']['coord_x'] + 'px',
                  background:
                    'url(' +
                    table.image_url +
                    ') center center / 100% 100% no-repeat',
                  height: table.seats_count * tableScale + 'px',
                  'min-height': '30px',
                  width: table.seats_count * tableScale + 'px',
                  'min-width': '30px',
                  'margin-top': (-table.seats_count * tableScale) / 2 + 'px',
                  'margin-left': (-table.seats_count * tableScale) / 2 + 'px',
                }"
              >
                <span class="name">{{ table.name }}</span>
              </div>
              <div
                v-for="(table, index) in itemToShow.additional_tables"
                :key="'a' + index"
                class="preset__table additional"
                :style="{
                  top: table['pivot']['coord_y'] + 'px',
                  left: table['pivot']['coord_x'] + 'px',
                  background:
                    'url(' +
                    table.image_url +
                    ') center center / 100% 100% no-repeat',
                  height: table.seats_count * tableScale + 'px',
                  'min-height': '30px',
                  width: table.seats_count * tableScale + 'px',
                  'min-width': '30px',
                  'margin-top': (-table.seats_count * tableScale) / 2 + 'px',
                  'margin-left': (-table.seats_count * tableScale) / 2 + 'px',
                }"
              >
                <span class="name">{{ table.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiUrls } from "@/config/constants";

export default {
  name: "ItemViewModal",
  props: [
    "showItemPopup",
    "columns",
    "headings",
    "itemToShow",
    "viewType",
    "editItem",
    "deleteItem",
    "close",
  ],
  computed: {
    rows() {
      return Object.keys(this.itemToShow);
    },
    tableScale() {
      return (
        parseFloat(
          this.itemToShow.table_preset
            ? this.itemToShow.table_preset.scale
            : this.itemToShow.scale || 1
        ) * 5
      );
    },
  },
  data() {
    return {
      restaurantSpaces: [],
      futureEvents: [],
      availableOptions: ["ПО", "ПС", "ПЗ"],
      orderStatusOptions: [
        { id: 1, title: "Ожидание" },
        { id: 2, title: "Принят" },
        { id: 3, title: "Отклонен" },
        { id: 4, title: "Ожидает оплаты" },
        { id: 5, title: "Отменен клиентом" },
          {id: 6, title: "Пришел"}
      ],

      subCategories: [],
    };
  },
  async mounted() {
    this.restaurantSpaces = await this.$store.dispatch("getRestaurantSpaces");

    let subcategoriesResponse = await this.$http.get(apiUrls.menuSubcategories);
    this.subCategories =
      subcategoriesResponse && subcategoriesResponse.data
        ? subcategoriesResponse.data.data
        : [];

    var eventsData = await this.$http.get("/events/dropdown");
    if (eventsData && eventsData.data && eventsData.data.data) {
      this.futureEvents = eventsData.data.data;
    }
  },
  methods: {
    getSubcategoryName(id) {
      let subCategory = this.subCategories.find((item) => item.id == id);
      return subCategory ? subCategory.name : "";
    },
    getRestaurantStatus(data) {
      return data ? data : "";
      // if (data >= 0 && data < this.availableOptions.length) {
      //   return this.availableOptions[data];
      // } else {
      //   return "";
      // }
    },
    getOrderStatus(data) {
      if (data - 1 >= 0 && data - 1 < this.availableOptions.length) {
        return this.orderStatusOptions[data - 1].title;
      } else {
        return "";
      }
    },
    getEventTitle(data) {
      if (!this.futureEvents) {
        return "";
      }

      var event = this.futureEvents.find((item) => item.id == data);
      return event ? event.title : "";
    },
    getRestaurantTitle(data) {
      if (!this.restaurantSpaces) {
        return "";
      }

      var space = this.restaurantSpaces.find((item) => item.id == data);
      return space ? space.name : "";
    },
  },
};
</script>
