<template>
    <div>
        <div v-if="showeditClient">
            <div class="popup__close" @click="closeEditClient">&times;</div>
            <div v-if="clientObj" class="client_info-edit">
                <h3 class="section__title" v-if="typeClient === 'create'">Создать нового клиента</h3>
                <h3 class="section__title" v-if="typeClient === 'edit'">Редактировать данные:</h3>
                <div class="site-input">
                    <label for="name">Имя</label>
                    <div class="input-item">
                        <input id="name" type="text" v-model="clientObj.name"/>
                    </div>
                </div>
                <div class="site-input">
                    <label for="last_name">Фамилия</label>
                    <div class="input-item">
                        <input id="last_name" type="text" v-model="clientObj.last_name"/>
                    </div>
                </div>
                <div class="site-input">
                    <label for="email">Email</label>
                    <div class="input-item">
                        <input id="email" type="text" v-model="clientObj.email"/>
                    </div>
                </div>
                <div class="site-input">
                    <label for="birthday">День рождения</label>
                    <div class="input-item">
                        <datepicker
                                id="birthday"
                                v-model="clientObj.birthday"
                                lang="ru"
                                :appendToBody="false"
                                :first-day-of-week="1"
                                :width="'auto'"
                                :valueType="'format'"
                        ></datepicker>
                    </div>
                </div>
                <div class="site-input">
                    <label for="phone">Телефон</label>
                    <div class="input-item">
                        <input id="phone" type="text" v-model="clientObj.phone"/>
                    </div>
                </div>
                <div class="site-input">
                    <label for="client_comment">Комментарий</label>
                    <div class="input-item">
                        <textarea id="client_comment" type="text" v-model="clientObj.comment"></textarea>
                    </div>
                </div>
                <div class="form__row">
                    <a
                            href
                            v-if="typeClient === 'edit'"
                            class="btn btn_primary"
                            @click.prevent="editClient"
                    >Редактировать
                    </a>
                    <a href v-else class="btn btn_primary" @click.prevent="creatNewClient">Сохранить</a>
                </div>
            </div>
        </div>

        <div v-else class="reserve-from-app-form">
            <h2 class="section__title" v-if="type === 'create'">Создать резерв</h2>
            <h2 class="modal__title" v-if="type === 'edit'">Редактировать резерв11: {{ reserveObj.id }}</h2>
            <form class="form" @submit.prevent="submitItem" ref="form">
                <div class="form__row">
                    <div class="site-input">
                        <label for="client_id">Клиент</label>
                        <div class="input-item client_multiselect" id="client_id">
                            <multiselect
                                    v-model="clientObj"
                                    :options="clients"
                                    :searchable="true"
                                    :internal-search="false"
                                    :loading="loadingUsers"
                                    :close-on-select="true"
                                    :clear-on-select="true"
                                    placeholder="Начните вводить имя/телефон/email клиента"
                                    :multiple="false"
                                    :trackBy="'id'"
                                    :custom-label="userWithEmailAndPhone"
                                    :selectLabel="'Выбрать'"
                                    :deselectLabel="'Убрать'"
                                    :selectedLabel="'Выбрано'"
                                    @search-change="searchUser"
                            >
                <span slot="noResult">
                  По вашему запросу нет результатов. Создать нового клиента:
                  <i
                          @click="showCreateClient"
                          class="fas fa-plus-square"
                  ></i>
                </span>
                            </multiselect>

                            <div v-if="!clientObj" @click="showCreateClient" class="client_btn-create">
                                <i class="fas fa-plus-square"></i>
                            </div>
                        </div>
                        <div v-if="!clientObj" class="booking__reserve booking__reserve2">
                            <div class="site-input">
                                <label>Имя</label>
                                <div class="input-item">
                                    <input type="text" v-model="newClient.name"/>
                                </div>
                            </div>
                            <div class="site-input">
                                <label>Фамилия</label>
                                <div class="input-item">
                                    <input type="text" v-model="newClient.last_name"/>
                                </div>
                            </div>
                            <div class="site-input">
                                <label>Телефон</label>
                                <div class="input-item">
                                    <input type="text" v-model="newClient.phone"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="client_info" v-if="clientObj">
                        <p>
                            <span>{{ clientObj.name }} {{ clientObj.last_name }}</span>
                            ,
                            {{ clientObj.phone }},
                            {{ clientObj.email }}
                        </p>
                        <p>{{ clientObj.comment }}</p>
                        <p>
                            <span>Cредний чек:</span>
                            {{ clientObj.avg_check }} грн,
                            <span>День рождения:</span>
                            {{ new Date(clientObj.birthday).toLocaleDateString() }}
                        </p>
                        <span
                                class="client_info-btn"
                                v-show="!showeditClient"
                                @click="showeditClient = !showeditClient"
                        >
              <i class="far fa-edit"></i>
            </span>
                    </div>
                </div>
                <div class="form__row">
                    <div class="booking__reserve">
                        <div class="form__col">
                            <div class="booking__reserve-tables" v-if="!ticketOnly">
                                <div class="site-input site-input_mb" v-if="mode !== 'booking' || addingTables">
                                    <label for="tables">Стол или группа столов</label>
                                    <div class="input-item" id="tables">
                                        <multiselect
                                                v-model="reserveObj.tables"
                                                :options="allTables"
                                                :searchable="true"
                                                :close-on-select="!addingTables"
                                                :clear-on-select="true"
                                                :placeholder="
                        addingTables ? 'Выберите столы' : 'Выберите стол'
                      "
                                                :multiple="addingTables"
                                                :trackBy="'id'"
                                                :label="'name'"
                                                :selectLabel="'Выбрать'"
                                                :deselectLabel="'Убрать'"
                                                :selectedLabel="'Выбрано'"
                                        >
                                            <template slot="option" slot-scope="props">
                                                <img
                                                        v-show="props.option.image_url"
                                                        class="option__image"
                                                        :src="props.option.image_url"
                                                        :alt="props.option.name"
                                                />
                                                <div class="option__desc">
                                                    <span class="option__title">{{ props.option.name }}</span>
                                                </div>
                                            </template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="site-input site-input_mb" v-else>
                                    <label for="tables">Стол или группа столов</label>
                                    <div class="input-item" id="tables">
                                        <multiselect
                                                :value="reserveObj.tables"
                                                :disabled="true"
                                                :options="allTables"
                                                :searchable="true"
                                                :close-on-select="true"
                                                placeholder="Выберите стол"
                                                :multiple="
                        reserveObj.tables && reserveObj.tables.length > 1
                      "
                                                :trackBy="'id'"
                                                :label="'name'"
                                                :selectLabel="'Выбрать'"
                                                :deselectLabel="'Убрать'"
                                                :selectedLabel="'Выбрано'"
                                        >
                                            <template slot="option" slot-scope="props">
                                                <img
                                                        v-show="props.option.image_url"
                                                        class="option__image"
                                                        :src="props.option.image_url"
                                                        :alt="props.option.name"
                                                />
                                                <div class="option__desc">
                                                    <span class="option__title">{{ props.option.name }}</span>
                                                </div>
                                            </template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="booking__reserve-tables-add" v-if="!addingTables">
                                    <i @click="addTables" class="fas fa-plus-square"></i>
                                </div>
                                <div class="booking__reserve-tables-cancel" v-else>
                                    <i @click="cancelAddTables" class="fas fa-times-circle"></i>
                                </div>
                            </div>
                            <div class="site-input">
                                <label for="restaurant_space_id">Зал Ресторана</label>
                                <div class="input-item" id="restaurant_space_id">
                                    <multiselect
                                            v-model="restaurant_space"
                                            :options="restaurantSpaces"
                                            :searchable="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            placeholder="Выберите зал..."
                                            :multiple="false"
                                            :trackBy="'id'"
                                            :label="'title'"
                                            :selectLabel="'Выбрать'"
                                            :deselectLabel="'Убрать'"
                                            :selectedLabel="'Выбрано'"
                                    >
                                        <span slot="noResult">По вашему запросу нет результатов.</span>
                                    </multiselect>
                                </div>
                            </div>
                            <div class="site-input">
                                <label for="event_id">Событие</label>
                                <div class="input-item" id="event_id">
                                    <multiselect
                                            v-model="reserveObj.event"
                                            :options="futureEvents"
                                            :searchable="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            placeholder="Выберите событие..."
                                            :multiple="false"
                                            :trackBy="'id'"
                                            :label="'title'"
                                            :selectLabel="'Выбрать'"
                                            :deselectLabel="'Убрать'"
                                            :selectedLabel="'Выбрано'"
                                    >
                                        <span slot="noResult">По вашему запросу нет результатов.</span>
                                    </multiselect>
                                </div>
                            </div>
                            <div class="site-input">
                                <label for="persons_count">Количество человек</label>
                                <div class="input-item">
                                    <input id="persons_count" type="number" v-model="reserveObj.persons_count"/>
                                </div>
                            </div>
                            <!-- <div class="site-input">
                              <label for="options">Статус</label>
                              <div class="input-item" id="options">
                                <multiselect
                                  v-model="reserveObj.options"
                                  :options="availableOptions"
                                  :searchable="false"
                                  :close-on-select="false"
                                  placeholder="Выберите статус"
                                  :multiple="true"
                                  :selectLabel="'Выбрать'"
                                  :deselectLabel="'Убрать'"
                                  :selectedLabel="'Выбрано'"
                                >
                                  <span slot="noResult"
                                    >По вашему запросу нет результатов.</span
                                  >
                                </multiselect>
                              </div>
                            </div>-->
                            <div class="site-input site-input_multirow">
                                <div class="status">
                                    <label for="options">Статус</label>
                                    <div class="input-item" id="options">
                                        <div class="checkbox-row">
                      <span class="checkbox-space">
                        <input
                                class="checkbox checkbox_normal"
                                type="checkbox"
                                id="ps"
                                v-model="reserveObj.options.ps"
                        />
                        <label for="ps">ПС</label>
                      </span>
                                            <span class="checkbox-space">
                        <input
                                class="checkbox checkbox_normal"
                                type="checkbox"
                                id="pz"
                                v-model="reserveObj.options.pz"
                        />
                        <label for="pz">ПЗ</label>
                      </span>
                                            <span class="checkbox-space">
                        <input
                                class="checkbox checkbox_normal"
                                type="checkbox"
                                id="po"
                                v-model="reserveObj.options.po"
                        />
                        <label for="po">ПО</label>
                      </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="site-input">
                                <label for="order_status">Статус Заказа</label>
                                <div class="input-item" id="order_status">
                                    <multiselect
                                            v-model="order_status"
                                            :options="orderStatusOptions"
                                            :searchable="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            placeholder="Выберите статус заказа..."
                                            :multiple="false"
                                            :trackBy="'id'"
                                            :label="'title'"
                                            :selectLabel="'Выбрать'"
                                            :deselectLabel="'Убрать'"
                                            :selectedLabel="'Выбрано'"
                                    >
                                        <span slot="noResult">По вашему запросу нет результатов.</span>
                                    </multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="form__col">
                            <div class="site-input">
                                <label for="date_start">
                                    {{
                                    mode === "booking" ? "Время начала" : "Дата и время начала"
                                    }}
                                </label>
                                <div class="input-item">
                                    <datepicker
                                            id="date_start"
                                            v-model="reserveObj.date_start"
                                            lang="ru"
                                            :type="mode === 'booking' ? 'time' : 'datetime'"
                                            :appendToBody="false"
                                            :format="mode === 'booking' ? 'HH:mm' : 'YYYY-MM-DD HH:mm'"
                                            :time-picker-options="{
                      start: '17:00',
                      step: '00:30',
                      end: '23:30',
                    }"
                                            :first-day-of-week="1"
                                            :width="'auto'"
                                            :valueType="'format'"
                                            :editable="false"
                                            :clearable="false"
                                            :placeholder="
                      mode === 'booking'
                        ? 'Выберите время'
                        : 'Выберите дату и время'
                    "
                                    ></datepicker>
                                </div>
                            </div>
                            <div class="site-input">
                                <label for="date_finish">
                                    {{
                                    mode === "booking"
                                    ? "Время окончания"
                                    : "Дата и время окончания"
                                    }}
                                </label>
                                <div class="input-item">
                                    <datepicker
                                            id="date_finish"
                                            v-model="reserveObj.date_finish"
                                            lang="ru"
                                            :type="mode === 'booking' ? 'time' : 'datetime'"
                                            :appendToBody="false"
                                            :format="mode === 'booking' ? 'HH:mm' : 'YYYY-MM-DD HH:mm'"
                                            :time-picker-options="{
                      start: '18:00',
                      step: '00:30',
                      end: '23:30',
                    }"
                                            :first-day-of-week="1"
                                            :width="'auto'"
                                            :valueType="'format'"
                                            :editable="false"
                                            :clearable="false"
                                            :placeholder="
                      mode === 'booking'
                        ? 'Выберите время'
                        : 'Выберите дату и время'
                    "
                                    ></datepicker>
                                </div>
                            </div>
                            <div class="site-input">
                                <label for="currency">Валюта</label>
                                <div class="input-item" id="currency">
                                    <div class="radio-row">
                                        <div
                                                v-for="(currency, index) in currencies"
                                                :key="index"
                                                class="radio-row__item"
                                        >
                                            <input
                                                    class="radio"
                                                    type="radio"
                                                    v-model="selectedCurrency"
                                                    :id="'currency' + index"
                                                    :value="currency"
                                            />
                                            <label :for="'currency' + index">{{ currency }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="site-input">
                                <label for="prepayment">Предоплата</label>
                                <div class="input-item">
                                    <input id="prepayment" type="number" v-model="reserveObj.prepayment"/>
                                </div>
                            </div>
                            <div class="site-input" v-if="!ticketOnly">
                                <label for="sms_send_time">SMS-напоминание</label>
                                <div class="input-item" id="sms_send_time">
                                    <div class="checkbox-row">
                                        <input
                                                class="checkbox checkbox_normal"
                                                type="checkbox"
                                                id="1hour"
                                                v-model="smsObj['1hour']"
                                        />
                                        <label for="1hour">За 1 час</label>
                                    </div>
                                    <div class="checkbox-row">
                                        <input
                                                class="checkbox checkbox_normal"
                                                type="checkbox"
                                                id="3hours"
                                                v-model="smsObj['3hours']"
                                        />
                                        <label for="3hours">За 3 часа</label>
                                    </div>
                                    <div class="checkbox-row">
                                        <input
                                                class="checkbox checkbox_normal"
                                                type="checkbox"
                                                id="thank_sms"
                                                v-model="reserveObj.thank_sms"
                                        />
                                        <label for="thank_sms">Сообщение спасибо</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="site-input">
                        <label for="comment">Сумма Бронирования</label>
                        <div class="input-item">{{ reserveObj.total_price }}</div>
                    </div>
                    <div class="site-input">
                        <label for="comment">Продукты:</label>
                        <ul>
                            <li v-for="(item, index) in reserveObj.products" :key="index">
                                {{ `${item.title} x${item.pivot.quantity}` }}
                                <span style="color: blue;">
                  {{
                  `(${getSubcategoryName(item.menu_subcategory_id)})`
                  }}
                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="site-input">
                        <label for="comment">Комментарий</label>
                        <div class="input-item">
                            <textarea id="comment" type="text" v-model="reserveObj.comment"></textarea>
                        </div>
                    </div>
                    <div class="dropzone">
                        <div class="site-input">
                            <label for="image">Изображение</label>
                            <p v-if="!show">
                                Перетащите сюда или выберите изображение
                                <i class="fa fa-cloud-upload"></i>
                            </p>
                            <input
                                    class="file-input"
                                    type="file"
                                    id="image"
                                    ref="file"
                                    @change="previewImage"
                                    accept="image/*"
                            />
                        </div>
                        <div class="image" v-if="show">
                            <img :src="imageData" alt/>
                            <span class="close" @click="deleteImage">&times;</span>
                        </div>
                    </div>
                    <div class="form__row form__row_space-between form__row_submit">
                        <button
                                class="btn btn_primary"
                                type="submit"
                                v-show="!submitting"
                        >Сохранить и перенести в Резервы
                        </button>
                        <div class="loader" v-show="submitting">Сохранение...</div>
                        <button
                                class="btn btn_danger"
                                @click.stop.prevent="deleteReserve"
                                v-if="reserveObj.id && !submitting"
                        >Отказать
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div
                class="modal"
                :class="{ fade: !showItemDeletePopup, open: showItemDeletePopup }"
                @click.self.stop="showItemDeletePopup = false"
        >
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="popup__close" @click="showItemDeletePopup = false">&times;</div>
                        <h4>Удалить резерв {{ reserveObj.id }}?</h4>
                        <div class="site-input popup__reason">
                            <label for="reason_remove">Укажите причину удаления:</label>
                            <div class="input-item">
                                <input id="reason_remove" type="text" v-model="reasonForRemoval"/>
                            </div>
                        </div>
                        <div class="popup__buttons popup__buttons_spread popup__buttons_mt">
                            <button class="btn btn_primary" @click.prevent="showItemDeletePopup = false">Отмена</button>
                            <button
                                    class="btn btn_danger"
                                    :disabled="!reasonForRemoval"
                                    @click.prevent="deleteConfirmed"
                            >Удалить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="moving-message" v-show="showError">
            <div>
                <p v-for="(error,index) in errors" :key="index">
                    <b>{{error}}</b>
                </p>
            </div>
            <button class="btn btn_danger" @click.stop.prevent="showError = false">Закрыть</button>
        </div>
    </div>
</template>

<script>
    import Multiselect from "vue-multiselect";
    import Datepicker from "vue2-datepicker";
    import {apiUrls} from "@/config/constants";
    import handleFormValidationErrors from "@/assets/js/mixins/handleFormValidationErrors";
    import moment from "moment";
    import {mapGetters} from 'vuex';

    const RESERVE_DURATION_HOURS_DEFAULT = 3;

    export default {
        name: "ReserveFromAppForm",
        components: {
            Multiselect,
            Datepicker
        },
        mixins: [handleFormValidationErrors],
        props: {
            type: {
                type: String,
                default: "create"
            },
            mode: {
                type: String,
                default: ""
            },
            reserve: {
                type: Object,
                default: () => ({
                    id: "",
                    client: {},
                    client_id: "",
                    table: {},
                    tables: [],
                    persons_count: "",
                    date_start: "",
                    date_finish: "",
                    options: "",
                    prepayment: "",
                    comment: "",
                    currency: ""
                })
            }
        },
        data() {
            return {
                showError: false,
                reserveObj: {
                    tables: [],
                    options: [],
                    sms_send_time: [],
                    restaurant_space: undefined,
                    thank_sms: false,
                    ticket: false,
                },
                itemToSubmit: {},
                currencies: ["UAH", "USD", "EUR", "RUR"],
                selectedCurrency: "UAH",
                clients: [{name: "", phone: ""}],
                loadingUsers: false,
                futureEvents: [],
                restaurantSpaces: [],
                availableOptions: {po: "ПО", ps: "ПС", pz: "ПЗ"},
                orderStatusOptions: [
                    {id: 1, title: "Ожидание"},
                    {id: 2, title: "Принят"},
                    {id: 3, title: "Отклонен"},
                    {id: 4, title: "Ожидает оплаты"},
                    {id: 5, title: "Отменен клиентом"},
                    {id: 6, title: "Пришел"}
                ],
                smsObj: {"1hour": false, "3hours": false},
                allTables: [{label: "", value: ""}],
                errors: [],
                searchQuery: "",
                selectedEvent: {},
                day: "",
                imageData: "",
                show: false,
                showeditClient: false,
                clientObj: null,
                typeClient: "edit",
                addingTables: false,
                tableBackup: {},
                newClient: {
                    name: "",
                    phone: "",
                    last_name: ""
                },
                submitting: false,
                showItemDeletePopup: false,
                reasonForRemoval: "",
                restaurant_space: null,
                order_status: null,

                subCategories: [],
                ticketOnly: false,
            };
        },
        computed: {
            ...mapGetters(['userRestaurant']),
            computedOptions() {
                // let options = "";
                // this.reserveObj.options.forEach((value) => {
                //   options.length ? (options += `, ${value}`) : (options = value || "");
                // });
                // return options;
                let options = "";
                for (let prop in this.reserveObj.options) {
                    if (
                        this.reserveObj.options.hasOwnProperty(prop) &&
                        this.reserveObj.options[prop]
                    ) {
                        options.length
                            ? (options += `, ${this.availableOptions[prop]}`)
                            : (options = this.availableOptions[prop] || "");
                    }
                }
                return options;
            }
        },
        watch: {
            "reserveObj.prepayment": {
                handler(val) {
                    if (val) {
                        if (this.reserveObj.options.indexOf("ПО") === -1) {
                            this.reserveObj.options.push("ПО");
                        }
                    }
                }
            }
        },
        methods: {
            getSubcategoryName(id) {
                let subCategory = this.subCategories.find(item => item.id == id);
                return subCategory ? subCategory.name : "";
            },
            getClients() {
                this.$http
                    .get("/clients", {params: {search: this.searchQuery}})
                    .then(resp => {
                        if (resp && resp.data && resp.data.data && resp.data.data.items) {
                            this.clients = resp.data.data.items;
                            this.loadingUsers = false;
                        }
                    });
            },
            getRestaurantSpaces() {
                return this.$store
                    .dispatch("getRestaurantSpaces")
                    .then(response => {
                        this.restaurantSpaces = response.map(item => {
                            item.title = item.name;
                            return item;
                        });
                        var selectedSpace = this.restaurantSpaces.find(
                            item => item.id == this.reserveObj.restaurant_space_id
                        );

                        this.restaurant_space = selectedSpace;
                    })
                    .catch(error => console.log(error));
            },
            getTables() {
                this.$http.get("/tables", {params: {restaurant_id: this.userRestaurant}}).then(resp => {
                    if (resp && resp.data && resp.data.data && resp.data.data.items) {
                        this.allTables = resp.data.data.items;
                    }
                });
            },
            getFutureEvents() {
                this.$http.get("/events/dropdown").then(resp => {
                    if (resp && resp.data.data) {
                        this.futureEvents = resp.data.data;
                        // console.log('futureEvents', this.futureEvents);
                    }
                });
            },
            createReserve(formData) {
                return this.$http
                    .post(apiUrls.reserve, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(data => data)
                    .catch(error => {
                        this.handleFormValidationErrors(error, this.$refs.form);
                    });
            },
            editReserve(formData, deleting = false) {
                if (!deleting) {
                    return this.$http
                        .post(apiUrls.reserve + "/" + this.reserveObj.id, formData, {
                            headers: {
                                "Content-Type": "multipart/form-data"
                            }
                        })
                        .then(data => data)
                        .catch(error => {
                            this.handleFormValidationErrors(error, this.$refs.form);
                        });
                } else {
                    return this.$http
                        .post(apiUrls.reserve + "/" + this.reserveObj.id, formData, {
                            headers: {
                                "Content-Type": "multipart/form-data"
                            }
                        })
                        .then(() => {
                            // console.log(data);
                            return this.$http.delete(
                                apiUrls.reserve + "/" + this.reserveObj.id
                            );
                        })
                        .catch(error => {
                            this.handleFormValidationErrors(error, this.$refs.form);
                        });
                }
            },
            async submitItem({deleting = false}) {
                this.errors = [];
                this.showError = false;
                if (!this.restaurant_space || !this.restaurant_space.id) {
                    this.errors.push("Укажите Зал Ресторана");
                }
                if (!this.reserveObj.date_start || this.reserveObj.date_start == "") {
                    this.errors.push("Укажите Время начала");
                }
                if (
                    !this.reserveObj.persons_count ||
                    this.reserveObj.persons_count == ""
                ) {
                    this.errors.push("Укажите Количество человек");
                }

                if (
                    !this.clientObj &&
                    (!this.newClient.name || this.newClient.name == "")
                ) {
                    this.errors.push("Укажите Имя");
                }
                if (
                    !this.clientObj &&
                    (!this.newClient.phone || this.newClient.phone == "")
                ) {
                    this.errors.push("Укажите Номер Телефона");
                }

                if (this.errors.length > 0) {
                    this.showError = true;
                    return;
                }

                if (!this.clientObj) {
                    try {
                        var clientResp = await this.$http.post(apiUrls.client, {
                            name: this.newClient.name,
                            phone: this.newClient.phone,
                            last_name: this.newClient.last_name
                        });
                        if (clientResp && clientResp.data && clientResp.data.data) {
                            this.clientObj = Array.isArray(clientResp.data.data)
                                ? clientResp.data.data[0]
                                : clientResp.data.data;
                        } else {
                            if (clientResp == `Error: Request failed with status code 422`) {
                                this.errors.push(
                                    "Пользователь с указанным номером телефона уже существует"
                                );
                                this.showError = true;
                                return;
                            }
                        }
                    } catch (err) {
                        this.errors.push("Произошла ошибка при добавлении пользователя");
                        this.showError = true;
                        return;
                    }
                }

                this.submitting = true;
                if (
                    this.reserveObj.prepayment &&
                    this.reserveObj.options.indexOf("ПО") === -1
                ) {
                    this.reserveObj.options.push("ПО");
                }

                if (!this.reserveObj.options) {
                    this.reserveObj.options = {
                        ps: false,
                        po: false,
                        pz: false
                    };
                } else {
                    this.reserveObj.options.ps =
                        this.reserveObj.options.ps == undefined
                            ? false
                            : this.reserveObj.options.ps;
                    this.reserveObj.options.po =
                        this.reserveObj.options.po == undefined
                            ? false
                            : this.reserveObj.options.po;
                    this.reserveObj.options.pz =
                        this.reserveObj.options.ps == undefined
                            ? false
                            : this.reserveObj.options.pz;
                }
                if (this.order_status && this.order_status.id == 4) {
                    this.reserveObj.options.po = true;
                    this.reserveObj.options.pz = true;
                }
                console.log("this.computedOptions", this.computedOptions);

                this.itemToSubmit = {
                    id: this.reserveObj.id,
                    client_id: this.clientObj ? this.clientObj.id : "",
                    tables: this.reserveObj.tables,
                    persons_count: this.reserveObj.persons_count,
                    date_start: this.reserveObj.date_start,
                    date_finish: this.reserveObj.date_finish,
                    options: this.computedOptions,
                    currency: this.selectedCurrency,
                    prepayment: this.reserveObj.prepayment,
                    status: this.order_status ? this.order_status.id : undefined,
                    sms_send_time: [],
                    event_id: this.reserveObj.event ? this.reserveObj.event.id : undefined,
                    restaurant_space_id: this.restaurant_space
                        ? this.restaurant_space.id
                        : undefined,
                    comment: deleting
                        ? (this.reserveObj.comment || "") +
                        " ПРИЧИНА УДАЛЕНИЯ: " +
                        this.reasonForRemoval
                        : this.reserveObj.comment,
                    image: this.reserveObj.image,
                    thank_sms: this.reserveObj.thank_sms
                };

                if (this.mode !== "booking") {
                    let normalDateLength = "YYYY-MM-DD HH:mm:ss".length;
                    if (normalDateLength - this.itemToSubmit.date_start.length === 3) {
                        this.itemToSubmit.date_start = this.itemToSubmit.date_start + ":00";
                    }
                    if (normalDateLength - this.itemToSubmit.date_finish.length === 3) {
                        this.itemToSubmit.date_finish = this.itemToSubmit.date_finish + ":00";
                    }
                } else {
                    this.itemToSubmit.date_start =
                        this.day + " " + this.itemToSubmit.date_start + ":00";
                    this.itemToSubmit.date_finish = this.itemToSubmit.date_finish
                        ? this.day + " " + this.itemToSubmit.date_finish + ":00"
                        : "";
                }

                if (
                    !this.itemToSubmit.date_finish ||
                    this.itemToSubmit.date_finish === ":00"
                ) {
                    let dateStartSafariFixed = new Date(
                        this.itemToSubmit.date_start.toString().replace(/-/g, "/")
                    );

                    this.itemToSubmit.date_finish = moment(
                        new Date().setTime(
                            dateStartSafariFixed.getTime() +
                            RESERVE_DURATION_HOURS_DEFAULT * 60 * 60 * 1000
                        )
                    ).format("YYYY-MM-DD HH:mm:ss");
                }

                let smses = [];

                if (this.smsObj["1hour"]) {
                    smses.push(this.dateStartSubtractHours(1));
                }

                if (this.smsObj["3hours"]) {
                    smses.push(this.dateStartSubtractHours(3));
                }
                this.itemToSubmit.sms_send_time = smses;
                console.log("this.itemToSubmit ReserveFromAppForm");
                let formData = new FormData();
                formData.append("restaurant_id", this.userRestaurant);
                formData.append("client_id", this.itemToSubmit.client_id);
                formData.append("persons_count", this.itemToSubmit.persons_count || "");
                formData.append(
                    "prepayment",
                    this.itemToSubmit.prepayment
                        ? this.itemToSubmit.prepayment + " " + this.itemToSubmit.currency
                        : ""
                );
                formData.append("ticket", (this.ticketOnly ? 1 : 0));
                formData.append("thank_sms", (this.itemToSubmit.thank_sms ? 1 : 0));
                formData.append("date_start", this.itemToSubmit.date_start);
                formData.append("date_finish", this.itemToSubmit.date_finish);
                formData.append("options", this.itemToSubmit.options);
                formData.append("comment", this.itemToSubmit.comment || "");
                if (this.itemToSubmit.restaurant_space_id) {
                    formData.append(
                        "restaurant_space_id",
                        this.itemToSubmit.restaurant_space_id || ""
                    );
                }
                if (this.itemToSubmit.event_id) {
                    formData.append("event_id", this.itemToSubmit.event_id);
                }
                formData.append("image", this.itemToSubmit.image || "");
                formData.append("from_mobile", deleting ? "1" : "0");
                formData.append("status", this.itemToSubmit.status);

                if (this.itemToSubmit.tables.length) {
                    for (let i = 0; i < this.itemToSubmit.tables.length; i++) {
                        formData.append("tables[" + i + "]", this.itemToSubmit.tables[i].id);
                    }
                } else if (this.itemToSubmit.tables.id) {
                    formData.append("tables[0]", this.itemToSubmit.tables.id);
                }

                if (this.itemToSubmit.sms_send_time.length) {
                    for (let i = 0; i < this.itemToSubmit.sms_send_time.length; i++) {
                        formData.append(
                            "sms_send_time[" + i + "]",
                            this.itemToSubmit.sms_send_time[i]
                        );
                    }
                }

                let resp;

                if (this.type === "edit") {
                    // console.log('editReserve');
                    resp = await this.editReserve(formData, deleting);
                } else {
                    // console.log('createReserve');
                    resp = await this.createReserve(formData);
                }

                if (resp && resp.data && resp.data.success === true) {
                    this.$emit("itemSubmitSuccess", this.itemToSubmit);
                    this.$store.dispatch("getReservesFromApp");
                }

                this.submitting = false;
            },
            userWithEmailAndPhone(user) {
                if (user.last_name) {
                    return user.email
                        ? user.name +
                        " " +
                        user.last_name +
                        " — " +
                        user.email +
                        " — " +
                        user.phone
                        : user.name + " " + user.last_name + " — " + user.phone;
                } else {
                    return user.email
                        ? user.name + " — " + user.email + " — " + user.phone
                        : user.name + " — " + user.phone;
                }
            },
            searchUser(searchQuery) {
                this.searchQuery = searchQuery;
                this.getClients();
            },
            previewImage(ev) {
                let input = ev.target;
                if (input.files && input.files[0]) {
                    let reader = new FileReader();
                    reader.onload = e => {
                        this.imageData = e.target.result;
                        this.show = !!this.imageData;
                    };
                    reader.readAsDataURL(input.files[0]);
                }
                this.reserveObj.image = this.$refs.file.files[0];
            },
            deleteImage() {
                this.reserveObj.image = null;
                this.imageData = "";
                this.show = false;
                this.$refs.file.value = "";
            },
            editClient() {
                this.$http
                    .put(apiUrls.client + "/" + this.clientObj.id, {
                        name: this.clientObj.name,
                        last_name: this.clientObj.last_name,
                        email: this.clientObj.email,
                        birthday: this.clientObj.birthday,
                        phone: this.clientObj.phone,
                        comment: this.clientObj.comment,
                        image: this.clientObj.image
                    })
                    .then(resp => {
                        if (resp && resp.data.data) {
                            this.showeditClient = !this.showeditClient;
                            this.typeClient = "edit";
                            this.getClients();
                        }
                    })
                    .catch(error => {
                        this.handleFormValidationErrors(error, this.$refs.form);
                    });
            },
            creatNewClient() {
                this.$http
                    .post(apiUrls.client, {
                        name: this.clientObj.name,
                        last_name: this.clientObj.last_name,
                        email: this.clientObj.email,
                        birthday: this.clientObj.birthday,
                        phone: this.clientObj.phone,
                        comment: this.clientObj.comment,
                        image: this.clientObj.image
                    })
                    .then(resp => {
                        if (resp && resp.data.data) {
                            this.clientObj.id = resp.data.data.id;
                            this.showeditClient = !this.showeditClient;
                            this.typeClient = "edit";
                            this.getClients();
                        }
                    })
                    .catch(error => {
                        this.handleFormValidationErrors(error, this.$refs.form);
                    });
            },
            showCreateClient() {
                this.typeClient = "create";
                this.clientObj = {
                    name: "",
                    last_name: "",
                    email: "",
                    birthday: "",
                    phone: "",
                    comment: "",
                    image: "",
                    avg_check: ""
                };
                this.showeditClient = !this.showeditClient;
            },
            closeEditClient() {
                if (!this.clientObj.id) {
                    this.clientObj = null;
                }
                this.showeditClient = !this.showeditClient;
            },
            addTables() {
                this.addingTables = true;
                this.tableBackup = [].concat(this.reserveObj.tables);
            },
            cancelAddTables() {
                this.reserveObj.tables = [].concat(this.tableBackup);
                this.addingTables = false;
            },
            dateStartSubtractHours(hours) {
                let dateStartSafariFixed = new Date(
                    this.itemToSubmit.date_start.toString().replace(/-/g, "/")
                );

                return moment(
                    new Date().setTime(
                        dateStartSafariFixed.getTime() - hours * 60 * 60 * 1000
                    )
                ).format("YYYY-MM-DD HH:mm:ss");
            },
            deleteReserve() {
                this.showItemDeletePopup = true;
            },
            deleteConfirmed() {
                this.submitItem({deleting: true})
                    .then(resp => {
                        if (resp.data && resp.data.success === true) {
                            this.$http
                                .delete(apiUrls.reserve + "/" + this.reserveObj.id)
                                .then(resp => {
                                    this.showItemDeletePopup = false;

                                    if (resp.data && resp.data.success === true) {
                                        this.$emit("itemDeleted");
                                    }
                                })
                                .catch(err => console.log(err));
                        }
                    })
                    .catch(err => console.log(err));
            }
        },
        async mounted() {
            this.reserveObj = Object.assign({}, this.reserve);
            console.log(this.reserveObj);
            let subcategoriesResponse = await this.$http.get(apiUrls.menuSubcategories);
            this.subCategories =
                subcategoriesResponse && subcategoriesResponse.data
                    ? subcategoriesResponse.data.data
                    : [];

            this.order_status = this.reserveObj.status
                ? this.orderStatusOptions[this.reserveObj.status - 1]
                : null;

            this.itemToSubmit.date_start = this.reserveObj.date_start;

            console.log("this.reserveObj.smses", this.reserve.smses);
            if (this.reserve.smses && this.reserve.smses.length) {
                this.reserve.smses.forEach(sms => {
                    console.log("!!sms");
                    console.log(this.dateStartSubtractHours(1));
                    console.log(moment(sms.send_time).format("YYYY-MM-DD HH:mm:ss"));
                    console.log(moment(sms.send_time).format("YYYY-MM-DD HH:mm:ss"));
                    console.log(this.dateStartSubtractHours(3));
                    console.log("sms!!");
                    if (
                        this.dateStartSubtractHours(1) ===
                        moment(sms.send_time).format("YYYY-MM-DD HH:mm:ss")
                    ) {
                        this.smsObj["1hour"] = true;
                    }
                    if (
                        this.dateStartSubtractHours(3) ===
                        moment(sms.send_time).format("YYYY-MM-DD HH:mm:ss")
                    ) {
                        this.smsObj["3hours"] = true;
                    }
                    if (
                        this.dateStartSubtractHours(1) <
                        moment(sms.send_time).format("YYYY-MM-DD HH:mm:ss")
                    ) {
                        this.reserveObj.thank_sms = true;
                    }
                });
            }

            if (this.mode === "booking") {
                this.day = this.reserveObj.date_start.slice(0, 10);
                this.reserveObj.date_start = this.reserveObj.date_start.slice(
                    11,
                    this.reserveObj.date_start.length - 3
                );
                this.reserveObj.date_finish = this.reserveObj.date_finish.slice(
                    11,
                    this.reserveObj.date_finish.length - 3
                );
            }

            if (this.reserveObj.ticket) {
                this.ticketOnly = true;
            }

            if (typeof this.reserveObj.options === "string") {
                this.reserveObj.options = this.reserveObj.options.split(", ");
            } else {
                this.reserveObj.options = [];
            }

            if (this.reserveObj.image_url) {
                this.imageData = this.reserveObj.image_url;
                this.show = true;
            }

            if (this.reserve.prepayment) {
                let array = this.reserve.prepayment.split(" ");
                this.reserveObj.prepayment = array[0];
                this.reserveObj.currency = array[1];
            }

            if (this.reserve.client) {
                this.clientObj = this.reserve.client;
            }

            if (this.ticketOnly) {
                this.smsObj["1hour"] = false;
                this.smsObj["3hours"] = false;
                this.reserveObj.thank_sms = false;
            }

            Promise.all([
                this.getClients(),
                this.getTables(),
                this.getFutureEvents(),
                this.getRestaurantSpaces()
            ]).then(this.addWatcher("reserveObj"));
        }
    };
</script>
