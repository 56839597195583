import Vue from 'vue'
import App from './App'
import router from './router/router'
import store from './store'
import axios from './plugins/axios-instance'
import clearReadonly from "@/assets/js/mixins/clearReadonly"
import addItemWatcher from "@/assets/js/mixins/addItemWatcher"
import {ClientTable} from 'vue-tables-2'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@/assets/sass/app.scss'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

Vue.component('VueSlider', VueSlider)

Vue.component('vue-phone-number-input', VuePhoneNumberInput);

window.$ = window.jQuery = require('jquery');

Vue.use(VueSweetalert2, {
    confirmButtonColor: '#0346bc'
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return;
        }
        next('/login')
    } else if (to.matched.some(record => record.meta.guest)) {
        if (!store.getters.isLoggedIn) {
            next();
            return;
        }
        next('/')
    } else {
        next();
    }
});

axios.interceptors.request.use(function (config) {
    store.state.globalLoading = true;
    return config;
}, function (error) {
    store.state.globalLoading = false;
    return error;
});

axios.interceptors.response.use(function (response) {
    store.state.globalLoading = false;
    return response;
}, function (error) {
    return new Promise((resolve) => {
        if (error && error.response && error.response.status === 401) {
            store.dispatch('logout').then(() => router.go('/login'))
        } else {
            resolve(error);
        }
    }).catch(e => console.log(e));
});

Vue.prototype.$http = axios;
Vue.config.productionTip = false;
Vue.mixin(clearReadonly);
Vue.mixin(addItemWatcher);
Vue.use(ClientTable, {}, false, 'bootstrap3', 'default');

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
}).$mount('#app');
