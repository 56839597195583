<template>
    <div class="section events">
        <event-form :type="'create'" @itemSubmitSuccess="onEventSubmitSuccess"></event-form>
    </div>
</template>

<script>
import EventForm from "@/components/pages/Cabinet/Events/blocks/EventForm";

export default {
    name: 'EventCreate',
    components: {
        EventForm
    },
    methods: {
        onEventSubmitSuccess() {
            this.$router.push('/events');
        }
    }
}
</script>