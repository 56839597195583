<template>
    <div class="section table-presets">
        <h2 class="section__title">Расстановки столов: сохраненные</h2>
        <div class="section__create-btn">
            <router-link to="/table-presets/create/">
                <button class="btn btn_success">Создать расстановку</button>
            </router-link>
        </div>

        <data-table ref="table" :options="tableOptions"></data-table>
    </div>
</template>

<script>
import DataTable from "@/components/global/DataTable/index";

export default {
    name: 'TablePresetsSaved',
    components: {
        DataTable
    },
    data() {
        return {
            tableOptions: {
                type: 'tablePreset',
                columns: ['id', 'name', 'restaurant_space.name', 'edit'],
                filterable: ['name', 'restaurant_space.name'],
                sortable: [], //todo ?
                headings: {
                    id: '#',
                    name: 'Название расстановки',
                    'restaurant_space.name': 'Зал',
                    edit: 'Действия',

                    restaurant_space: 'Зал',
                    comment: 'Комментарий',
                    created_at: 'Создан',
                    updated_at: 'Обновлен'
                },
                prefetchDataList: [],
                actions: ['view', 'edit', 'delete','copy']
            },
        }
    },
}
</script>