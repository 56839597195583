export default {
    methods: {
        addWatcher(dataProp) {
            this[dataProp + 'Watcher'] = this.$watch(dataProp, {
                handler: function(newValue) {
                    this.$emit('itemChanged', newValue)
                },
                deep: true
            });
        }
    }
};