<template>
    <div class="section tables">
        <h2 class="section__title">Столы</h2>
        <div class="section__create-btn">
            <router-link to="/tables/create/">
                <button class="btn btn_success btn_mr">Создать стол</button>
            </router-link>
        </div>

        <data-table ref="table" :options="tableOptions"></data-table>
    </div>
</template>

<script>
import DataTable from "@/components/global/DataTable/index";
// import {misc} from "@/config/constants";

export default {
    name: 'Tables',
    components: {
        DataTable
    },
    data() {
        return {
            tableTypes: {},
            tableOptions: {
                type: 'table',
                columns: ['id', 'name', 'seats_count', 'edit'],
                filterable: ['name','seats_count'],
                sortable: [], //todo ?
                headings: {
                    id: '#',
                    name: 'Название стола',
                    seats_count: 'Количество мест',
                    edit: 'Действия',

                    image_url: 'Изображение'
                },
                prefetchDataList: [],
                actions: ['view', 'edit', 'delete']
            }
        }
    },
    methods: {}
}
</script>