<template>
    <span v-if="data.tables && data.tables.length">
        <span v-for="(table, index) in data.tables" :key="index"> {{table.name}} </span>
    </span>
</template>

<script>
export default {
    name: "tables",
    props: ['data', 'index']
}
</script>