<template>
    <div class="section main">
        <div>v{{$store.state.version}}</div>
        <br/>
        <h3>Обучающие видео:</h3>
        <div><a class="tutorial-link" href="https://youtu.be/7s2psufdJMI" target="_blank">Добавление / редактирование меню ресторана</a></div>
        <div><a class="tutorial-link" href="https://youtu.be/5ZL3ukBqvnk" target="_blank">Cуперадминка, изменить/добавить зал ресторана, ивент по умолчанию</a></div>
        <div><a class="tutorial-link" href="https://youtu.be/5Iyl62yY390" target="_blank">Отправка PUSH уведомлений в приложение</a></div>
        <div><a class="tutorial-link" href="https://youtu.be/whCckuHuAJ4" target="_blank">Работа с ивентами, расписание дня, резервы из приложения</a></div>
        <div><a class="tutorial-link" href="https://youtu.be/HOIQ-ebvq9k" target="_blank">Сохранение резерва</a></div>
        <div><a class="tutorial-link" href="https://youtu.be/MTQql0tsGS0" target="_blank">Как исправить расстановку зала-1</a></div>
        <div><a class="tutorial-link" href="https://youtu.be/sJN0UHlFwjs" target="_blank">Как исправить расстановку зала-2</a></div>
        <div><a class="tutorial-link" href="https://youtu.be/0wG7XOlVu2o" target="_blank">Разные расстановки столов на разные даты</a></div>
    </div>
</template>

<script>
export default {
    name: 'Main',
    components: {
    }
}
</script>