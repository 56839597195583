<template>
    <span>
        <a href="" style="color: #333"   @click.prevent="show"> {{data.client ? data.client.name + ' ' + (data.client.last_name || '') : ''}}</a>
    </span>
</template>

<script>
export default {
    name: "client",
    props: ['data', 'index'],
    methods:{
        show(){
            this.$parent.$parent.$emit('showItem', this.data.client);
        }
    }
}
</script>