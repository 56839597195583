<template>
    <div class="layout">
        <div class="layout__content">
            <div class="main" :class="{'booking-fullscreen' : isFullscreenModeOn($route)}">
                <sidebar v-if="isIn"></sidebar>
                <div class="content" :class="{'wide': sidebarMinimized}">
                    <slot></slot>
                </div>
                <global-loader></global-loader>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import Sidebar from "../global/Sidebar";
import GlobalLoader from "@/components/global/GlobalLoader";

export default {
    name: "layout",
    components: {
        Sidebar,
        GlobalLoader
    },
    data() {
        return {

        }
    },
    computed: {
        isIn() {
            return this.$route.name !== 'Login'
        },
        ...mapState(['user', 'sidebarMinimized', 'globalLoading']),
        ...mapGetters(['isLoggedIn', 'isFullscreenModeOn'])
    },
    methods: {
        logout() {
            this.$store.dispatch('logout');
        }
    }
}
</script>