<template>
    <div class="section events">
        <h2 class="section__title">События</h2>
        <div class="section__create-btn">
            <router-link to="/events/create/">
                <button class="btn btn_success">Создать событие</button>
            </router-link>
        </div>

        <data-table ref="table" :options="tableOptions"></data-table>
    </div>
</template>

<script>
import DataTable from "@/components/global/DataTable/index";

export default {
    name: 'Users',
    components: {
        DataTable
    },
    data() {
        return {
            tableOptions: {
                type: 'event',
                columns: ['id', 'title', 'date_time', 'edit'],
                filterable: ['title', 'date_time'],
                sortable: [], //todo ?
                headings: {
                    id: '#',
                    title: 'Название',
                    date_time: 'Дата',
                    edit: 'Действия',

                    description: 'Описание',
                    ticket_price: 'Цена билета',
                    min_deposit: 'Минимальный депозит',
                    image_url: 'Изображение'
                },
                prefetchDataList: [],
                actions: ['view', 'edit', 'delete']
            },
        }
    }
}
</script>