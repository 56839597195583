<template>
    <div class="section">
        <h2 class="section__title">Встретить гостя</h2>
        <div class="new-event">
            <form class="form" @submit.prevent="submitItem" ref="form">
                <div class="form__row">
                    <div class="site-input">
                        <label for="title">Код</label>
                        <div class="input-item">
                            <input id="title" type="text" v-model="code" required>
                        </div>
                    </div>
                </div>
                <div class="form__row form__row_submit">
                    <button class="btn btn_primary" type="submit" v-show="!submitting">Проверить</button>
                    <div class="loader" v-show="submitting">Проверка...</div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import {apiUrls} from "@/config/constants";

    export default {
        name: 'Meet',
        components: {},
        data() {
            return {
                code: null,
                submitting: false
            }
        },
        methods: {
            checkCode(formData) {
                return this.$http.post(apiUrls.meet, formData)
                    .then(data  => data)
                    .catch(error => error)
            },
            async submitItem() {
                this.submitting = true;

                let formData = new FormData();
                formData.append('code', this.code);
                let resp = await this.checkCode(formData);

                if (resp && resp.data) {
                    if (resp.data.success === true) {
                        this.code = null;
                        this.$swal('Успешно!', resp.data.message, 'success');
                    } else {
                        this.$swal('Ошибка!', resp.data.message, 'error');
                    }
                } else {
                    this.$swal('Ошибка!', 'Системная ошибка, обновите страницу', 'error');
                }

                this.submitting = false;
            }
        },
    }
</script>