<template>
    <div>
        <h2 class="section__title" v-if="type === 'create'">Создать пользователя</h2>
        <h2 class="modal__title" v-if="type === 'edit'">Редактировать пользователя: {{userObj.username}}</h2>
        <div class="new-user">
            <form class="form" @submit.prevent="submitUser" ref="form">
                <div class="form__col">
                    <div class="site-input">
                        <label for="username">Имя пользователя</label>
                        <div class="input-item">
                            <input id="username" type="text" v-model="userObj.username" required>
                        </div>
                    </div>
                    <div class="site-input">
                        <label for="last_name">Фамилия</label>
                        <div class="input-item">
                            <input id="last_name" type="text" v-model="userObj.last_name">
                        </div>
                    </div>
                    <div class="site-input">
                        <label for="email">Email</label>
                        <div class="input-item">
                            <input id="email" type="text" v-model="userObj.email">
                        </div>
                    </div>
                    <div class="site-input">
                        <label for="status">Статус</label>
                        <div class="input-item">
                            <select name="status" id="status" v-model="userObj.status">
                                <option v-for="(status, index) in availableStatuses" :key="index" :value="status.value">{{status.label}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form__col">
                    <div class="site-input" v-if="type === 'create'">
                        <label for="password">Пароль</label>
                        <div class="input-item">
                            <input id="password" type="password" v-model="userObj.password" minlength="6">
                        </div>
                    </div>
                    <div class="site-input site-input_mb">
                        <label for="roles">Роли</label>
                        <div class="input-item" id="roles">
                            <multiselect v-model="userObj.roles"
                                         :options="availableRoles"
                                         :searchable="false"
                                         :close-on-select="false"
                                         placeholder="Выберите роли"
                                         :multiple="true"
                                         :trackBy="'value'"
                                         :label="'label'"
                                         :selectLabel="'Выбрать'"
                                         :deselectLabel="'Убрать'"
                                         :selectedLabel="'Выбрано'"
                            ></multiselect>
                        </div>
                    </div>
                    <div class="site-input">
                        <label for="birthday">День рождения</label>
                        <div class="input-item">
                            <datepicker id="birthday"
                                        v-model="userObj.birthday"
                                        lang="ru"
                                        type="date"
                                        :appendToBody="false"
                                        format="YYYY-MM-DD"
                                        :first-day-of-week="1"
                                        :width="'auto'"
                                        :valueType="'format'"
                            ></datepicker>
                        </div>
                    </div>
                    <div class="site-input">
                        <label for="phone">Телефон</label>
                        <div class="input-item">
                            <input id="phone" type="text" v-model="userObj.phone">
                        </div>
                    </div>
                    <div class="site-input">
                        <label for="additional_info">Дополнительная информация</label>
                        <div class="input-item">
                            <input id="additional_info" type="text" v-model="userObj.additional_info">
                        </div>
                    </div>
                </div>
                <div class="form__row">
                    <div class="dropzone">
                        <div class="site-input">
                            <label for="picture">Изображение</label>
                            <p v-if="!show">Перетащите сюда или выберите изображение <i class="fa fa-cloud-upload"></i></p>
                            <input class="file-input" type="file" id="picture" ref="file" @change="previewImage" accept="image/*">
                        </div>
                        <div class="image" v-if="show">
                            <img :src="imageData" alt="">
                            <span class="close" @click="deleteImage">&times;</span>
                        </div>
                    </div>
                </div>
                <div class="form__row">
                    <button class="btn btn_primary" type="submit">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Datepicker from 'vue2-datepicker';
import {apiUrls} from "@/config/constants";
import handleFormValidationErrors from "@/assets/js/mixins/handleFormValidationErrors";

export default {
    name: 'UserForm',
    components: {
        Multiselect,
        Datepicker
    },
    mixins: [handleFormValidationErrors],
    props: {
        type: {
            type: String,
            default: 'create'
        },
        user: {
            type: Object,
            default: () => ({
                username: '',
                last_name: '',
                email: '',
                status: '10',
                password: '',
                roles: [],
                birthday: '',
                phone: '',
                additional_info: '',
                image: null,
            })
        }
    },
    data() {
        return {
            userObj: {},
            userToSubmit: {},
            availableRoles: [],
            availableStatuses: [],
            errors: [],
            imageData: '',
            show: false
        }
    },
    methods: {
        getUserRoles() {
            this.$store.dispatch('getDataList', 'USER_ROLES_LIST')
                .then(data => {
                    this.availableRoles = Object.keys(data.role).map(roleKey => {
                        let roleObj = {};
                        roleObj.label = data.role[roleKey];
                        roleObj.value = roleKey;
                        return roleObj
                    });

                    this.userObj.roles = Object.keys(this.user.roles).map(roleKey => {
                        let roleObj = {};
                        roleObj.label = data.role[roleKey];
                        roleObj.value = roleKey;
                        return roleObj
                    });
                });
        },
        getUserStatusList() {
            this.$store.dispatch('getDataList', 'USER_STATUS_LIST')
                .then(data => {
                    this.availableStatuses = Object.keys(data.status).map(statusKey => {
                        let statusObj = {};
                        statusObj.label = data.status[statusKey];
                        statusObj.value = statusKey;
                        return statusObj
                    });
                });
        },
        createUser(formData) {
            return this.$http.post(apiUrls.user.CREATE, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(data => data)
                .catch(error => {
                    this.handleFormValidationErrors(error, this.$refs.form)
                })
        },
        editUser(formData) {
            return this.$http.put(apiUrls.user.UPDATE + '/' + this.user.id, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(data => data)
                .catch(error => {
                    this.handleFormValidationErrors(error, this.$refs.form)
                })
        },
        async submitUser() {
            this.userToSubmit = {
                username: this.userObj.username,
                last_name: this.userObj.last_name,
                email: this.userObj.email,
                status: this.userObj.status,
                password: this.userObj.password,
                roles: this.userObj.roles ? this.userObj.roles.map(role => role.value) : [],
                birthday: this.userObj.birthday ? this.userObj.birthday + ' 23:59:00' : this.userObj.birthday,
                phone: this.userObj.phone,
                additional_info: this.userObj.additional_info,
                image: this.userObj.image,
                id: this.userObj.id
            };

            let formData = new FormData();
            formData.append('username', this.userToSubmit.username);
            formData.append('last_name', this.userToSubmit.last_name);
            formData.append('email', this.userToSubmit.email);
            formData.append('status', this.userToSubmit.status);
            if (this.type === 'create') {
                formData.append('password', this.userToSubmit.password);
            }
            formData.append('roles', JSON.stringify(this.userToSubmit.roles));
            formData.append('birthday', this.userToSubmit.birthday);
            formData.append('phone', this.userToSubmit.phone);
            formData.append('additional_info', this.userToSubmit.additional_info);
            formData.append('image', this.userToSubmit.image);

            let resp;

            if (this.type === 'edit') {
                resp = await this.editUser(formData);
            } else {
                resp = await this.createUser(formData);
            }

            if (resp && resp.data && resp.data.success === true) {
                this.$emit('userSubmitSuccess', this.userToSubmit);
            }

            this.submitting = false;
        },
        previewImage(event) {
            let input = event.target;
            if (input.files && input.files[0]) {
                let reader = new FileReader();
                reader.onload = e => {
                    this.imageData = e.target.result;
                    this.show = !!this.imageData;
                };
                reader.readAsDataURL(input.files[0]);
            }
            this.userObj.image = this.$refs.file.files[0];
        },
        deleteImage() {
            this.userObj.image = null;
            this.imageData = '';
            this.show = false;
            this.$refs.file.value = '';
        }
    },
    mounted() {
        this.userObj = Object.assign({}, this.user);

        if (this.userObj.picture && this.userObj.picture.length) {
            this.imageData = this.userObj.picture[0];
            this.show = true;
        }

        this.getUserRoles();
        this.getUserStatusList();
    }
}
</script>