export default {
    userRestaurant: state => state.user.restaurant_id,
    isLoggedIn: state => !!state.token,
    isFullscreenModeOn: state => route => state.fullscreenModeOn && route.path.indexOf('booking') !== -1,
    getDataListItemByKey: state => (dataList, dataListKey, dataKey) => {
        if (state[dataList] && state[dataList][dataListKey]) {
            if (dataKey && Array === dataKey.constructor) {
                return dataKey.map(dataKeyItem => state[dataList][dataListKey][dataKeyItem]).join(', ')
            } else {
                return state[dataList][dataListKey][dataKey]
            }
        } else {
            return dataKey
        }
    },
    reservesFromAppCounter: state => state.reservesFromAppCounter > 99 ? '99+' : state.reservesFromAppCounter,
    isCurrentTableBusy: state => state.bookingState && state.bookingState.table && state.bookingState.table.busy_now === 'busy'
}