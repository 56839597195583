const env = {
  production: "http://hostes.bulladmin.com.ua/api", //"http://queen:81/api", // "http://hostes.bulladmin.com.ua/api", //"http://queen/api", //
  development:"http://hostes.bulladmin.com.ua/api", //"http://queen:81/api", //"http://hostes.bulladmin.com.ua/api", // "http://hostes.queenkyiv.com/api"// "http://127.0.0.1:8001/api" //https://queendevapi.hierarchicalsystems.com/api", //http://queen-dev.bigdig.com.ua/api //"http://127.0.0.1:8000/api", //"http://hostes.queenkyiv.com/api", //"http://queen.bigdig.com.ua/api",//"http://queen.local:8888/api", //
};

const apiUrls = {
    baseURL: env[process.env.NODE_ENV] || env.development,
    login: "/login",
    user: "/users",
    client: "/clients",
    event: "/events",
    reserve: "/reserves",
    ticket: "/tickets",
    restaurant: "/restaurants",
    restaurantSpace: "/restaurant_spaces",
    table: "/tables",
    tableGroup: "/tableGroups",
    tablePreset: "/table_presets",
    tablesForDate: "/tables_for_date",
    booking: "/booking",
    reserveFromApp: "/reserves",
    medias: "/medias",
    media: "/media",
    menuCategories: "/menu_categories",
    menuSubcategories: "menu_subcategories",
    meet: "/reserves/check_code",

    EVENTS_GET_DEFAULT: "/events/get_default/",
};

const keys = {
  user: {
    local: "user",
  },
  token: {
    param: "api_token",
    local: "_x-_",
  },
  sidebarMinimized: {
    local: "_sbm_",
  },
  bookingState: {
    local: "_bs_",
  },
};

const misc = {
  dateUnsetLabel: "Всегда",
  WORKING_HOURS_OFFSET: 6, // До скольки часов N+1 дня считать рабочим N день
  RESERVE_DURATION_HOURS_DEFAULT: 3,
};

export { apiUrls, keys, misc };
