<template>
    <div class="section tables">
        <h2 class="section__title" v-if="type === 'create'">Создать стол</h2>
        <h2 class="modal__title" v-if="type === 'edit'">Редактировать стол: {{tableObj.id}}</h2>
        <div class="new-table">
            <form class="form" @submit.prevent="submitItem" ref="form">
                <div class="site-input">
                    <label for="name">Название стола</label>
                    <div class="input-item">
                        <input id="name" type="text" v-model="tableObj.name" required>
                    </div>
                </div>
                <div class="site-input">
                    <label for="seats_count">Количество мест</label>
                    <div class="input-item">
                        <input id="seats_count" type="number" v-model.number="tableObj.seats_count" required>
                    </div>
                </div>
                <div class="form__row">
                    <div class="dropzone">
                        <div class="site-input">
                            <label for="image">Изображение</label>
                            <p v-if="!show">Перетащите сюда или выберите изображение <i class="fa fa-cloud-upload"></i></p>
                            <input class="file-input" type="file" id="image" ref="file" @change="previewImage" accept="image/*">
                        </div>
                        <div class="image" v-if="show">
                            <img :src="imageData" alt="">
                            <span class="close" @click="deleteImage">&times;</span>
                        </div>
                    </div>
                </div>
                <div class="form__row form__row_m">
                    <div class="form__col">
                        Свободен сейчас?
                        <b v-if="tableObj.busy_now === 'busy'">Занят</b>
                        <b v-if="tableObj.busy_now === 'draft'">В процессе букинга</b>
                        <b v-if="!tableObj.busy_now">Свободен</b>
                        <button v-if="tableObj.busy_now" class="btn btn_m btn_pale" @click="freeTable(tableObj)">Освободить</button>
                    </div>
                    <div class="form__col">

                    </div>
                </div>
                <div class="form__row form__row_submit">
                    <button class="btn btn_primary" type="submit" v-show="!submitting">Сохранить</button>
                    <div class="loader" v-show="submitting">Сохранение...</div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import {apiUrls} from "@/config/constants";
import handleFormValidationErrors from "@/assets/js/mixins/handleFormValidationErrors";

import {mapGetters} from  'vuex';

export default {
    name: 'TableForm',
    mixins: [handleFormValidationErrors],
    props: {
        type: {
            type: String,
            default: 'create'
        },
        item: {
            type: Object,
            default: () => ({
                name: "",
                id: 0,
                seats_count: 0,
                image: ''
            })
        }
    },
    data() {
        return {
            tableObj: {},
            imageData: '',
            show: false,
            submitting: false
        }
    },
    computed: {
         ...mapGetters(['userRestaurant']),
    },
    methods: {
        createItem(formData) {
            return this.$http.post(apiUrls.table, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(data => data)
                .catch(error => {
                    this.handleFormValidationErrors(error, this.$refs.form)
                })
        },
        editItem(formData) {
            return this.$http.post(apiUrls.table + '/' + this.tableObj.id, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(data => data)
                .catch(error => {
                    this.handleFormValidationErrors(error, this.$refs.form)
                })
        },
        async submitItem() {
            this.submitting = true;

            this.itemToSubmit = {
                id: this.tableObj.id,
                name: this.tableObj.name,
                seats_count: this.tableObj.seats_count,
                image: this.tableObj.image,
            };

            let formData = new FormData();
            formData.append('name', this.itemToSubmit.name);
            formData.append('seats_count', this.itemToSubmit.seats_count);
            formData.append('image', this.itemToSubmit.image || '');
            formData.append('restaurant_id', this.userRestaurant);

            let resp;

            if (this.type === 'edit') {
                resp = await this.editItem(formData);
            } else {
                resp = await this.createItem(formData);
            }

            if (resp && resp.data && resp.data.success === true) {
                this.$emit('itemSubmitSuccess', this.itemToSubmit);
            }

            this.submitting = false;
        },
        previewImage(ev) {
            let input = ev.target;
            if (input.files && input.files[0]) {
                let reader = new FileReader();
                reader.onload = e => {
                    this.imageData = e.target.result;
                    this.show = !!this.imageData;
                };
                reader.readAsDataURL(input.files[0]);
            }
            this.tableObj.image = this.$refs.file.files[0];
        },
        deleteImage() {
            this.tableObj.image = null;
            this.imageData = '';
            this.show = false;
            this.$refs.file.value = '';
        },
        freeTable(table) {
            table.busy_now = '';
            this.$http.post('tables/' + table.id, table)
                .then(resp => {
                    this.tableObj.busy_now = resp.data.data.busy_now;
                })
        }
    },
    mounted() {
        this.tableObj = Object.assign({}, this.item);

        if (this.tableObj.image_url) {
            this.imageData = this.tableObj.image_url;
            this.show = true;
        }

        this.$http.get('tables/' + this.tableObj.id)
            .then(resp => {
                this.tableObj.busy_now = resp.data.data.busy_now;
                this.addWatcher('tableObj');
            })
            .catch(() => {
                this.addWatcher('tableObj');
            })
    }
}
</script>