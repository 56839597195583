import LoginComponent from '@/components/pages/Forms/Login';
import Main from '@/components/pages/Cabinet/Main';
import TablePresetsSaved from '@/components/pages/Cabinet/TablePresets/TablePresetsSaved';
import TablePresetsForDate from '@/components/pages/Cabinet/TablePresets/TablePresetsForDate';
import TablePresetCreate from '@/components/pages/Cabinet/TablePresets/TablePresetCreate';
import Events from '@/components/pages/Cabinet/Events/index';
import EventCreate from '@/components/pages/Cabinet/Events/EventCreate';
import Tables from '@/components/pages/Cabinet/Tables/index';
import TableCreate from '@/components/pages/Cabinet/Tables/TableCreate';
import Booking from '@/components/pages/Cabinet/Booking/index';
import Reserves from '@/components/pages/Cabinet/Reserves/index';
import Reserve from '@/components/pages/Cabinet/Reserves/Reserve';
import ReservesFromApp from '@/components/pages/Cabinet/ReservesFromApp/index';
// import ReserveLogs from '@/components/pages/Cabinet/ReserveLogs';
import NotFound from '@/components/pages/Error/NotFound';
import Meet from '@/components/pages/Cabinet/Meet/index';

export default [
    {
        path: '/login',
        name: 'Login',
        component: LoginComponent,
        meta: {
            guest: true
        }
    },
    {
        path: '/',
        name: 'Main',
        component: Main,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/table-presets',
        redirect: '/table-presets/saved',
    },
    {
        path: '/table-presets/saved',
        name: 'TablePresetsSaved',
        component: TablePresetsSaved,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/table-presets/on-date',
        name: 'TablePresetsForDate',
        component: TablePresetsForDate,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/table-presets/create',
        name: 'TablePresetCreate',
        component: TablePresetCreate,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/tables',
        name: 'Tables',
        component: Tables,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/tables/create',
        name: 'TableCreate',
        component: TableCreate,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/events',
        name: 'Events',
        component: Events,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/events/create',
        name: 'EventCreate',
        component: EventCreate,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/booking',
        name: 'Booking',
        component: Booking,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reserves',
        name: 'Reserves',
        component: Reserves,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reserves/create',
        name: 'CreateReserve',
        component: Reserve,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reserves/:reserveId/edit',
        name: 'EditReserve',
        component: Reserve,
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reserves-from-app',
        name: 'ReservesFromApp',
        component: ReservesFromApp,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/meet',
        name: 'Meet',
        component: Meet,
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '*',
        component: NotFound
    }
]