export default {
    methods: {
        handleFormValidationErrors(error, form) {
            try {
                if (!error.response.data.success) {
                    if (error.response.status === 422) {
                        this.showValidationErrors(error.response.data.errors);
                    } else {
                        this.showError(error.response.data, form)
                    }
                }
            } catch (e) {
                // console.log(e);
            }
        },
        showValidationErrors(errors) {
            this.clearErrors();
            Object.keys(errors).forEach(errorField => {
                let $field = $('#' + errorField);
                $field.addClass('validation-error');
                errors[errorField].forEach(error => {
                    $('<span class="validation-error__message">' + error + '</span>').insertAfter($field);
                });
            })
        },
        showError(error, form) {
            this.clearErrors();
            $('<span class="validation-error__message">' + error.message + '</span>').insertAfter($(form));
        },
        clearErrors() {
            $('.error').removeClass('validation-error');
            $('span.validation-error__message').detach();
        }
    },
    deactivated() {
        this.clearErrors();
    }
};