<template>
    <div class="section tables">
        <table-form :type="'create'" @itemSubmitSuccess="onItemSubmitSuccess"></table-form>
    </div>
</template>

<script>
import TableForm from "@/components/pages/Cabinet/Tables/blocks/TableForm";

export default {
    name: 'UserCreate',
    components: {
        TableForm
    },
    methods: {
        onItemSubmitSuccess() {
            this.$router.push('/tables');
        }
    }
}
</script>