<template>
    <div class="event-gallery">
        <label for="images">Фото
            <button class="btn btn_danger delete-button"
                    :class="{'visible': selectedItems.length}"
                    @click="deleteSelectedItems">
                Удалить фото ({{selectedItems.length}})
            </button>
        </label>
        <div class="gallery" id="images">
            <div class="gallery__item" v-for="(item, index) in eventGallery.images" :key="index" @click="toggleItemSelect(item)">
                <img :src="item.url || item.imageData" alt="">
                <div class="check" :class="{'selected': selectedItems.findIndex(selectedItem => selectedItem === item) > -1}"></div>
                <div class="gallery__item-title">
                    <div v-show="editingImageTitle !== index" class="pencil">
                        {{item.title}}
                        <span @click="editingImageTitle = index" class="pencil__btn"><i class="fas fa-pencil-alt"></i></span>
                    </div>
                    <div v-show="editingImageTitle === index" class="editing">
                        <textarea v-model="item.title"></textarea>
                        <span @click="editingImageTitle = null" class="ok"><i class="fas fa-check"></i></span>
                    </div>
                </div>
            </div>
            <div class="gallery__item gallery__item_plus">
                <input class="file-input" type="file" id="gallery-input" ref="galleryInput" @change="onGalleryInputChange" accept="image/*" multiple>
            </div>
        </div>

        <label for="videos">Видео</label>
        <div class="videos" id="videos">
            <div class="videos__item" v-for="(item, index) in eventGallery.videos" :key="index">
                <div class="videos__item-input videos__item-input_title">
                    <label :for="'video' + index + 'title'">Название</label>
                    <div class="input-item">
                        <input type="text" :id="'video' + index + 'title'" v-model="item.title">
                    </div>
                </div>
                <div class="videos__item-input videos__item-input_url">
                    <label :for="'video' + index">Ссылка на YouTube</label>
                    <div class="input-item">
                        <input type="text" :id="'video' + index" v-model="item.url" placeholder="Ссылка на YouTube">
                    </div>
                </div>
                <div class="delete" @click="deleteVideo(index)">&times;</div>
            </div>
            <div class="videos__item videos__item_plus" @click="addVideoRow">
                <div class="gallery__item gallery__item_plus gallery__item_sm"></div>
            </div>

        </div>

        <div class="gallery__button form__row form__row_submit">
            <button class="btn btn_primary" v-show="!submitting" @click.prevent="submitGallery">Сохранить</button>
            <div class="loader" v-show="submitting">Сохранение...</div>
        </div>
    </div>
</template>

<script>
import {apiUrls} from "@/config/constants";

export default {
    name: "EventGallery",
    props: ['id', 'media'],
    data: () => ({
        submitting: false,
        eventGallery: {
            images: [],
            videos: [],
        },
        defaultImage: {},
        defaultVideo: {},
        editingImageTitle: null,
        selectedItems: []
    }),
    watch: {
        media: {
            handler() {
                this.initMedia();
            },
            deep: true
        }
    },
    methods: {
        onGalleryInputChange(ev) {
            let input = ev.target;
            if (input.files && input.files.length) {
                for (let i = 0; i < input.files.length; i++) {
                    let image = Object.assign({}, this.defaultImage);
                    let reader = new FileReader();
                    reader.onload = e => {
                        image.imageData = e.target.result;
                        image.file = input.files[i];
                        this.eventGallery.images.push(image);
                    };
                    reader.readAsDataURL(input.files[i]);
                }
            }
        },
        addVideoRow() {
            this.eventGallery.videos.push(Object.assign({}, this.defaultVideo));
        },
        deleteVideo(index) {
            if (this.eventGallery.videos[index].id) {
                this.deleteMedia(this.eventGallery.videos[index])
                    .then(() => {
                        this.$emit('delete', [this.eventGallery.videos[index]]);
                        this.eventGallery.videos.splice(index, 1);
                    })
            } else {
                this.eventGallery.videos.splice(index, 1);
            }
        },
        submitGallery() {
            this.submitting = true;
            Promise.all(this.eventGallery.images.map(this.submitMedia).concat(this.eventGallery.videos.map(this.submitMedia)))
                .then(results => {
                    this.submitting = false;
                    this.$emit('submit', results);
                }, error => {
                    console.log(error);
                    this.submitting = false;
                })
        },
        submitMedia(media) {
            let changed = false;

            if (media.id) {
                let initialMedia = this.media.find(_media => _media.id === media.id);

                if (media.type ==='video') {
                    changed = initialMedia.url !== media.url || initialMedia.title !== media.title
                } else if (media.type ==='image') {
                    changed = initialMedia.title !== media.title
                }

                if (!changed) return Promise.resolve({data: {data: media}});

                return this.$http.put(apiUrls.medias + '/' + media.id,
                    media.type === 'image' ? {title: media.title} : {title: media.title, url: media.url})
            }

            let formData = new FormData();

            formData.append('type', media.type);
            formData.append('title', media.title);
            formData.append('mediable_type', media.mediable_type);

            if (media.type === 'video') {
                formData.append('video_link', media.url || '');
            }

            if (!media.id) {
                formData.append('mediable_id', this.id);
                if (media.type === 'image') {
                    formData.append('file', media.file);
                }
            }

            return this.$http.post(apiUrls.medias, formData, {headers: {'Content-Type': 'multipart/form-data'}})
        },
        deleteSelectedItems() {
            this.selectedItems = this.selectedItems.filter(selectedItem => {
                if (!selectedItem.id) {
                    this.eventGallery.images.splice(this.eventGallery.images.findIndex(item => item === selectedItem));
                    return false
                } else {
                    return true
                }
            });
            this.deleteItems(this.selectedItems)
                .then(() => {
                    this.selectedItems = [];
                })
        },
        deleteItems(items) {
            this.submitting = true;
            return Promise.all(items.map(this.deleteMedia))
                .then(results => {
                    this.submitting = false;
                    this.$emit('delete', results);
                }, error => {
                    console.log(error);
                    this.submitting = false;
                })
        },
        deleteMedia(media) {
            return this.$http.delete(apiUrls.medias + '/' + media.id)
        },
        toggleItemSelect(item) {
            if (!item.selected) {
                item.selected = true;
                this.selectedItems.push(item);
            } else {
                item.selected = false;
                this.selectedItems.splice(this.selectedItems.findIndex(selectedItem => selectedItem === item), 1);
            }
        },
        initMedia() {
            this.eventGallery = {images: [], videos: []};

            if (this.media && this.media.length) {
                this.media.forEach(media => {
                    if (media.type === 'image') {
                        this.eventGallery.images.push(Object.assign({}, media))
                    } else if (media.type === 'video') {
                        this.eventGallery.videos.push(Object.assign({}, media))
                    }
                });
            }
        }
    },
    mounted() {
        this.defaultVideo = {
                title: '',
                type: 'video',
                url: '',
                mediable_type: 'event',
                mediable_id: this.id
            };
        this.defaultImage = {
                title: '',
                type: 'image',
                file: '',
                mediable_type: 'event',
                mediable_id: this.id
            };

        this.initMedia();
    }
}
</script>