<template>
    <span>
        <a  href="" style="color: #333"  @click.prevent="show" v-if="data.event"> {{data.event.title}} </a>
        <span v-else>(Не задано)</span>
    </span>
</template>

<script>
export default {
    name: "event",
    props: ['data', 'index'],
    methods:{
        show(){
            this.$parent.$parent.$emit('showItem', this.data.event);
        }
    }
}
</script>