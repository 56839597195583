<template>
    <div id="app">
        <layout>
            <keep-alive :exclude="['TableForm', 'UserForm', 'ReserveForm', 'TablePresetForDateForm', 'TablePresetForm', 'Booking', 'Reserve']">
                <router-view></router-view>
            </keep-alive>
        </layout>
    </div>
</template>

<script>
import Layout from '@/components/layouts/default';

export default {
    name: 'App',
    components: {
        Layout,
    },
    mounted() {
        this.$store.state.version = '1.0.12'
        console.log('version: ', this.$store.state.version);
    }
}
</script>