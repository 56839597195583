<template>
    <div>
        <div class="reserve__top" v-if="options.type === 'reserve' || options.type === 'reserveFromApp'">
            <div class="site-input reserve__select">
                <label for="past_reserve">Период резервов</label>
                <div class="input-item" id="past_reserve">
                    <multiselect v-model="selectedReserveOptions"
                                 :options="reserveOptions"
                                 :close-on-select="true"
                                 :searchable="false"
                                 :clear-on-select="true"
                                 placeholder="Выберите период резервов..."
                                 :multiple="false"
                                 :selectLabel="'Выбрать'"
                                 :deselectLabel="'Убрать'"
                                 :selectedLabel="'Выбрано'"
                    >
                        <span slot="noResult">По вашему запросу нет результатов.</span>
                    </multiselect>
                </div>
            </div>
            <div class="reserve__order"> Сортировка:
                <button class="btn btn_theme" :class="{'active': options.getDataParams.orderBy === 'created_at' }" @click="changeOrderBy('created_at')">
                    Создан
                    <span class="sortby" v-show="options.getDataParams.sortedBy === 'desc'"><i class="fas fa-arrow-down"></i></span>
                    <span class="sortby sortby_rotate180" v-show="options.getDataParams.sortedBy === 'asc'"><i class="fas fa-arrow-down"></i></span>
                </button>
                <button class="btn btn_theme" :class="{'active': options.getDataParams.orderBy === 'date_start' }" @click="changeOrderBy('date_start')">
                    Дата начала
                    <span class="sortby" v-show="options.getDataParams.sortedBy === 'desc'"><i class="fas fa-arrow-down"></i></span>
                    <span class="sortby sortby_rotate180" v-show="options.getDataParams.sortedBy === 'asc'"><i class="fas fa-arrow-down"></i></span>
                </button>
            </div>
        </div>

        <div class="table-container">
            <v-client-table ref="table" :data="tableData" :columns="columns" :options="tableOptions">
                <template v-slot:prependBody>
                    <tr>
                        <td v-for="(column, index) in columns" :key="index">
                            <input v-if="searchObject[column] !== undefined"
                                   type="text"
                                   class="table__search form-control"
                                   :value="searchObject[column]"
                                   @input="debounceSearch($event.target.value, column)">
                        </td>
                    </tr>
                </template>
                <div class="table__controls" slot="status" slot-scope="{row}">
                    <span v-html="getStatus(row)"></span>
                </div>
                <div class="table__controls" slot="edit" slot-scope="{row}">
                    <a class="table__controls-btn" v-if="actions.includes('view')" @click.prevent="showItem(row)" title="Просмотр">
                        <i class="fas fa-eye"></i>
                    </a>
                    <a class="table__controls-btn" v-if="actions.includes('edit')" @click.prevent="editItem(row, 'edit')" title="Редактировать">
                        <i class="far fa-edit"></i>
                    </a>
                    <a class="table__controls-btn" v-if="actions.includes('delete')" @click.prevent="deleteItem(row)" title="Удалить">
                        <i class="far fa-trash-alt"></i>
                    </a>
                    <a class="table__controls-btn" v-if="actions.includes('copy')" @click.prevent="editItem(row, 'copy')" title="Копировать">
                        <i class="fa fa-clone" aria-hidden="true"></i>
                    </a>
                </div>
            </v-client-table>
            <pagination v-model="pagination.page" :records="pagination.records" @paginate="paginate" :options="paginationOptions" :perPage="pagination.perPage"></pagination>
        </div>

        <item-view-modal :showItemPopup="showItemPopup"
                         :rows="columns"
                         :headings="tableOptions.headings"
                         :close="closeItemPopup"
                         :itemToShow="itemToShow"
                         :viewType="viewType"
                         :editItem="editItem"
                         :deleteItem="deleteItem">
        </item-view-modal>

        <div v-if="showItemEditPopup" class="modal" :class="{'fade': !showItemEditPopup, 'open' : showItemEditPopup, 'fullscreen': options.type === 'tablePreset' || options.type === 'tablesForDate'}"
             @click.self.stop="showItemEditPopup = false">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="popup__main-controls">
                        <div class="popup__control popup__control_save" :class="{'disabled': !itemIsChanged}" @click="submitForm">
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                 width="1280.000000pt" height="1253.000000pt" viewBox="0 0 1280.000000 1253.000000"
                                 preserveAspectRatio="xMidYMid meet">
                                <g class="fill" transform="translate(0.000000,1253.000000) scale(0.100000,-0.100000)" stroke="none">
                                    <path d="M12010 12523 c-766 -82 -2057 -1035 -3968 -2928 -1158 -1147 -2569
                                        -2676 -3671 -3977 l-183 -216 -151 79 c-269 141 -759 385 -990 493 -1084 507
                                        -1783 723 -2272 703 -170 -7 -264 -29 -395 -93 -78 -39 -109 -61 -175 -128
                                        -139 -139 -199 -290 -199 -496 0 -182 7 -192 324 -487 400 -373 884 -874 1210
                                        -1253 790 -918 1590 -2112 2530 -3770 166 -293 199 -341 257 -381 142 -98 318
                                        -88 449 24 53 46 85 108 183 355 471 1180 1084 2483 1688 3587 1023 1869 2163
                                        3506 3478 4990 505 570 961 1038 1785 1831 563 541 725 717 810 876 141 264
                                        87 518 -143 673 -138 93 -362 140 -567 118z"/>
                                </g>
                            </svg>
                        </div>
                        <div class="popup__control popup__control_close" @click="closeItemEditPopup">
                            <span>&times;</span>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="modal modal__main-info" :class="{'fade': !confirmClose, 'open' : confirmClose}"
                             @click.self.stop="showItemDeletePopup = false">
                            <div class="modal-dialog modal-md">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <h4>Были внесены изменения, вы точно хотите закрыть форму?</h4>
                                        <div class="popup__buttons popup__buttons_spread popup__buttons_mt">
                                            <button class="btn btn_primary" @click.prevent="closeItemEditPopupCancel">Отмена</button>
                                            <button class="btn btn_danger" @click.prevent="closeItemEditPopupConfirm">Закрыть без сохранения</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <user-form v-if="options.type === 'user'" :type="'edit'" :user="itemToEdit" @userSubmitSuccess="onItemEditSuccess" @itemChanged="onItemChanged" ref="user"></user-form>
                        <table-form v-if="options.type === 'table'" :type="'edit'" :item="itemToEdit" @itemSubmitSuccess="onItemEditSuccess" @itemChanged="onItemChanged" ref="table"></table-form>
                        <event-form v-if="options.type === 'event'" :type="'edit'" :event="itemToEdit" @itemSubmitSuccess="onItemEditSuccess" @itemChanged="onItemChanged" ref="event"></event-form>
                        <reserve-form v-if="options.type === 'reserve' || options.type === 'ticket'" :type="typeForm ? 'create' : 'edit'" :reserve="itemToEdit" @itemSubmitSuccess="onItemEditSuccess" @itemChanged="onItemChanged"
                                      @itemDeleted="onItemDeleted" ref="reserve"></reserve-form>
                        <reserve-from-app-form v-if="options.type === 'reserveFromApp'" :type="typeForm ? 'create' : 'edit'" :reserve="itemToEdit" @itemSubmitSuccess="onItemEditSuccess"
                                               @itemChanged="onItemChanged" ref="reserveFromApp"></reserve-from-app-form>
                        <table-preset-form v-if="options.type === 'tablePreset'" :type="typeForm ? 'create' : 'edit'" :tablePreset="itemToEdit" @itemSubmitSuccess="onItemEditSuccess"
                                           @itemChanged="onItemChanged" ref="tablePreset"></table-preset-form>
                        <table-preset-for-date-form v-if="options.type === 'tablesForDate'" :type="typeForm ? 'create' : 'edit'" :tablePreset="itemToEdit" @itemSubmitSuccess="onItemEditSuccess"
                                                    @itemChanged="onItemChanged" ref="tablesForDate"></table-preset-for-date-form>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" :class="{'fade': !showItemDeletePopup, 'open' : showItemDeletePopup}"
             @click.self.stop="showItemDeletePopup = false">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="popup__close" @click="showItemDeletePopup = false">&times;</div>
                        <h4>Удалить {{itemToDelete.id}}?</h4>
                        <div class="site-input popup__reason" v-if="options.type === 'reserve' || options.type === 'ticket'">
                            <label for="reason_remove">Укажите причину удаления:</label>
                            <div class="input-item">
                                <input id="reason_remove" type="text" v-model="reasonForRemoval">
                            </div>
                        </div>
                        <div class="popup__buttons popup__buttons_spread popup__buttons_mt">
                            <button class="btn btn_primary" @click.prevent="showItemDeletePopup = false">Отмена</button>
                            <button class="btn btn_danger" v-if="options.type === 'reserve' || options.type === 'ticket'" :disabled="!reasonForRemoval" @click.prevent="deleteConfirmed(itemToDelete)">Удалить</button>
                            <button class="btn btn_danger" v-else @click.prevent="deleteConfirmed(itemToDelete)">Удалить</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" :class="{'fade': !showMessagePopup, 'open' : showMessagePopup}"
             @click.self.stop="showMessagePopup = false">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="popup__close" @click="showMessagePopup = false">&times;</div>
                        <p>{{message}}</p>
                        <div class="popup__buttons popup__buttons_spread popup__buttons_mt">
                            <button class="btn btn_primary" @click.prevent="showMessagePopup = false">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {apiUrls} from "@/config/constants";
    import Pagination from 'vue-pagination-2';
    import Multiselect from 'vue-multiselect';
    import UserForm from "@/components/pages/Cabinet/Users/blocks/UserForm";
    import TableForm from "@/components/pages/Cabinet/Tables/blocks/TableForm";
    import EventForm from "@/components/pages/Cabinet/Events/blocks/EventForm";
    import ReserveForm from "@/components/pages/Cabinet/Reserves/blocks/ReserveForm";
    import ReserveFromAppForm from "@/components/pages/Cabinet/ReservesFromApp/blocks/ReserveFromAppForm";
    import TablePresetForm from "@/components/pages/Cabinet/TablePresets/blocks/TablePresetForm";
    import TablePresetForDateForm from "@/components/pages/Cabinet/TablePresets/blocks/TablePresetForDateForm";
    import ItemViewModal from "@/components/global/Modals/ItemViewModal";

    import {mapGetters} from 'vuex';

    const invert = obj => {
        let new_obj = {};

        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                new_obj[obj[prop]] = '';
            }
        }

        return new_obj;
    };

    export default {
        name: "DataTable",
        props: ['options'],
        components: {
            UserForm,
            TableForm,
            EventForm,
            ReserveForm,
            ReserveFromAppForm,
            TablePresetForm,
            TablePresetForDateForm,
            ItemViewModal,
            Pagination,
            Multiselect
        },
        data() {
            return {
                tableData: [],
                searchQueryString: '',
                prefetchDataList: this.options.prefetchDataList || [],
                actions: this.options.actions || [],
                tableOptions: {
                    preserveState: true,
                    theme: 'bootstrap3',
                    template: 'default',
                    filterable: false,
                    headings: this.options.headings,
                    sortable: this.options.sortable || [], //todo ?
                    perPage: 20,
                    texts: {
                        noResults: 'Нет результатов',
                        loading: 'Загрузка...'
                    },
                    templates: this.options.templates
                },
                columns: this.options.columns,
                formatted: this.options.formatted || null,
                pagination: {
                    page: 1,
                    records: 0,
                    perPage: 20,
                    totalPages: 0
                },
                paginationOptions: {
                    format: true,
                    chunk: 10,
                    chunksNavigation: 'fixed',
                    edgeNavigation: true,
                    theme: 'bootstrap4',
                    texts: {
                        count: 'Показаны записи с {from} по {to} из {count}.',
                        first: 'На первую',
                        last: 'На последнюю'
                    }
                },
                searchObject: {},
                searchObjectWatcher: null,
                searchQueryTimeout: null,
                searchQueryObject: {},

                showItemPopup: false,
                viewType: '',
                itemToShow: {},
                showItemEditPopup: false,
                itemToEdit: {},
                showItemDeletePopup: false,
                itemToDelete: {},
                itemIsChanged: false,
                confirmClose: false,
                reasonForRemoval: '',
                typeForm: false,
                reserveOptions: ['Все', 'Прошедшие', 'Будущие'],
                selectedReserveOptions: '',
                availableReserveOptions: {po: 'ПО', ps: 'ПС', pz: 'ПЗ'},
                message: '',
                showMessagePopup: false,
                orderStatusOptions: [{id: 1, title: 'Ожидание'},
                    {id: 2, title: 'Принят'},
                    {id: 3, title: 'Отклонен'},
                    {id: 4, title: 'Ожидает оплаты'},
                    {id: 5, title: 'Отменен клиентом'},
                    {id: 6, title: "Пришел"}],
            }
        },
        computed: {
            ...mapGetters(['userRestaurant']),
            params() {
                return {
                    page: this.pagination.page || 1,
                    // ...this.searchQueryObject
                }
            }
        },
        methods: {
            getStatus(data) {
                if (!data || !data.status || (data.status < 1 || data.status > this.orderStatusOptions.length)) {
                    return '';
                }
                var color = 'black';
                if (data.status == 4 || data.status == 5) {
                    color = 'red';
                }
                return `<span style="color: ${color}">${this.orderStatusOptions[data.status - 1].title}</span>`;
            },
            getData(customDate = null) {
                let params = this.params;
                if (this.options.getDataParams) {
                    params = Object.assign(params, this.options.getDataParams)
                }

                if (this.options.searchParams && this.options.searchParams.length) {
                    this.options.searchParams.forEach(searchParamKey => {
                        params[searchParamKey] = this.searchObject[searchParamKey];
                    })
                }

                if (customDate !== null) {
                    params.date = customDate;
                }

                params.restaurant_id = this.userRestaurant;
                return this.$http.get(this.searchQueryString.length ? apiUrls[this.options.type] + '?search=' + this.searchQueryString : apiUrls[this.options.type], {params: params})
                    .then(resp => {
                        let data = resp.data.data.items;
                        if (this.formatted) {
                            this.tableData = data.map(item => {
                                Object.keys(this.formatted).forEach(column => {
                                    item[column] = this.formatted[column](item[column]);
                                });
                                return item
                            })
                            // console.log('formatted', this.tableData);
                        } else {
                            this.tableData = data.map(item => {
                                if (item.products) {
                                    item.total_price = this.getReservationsSum(item.products);
                                }
                                // if (item.status) {
                                //     item.status = this.getStatus(item);
                                // }
                                return item;
                            });
                            // console.log('tableData', this.tableData);
                        }

                        this.pagination.page = parseFloat(resp.data.data.page);
                        this.pagination.records = resp.data.data.records;
                        this.pagination.perPage = resp.data.data.perPage;
                        this.pagination.totalPages = resp.data.data.totalPages;
                    })
            },
            getReservationsSum(data) {
                var sum = 0;
                data.forEach(p => {
                    sum += p.price * p.pivot.quantity;
                });
                return sum.toFixed(2);
            },
            paginate(page) {
                this.pagination.page = page;
                this.getData();
            },
            debounceSearch(value, field) {
                if (this.searchQueryTimeout) {
                    clearTimeout(this.searchQueryTimeout);
                }
                this.searchQueryTimeout = setTimeout(() => {
                    this.searchObject[field] = value;
                    this.searchQueryTimeout = null;
                }, 600);
            },
            showItem(item) {
                if (this.options.type === 'tablePreset' || this.options.type === 'tablesForDate') {
                    this.viewType = this.options.type
                } else {
                    this.viewType = ''
                }
                this.itemToShow = item;
                this.showItemPopup = true;
            },
            editItem(item, type = 'edit') {
                this.showItemPopup = false;
                this.itemToEdit = item;
                this.showItemEditPopup = true;
                type === 'edit' ? this.typeForm = false : this.typeForm = true;
            },
            deleteItem(item) {
                this.showItemPopup = false;
                this.itemToDelete = item;
                this.showItemDeletePopup = true;
            },
            deleteConfirmed(item) {
                console.log('!!', item);
                if (this.options.type === 'reserve'  || this.options.type === 'ticket') {
                    let formData = new FormData();
                    formData.append('restaurant_id', this.userRestaurant);
                    formData.append('client_id', item.client_id);
                    formData.append('restaurant_space_id', item.restaurant_space_id);
                    for (let i = 0; i < item.tables.length; i++) {
                        formData.append('tables[' + i + ']', item.tables[i].id);
                    }
                    formData.append('persons_count', item.persons_count);
                    formData.append('prepayment', item.prepayment);
                    formData.append('date_start', item.date_start);
                    formData.append('date_finish', item.date_finish);

                    let options = '';
                    for (let prop in item.options) {
                        if (item.options.hasOwnProperty(prop) && item.options[prop]) {
                            options.length ? options += `, ${this.availableReserveOptions[prop]}` : options = this.availableReserveOptions[prop] || ''
                        }
                    }

                    formData.append('options', options);
                    formData.append('comment', item.comment || '' + " ПРИЧИНА УДАЛЕНИЯ: " + this.reasonForRemoval);
                    if (item.event_id)
                        formData.append('event_id', item.event_id || '');
                    if (item.image)
                        formData.append('image', item.image || '');

                    this.$http.post(apiUrls[this.options.type === 'ticket' ? 'reserve' : this.options.type] + '/' + item.id, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then(resp => {
                            if (resp.data && resp.data.success === true) {
                                this.$http.delete(apiUrls[this.options.type === 'ticket' ? 'reserve' : this.options.type] + '/' + item.id)
                                    .then(resp => {
                                        this.showItemDeletePopup = false;

                                        if (resp.data && resp.data.success === true) {
                                            this.getData();
                                        }
                                    })
                                    .catch(err => console.log(err));
                            }
                        })
                        .catch(err => console.log(err));
                } else {
                    this.$http.delete(apiUrls[this.options.type === 'ticket' ? 'reserve' : this.options.type] + '/' + item.id)
                        .then(resp => {
                            this.showItemDeletePopup = false;

                            if (resp.data && resp.data.success === true) {
                                this.getData();
                            }
                        })
                        .catch(err => {
                            if (err.response.data && err.response.data.message && err.response.data.message.indexOf('FOREIGN')) {
                                this.showItemDeletePopup = false;
                                this.showMessage('Этот объект используется в других таблицах.');
                            }
                            console.log(err.response)
                        });
                }

            },
            showMessage(message) {
                this.message = message;
                this.showMessagePopup = true;
            },
            closeItemPopup() {
                this.showItemPopup = false
            },
            closeItemEditPopup() {
                if (this.itemIsChanged) {
                    this.confirmClose = true;
                } else {
                    this.showItemEditPopup = false;
                    this.itemIsChanged = false;
                }
            },
            closeItemEditPopupConfirm() {
                this.showItemEditPopup = false;
                this.itemIsChanged = false;
                this.confirmClose = false;
            },
            closeItemEditPopupCancel() {
                this.confirmClose = false;
            },
            onItemEditSuccess() {
                this.getData();
                this.showItemEditPopup = false;
            },
            submitForm() {
                if (!this.itemIsChanged) return;

                this.$refs[this.options.type].submitItem();
            },
            onItemChanged(item) {
                this.itemToEdit = item;
                this.itemIsChanged = true;
            },
            onItemDeleted() {
                this.showItemEditPopup = false;
                this.getData();
            },
            changeOrderBy(typeOrderBy) {
                this.options.getDataParams.orderBy = typeOrderBy;
                this.options.getDataParams.sortedBy === 'desc' ? this.options.getDataParams.sortedBy = 'asc' : this.options.getDataParams.sortedBy = 'desc';
                this.getData();
            }
        },
        activated() {
            this.getData()
                .then(() => {
                    if (this.$store.state.itemToEdit && this.$store.state.itemToEdit.type === this.options.type) {
                        if (this.$store.state.itemToEdit.id === 'last') {
                            this.editItem(this.tableData[this.tableData.length - 1]);
                        } else {
                            this.$http.get(apiUrls[this.options.type], {params: {search: this.$store.state.itemToEdit.id, searchFields: 'id:=', page: 1}})
                                .then(resp => {
                                    if (resp.data && resp.data.data && resp.data.data.items && resp.data.data.items.length === 1) {
                                        this.editItem(resp.data.data.items[0]);
                                    }
                                });
                        }

                        this.$store.dispatch('setItemToEdit', null);
                    }
                });
        },
        watch: {
            selectedReserveOptions: {
                handler(newVal) {
                    switch (newVal) {
                        case 'Все':
                            this.options.getDataParams.past = '';
                            this.getData();
                            break;
                        case 'Прошедшие':
                            this.options.getDataParams.past = true;
                            this.getData();
                            break;
                        case 'Будущие':
                            this.options.getDataParams.past = false;
                            this.getData();
                            break;
                        default:
                            this.options.getDataParams.past = '';
                            this.getData();
                    }
                },
            }
        },
        mounted() {
            this.searchObject = invert({...this.options.filterable});

            this.$on('showItem', data => {
                this.showItem(data)
            });

            this.searchObjectWatcher = this.$watch('searchObject', {
                handler: function (newValue) {
                    this.searchQueryObject = {};
                    this.searchQueryString = '';
                    for (let p in newValue)
                        if (newValue.hasOwnProperty(p)) {
                            this.searchQueryObject[p] = newValue[p];
                            if (newValue[p].length) {
                                if (this.searchQueryString.length) {
                                    this.searchQueryString += ';';
                                }

                                this.searchQueryString = this.searchQueryString.concat(p + ':' + newValue[p]);
                            }

                        }

                    this.getData();
                },
                deep: true
            });

            if (this.options.type === 'ticket') {
                this.getData();
            }
        }
    }
</script>